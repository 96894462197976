import {
    animate,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { APP_LOGO } from '@interacta-shared/util-common';

@Component({
    selector: 'interacta-light-box-layout',
    templateUrl: './light-box-layout.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('light-box', [
            transition(':enter', [
                query(':self', [
                    style({ opacity: 0 }),
                    animate('200ms ease', style({ opacity: 1 })),
                ]),
            ]),
            transition(':leave', [
                query(':self', [animate('200ms ease', style({ opacity: 0 }))]),
            ]),
        ]),
    ],
})
export class LightBoxLayoutComponent {
    readonly logo = inject(APP_LOGO).dark;
}
