import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { filterMap } from '@interacta-shared/util';
import { isAttachmentSupported } from '@modules/ai/helpers/ai.utils';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { Store } from '@ngrx/store';

@Component({
    selector: 'interacta-attachment-action-menu',
    templateUrl: './attachment-action-menu.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentActionMenuComponent {
    @Input() attachment!: IAttachment;
    @Input() aiEnabled = false;
    @Input() customButtons: ICustomButton[] = [];
    @Input() canEdit = false;
    @Input() canEditHashtags = false;
    @Input() canDelete = false;
    @Input() canManageVersion = false;
    @Input() canDownload = false;
    @Input() canShare = false;
    @Input() versionNumber = 1;
    @Input() isDrive = false;
    @Output() edit = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() manageVersion = new EventEmitter<void>();
    @Output() download = new EventEmitter<void>();
    @Output() openDrive = new EventEmitter<void>();
    @Output() share = new EventEmitter<void>();
    @Output() sendToAI = new EventEmitter<void>();

    theme$ = this.store
        .select(UISelectors.theme)
        .pipe(filterMap((theme) => theme?.mode ?? undefined));

    isAttachmentSupported = isAttachmentSupported;

    constructor(private store: Store) {}
}
