import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { SelectionRecord } from '@modules/core/services/selection.service';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachments-rows',
    templateUrl: './attachments-rows.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsRowsComponent {
    @Input() attachments!: IListAttachments;
    @Input() selectionRecord: SelectionRecord = {};
    @Input() enableSelection = true;
    @Input() enableMenu = true;
    @Input() attachmentSection: 'detail' | 'community' = 'community';
    @Input() customButtons: ICustomButton[] = [];
    @Input() canShare = false;
    @Input() preview = false;
    @Input() hashtagsAvailable = false;
    @Input() aiEnabled = false;
    @Input() showOriginField = false;

    @Output() itemMouseEnter = new EventEmitter<IAttachment>();
    @Output() itemMouseLeave = new EventEmitter<IAttachment>();
    @Output() toggleSelectAttachment = new EventEmitter<{
        attachment: IAttachment;
    }>();
    @Output() download = new EventEmitter<IAttachment>();
    @Output() openAttachment = new EventEmitter<{
        index: number;
        showInfo: boolean;
    }>();
    @Output() loadMore = new EventEmitter<void>();
    @Output() edit = new EventEmitter<IAttachment>();
    @Output() editHashtags = new EventEmitter<IAttachment>();
    @Output() delete = new EventEmitter<IAttachment>();
    @Output() manageVersion = new EventEmitter<IAttachment>();
    @Output() share = new EventEmitter<IAttachment>();
    @Output() sendToAI = new EventEmitter<IAttachment>();

    trackByFn(_index: number, item: IAttachment): IAttachment['id'] {
        return item.id;
    }
}
