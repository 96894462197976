import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-attachment-highlight',
    templateUrl: './attachment-highlight.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentHighlightComponent {
    @Input({ required: true }) ringClass!: string;
    @Input({ required: true }) badgeClass!: string;
    @Input({ required: true }) tooltip!: string;
}
