<div class="flex flex-col space-y-16">
    <ng-container *ngIf="mediaList.totalCount > 0">
        <interacta-media-list-different-size
            *ngIf="mediaSize === 'different'"
            [mediaList]="mediaList.attachments"
            (openMedia)="
                openAttachment.emit({ list: mediaList, index: $event.index })
            "
        ></interacta-media-list-different-size>
        <interacta-media-list
            *ngIf="mediaSize === 'same'"
            [expandable]="layout === 'extended'"
            [mediaList]="mediaList"
            [size]="size"
            (openMedia)="
                openAttachment.emit({ list: mediaList, index: $event.index })
            "
        ></interacta-media-list>
    </ng-container>

    <interacta-attachments-chip-list
        *ngIf="documentsList.totalCount > 0"
        [attachmentsList]="documentsList"
        [bgColor]="bgColor"
        [expandable]="layout === 'extended'"
        [initialLength]="initialLength"
        [orientation]="layout === 'extended' ? 'column' : 'row'"
        [pagination]="pagination"
        (openDocument)="
            openAttachment.emit({ list: documentsList, index: $event.index })
        "
    ></interacta-attachments-chip-list>
</div>
