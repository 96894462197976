import {
    Index,
    PaginatedList,
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListError,
    fetchPaginatedListSuccess,
} from '@interacta-shared/util';
import { createReducer, on } from '@ngrx/store';
import {
    AttachmentSection,
    AttachmentSectionSource,
} from '../../models/attachment-section/attachment-section.model';
import * as sectionsActions from './sections.actions';

export interface SectionsState {
    containsText: string;
    currentAttachment: AttachmentSectionSource | null;
    active: boolean;
    selectedSection: AttachmentSection['id'] | null;
    items: Record<Index, PaginatedList<AttachmentSection>>;
}

export const sectionsInitialState = (): SectionsState => ({
    containsText: '',
    currentAttachment: null,
    active: false,
    selectedSection: null,
    items: {},
});

export const sectionsReducer = createReducer(
    sectionsInitialState(),

    on(sectionsActions.setCurrentAttachment, (state, action) => ({
        ...state,
        currentAttachment: action.currentAttachment,
        items: {
            ...state.items,
            [action.currentAttachment.id]:
                state.items[action.currentAttachment.id] ??
                emptyPaginatedList<AttachmentSection>(),
        },
    })),

    on(sectionsActions.setActive, (state, { active }) => ({
        ...state,
        active,
    })),

    on(sectionsActions.fetchSectionsList, (state, action) => {
        if (
            state.currentAttachment?.id === action.attachment.id &&
            action.attachment.id != null
        ) {
            return {
                ...state,
                containsText: action.containsText,
                selectedSection: action.pageToken
                    ? state.selectedSection
                    : null,
                items: {
                    ...state.items,
                    [action.attachment.id]: fetchPaginatedList(
                        state.items[action.attachment.id],
                        action.pageToken ?? undefined,
                    ),
                },
            };
        } else {
            return state;
        }
    }),

    on(sectionsActions.fetchSectionsListSuccess, (state, action) => {
        if (
            state.currentAttachment?.id === action.attachmentId &&
            action.attachmentId != null
        ) {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.attachmentId]: fetchPaginatedListSuccess(
                        state.items[action.attachmentId],
                        action.items,
                    ),
                },
            };
        } else {
            return state;
        }
    }),

    on(sectionsActions.fetchSectionsListError, (state, action) => {
        if (
            state.currentAttachment?.id === action.attachmentId &&
            action.attachmentId != null
        ) {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.attachmentId]: fetchPaginatedListError(
                        state.items[action.attachmentId],
                    ),
                },
            };
        } else {
            return state;
        }
    }),

    on(sectionsActions.selectSection, (state, { id }) => ({
        ...state,
        selectedSection: id,
    })),

    on(sectionsActions.flush, (_) => sectionsInitialState()),
);
