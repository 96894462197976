import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CurrentUser } from '@interacta-shared/data-access-auth';
import { Layout } from '@interacta-shared/ui';
import {
    createDeltaFromString,
    isDeltaEmpty,
} from '@interacta-shared/util-common';
import { dashboardRoutes } from '@modules/app-routing/routes';
import { PostActions, PostActionsApi } from '@modules/communities/store';
import { KeyCode } from '@modules/core';
import {
    closeCommentFullScreen,
    openCommentFullScreen,
} from '@modules/core/store/UI/ui.actions';
import { DashboardService } from '@modules/dashboard/services/dashboard-page.service';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import {
    CommentLocationType,
    IComment,
    ICommentDraft,
} from '@modules/post/models/comment.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { PostCommentCanDeactivateService } from '@modules/post/services/post-comment-can-deactivate.service';
import { PostViewService } from '@modules/post/services/post-view.service';
import { MentionDeltaEditorComponent } from '@modules/shared-v2/components/mention-delta-editor/mention-delta-editor.component';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Delta } from 'quill/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    concat,
    merge,
    of,
    timer,
} from 'rxjs';
import {
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
    tap,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-post-comment-input',
    templateUrl: './post-comment-input.component.html',
    providers: [AttachmentInputService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentInputComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input({ required: true }) post!: GenericPost;
    @Input({ required: true }) draft!: ICommentDraft;
    @Input() commentsCount: number | undefined;
    @Input() initialViewMode: Layout = 'compact';
    @Input() locationType: CommentLocationType = 'dashboard';
    @Input() createMention?: boolean;
    @Input() isFullScreen = false;
    @Input() alwaysExpanded = false;
    @Input() alwaysCompact = false;
    @Input() disableFullScreen = false;
    @Input() showCloseButton = false;
    @Input() editorClasses = '';

    @Output() focusOnCommentInput = new EventEmitter<boolean>();

    @ViewChild('container') container!: ElementRef<HTMLElement>;
    @ViewChild('editor') editor?: MentionDeltaEditorComponent;

    user: CurrentUser | null = null;
    control = new UntypedFormControl();

    commentDraft$!: Observable<ICommentDraft>;
    toggleShadow$!: Observable<boolean>;
    isEmptyCommentInput$ = new BehaviorSubject<boolean>(true);
    viewMode$ = new BehaviorSubject<Layout>('compact');
    dropZoneOver$ = new BehaviorSubject<boolean>(false);
    mouseHover$ = new BehaviorSubject<boolean>(false);

    showDropZonePanel$ = combineLatest([
        this.dropZoneOver$.pipe(distinctUntilChanged(), delay(100)),
        this.mouseHover$,
    ]).pipe(
        map(([drop, hoverCard]) => drop || hoverCard),
        distinctUntilChanged(),
    );

    private destroy$ = new Subject<void>();

    constructor(
        private store: Store,
        private authService: AuthService,
        private postViewService: PostViewService,
        private dashboardService: DashboardService,
        private cdr: ChangeDetectorRef,
        private actions: Actions,
        private discoveryService: DiscoveryService,
        @Optional()
        private postCommentCanDeactivateService: PostCommentCanDeactivateService,
        public attachmentInputService: AttachmentInputService,
        public router: Router,
    ) {}

    ngOnInit(): void {
        this.user = this.authService.currentUserData();
        this.attachmentInputService.init();
        this.viewMode$.next(this.initialViewMode);

        this.control.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                const isEmpty = !val || isDeltaEmpty(val);
                this.isEmptyCommentInput$.next(isEmpty);
                this.postCommentCanDeactivateService?.setCommentDirty(
                    this.control.dirty,
                );
            });

        this.dashboardService
            .getFocusComment$(this.post.id)
            .pipe(
                tap((_) => this.setViewMode('extended')),
                delay(100), //give editor time for rendering before focus
                takeUntil(this.destroy$),
            )
            .subscribe((_) => {
                this.focus();
                this.cdr.markForCheck();
            });

        this.toggleShadow$ = merge(
            this.postViewService
                .getTriggerShadow$(this.post.id)
                .pipe(
                    concatMap((_) =>
                        concat(of(true), timer(1200).pipe(map(() => false))),
                    ),
                ),
            this.showDropZonePanel$,
        );

        this.actions
            .pipe(
                ofType(
                    PostActionsApi.addCommentSuccess,
                    PostActionsApi.saveEditCommentSuccess,
                    PostActionsApi.addStreamingCommentSuccess,
                ),
                filter(({ postId }) => postId === this.post.id),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.close());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.post &&
            !changes.post.isFirstChange() &&
            this.post.id !== changes.post.previousValue?.id
        ) {
            this.close();
        }

        if (changes.draft && this.draft.comment) {
            if (
                isDeltaEmpty(this.draft.comment.comment as Delta | null) &&
                this.draft.comment.attachments?.length === 0
            ) {
                this.close();
            } else {
                this.control.setValue(this.draft.comment.comment);
                this.control.markAsDirty();
                this.attachmentInputService.replacePendingAttachment(
                    this.draft.comment.attachments || [],
                );
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.isFullScreen) {
            setTimeout(() => this.focus());
        }
    }

    ngOnDestroy(): void {
        if (
            !this.isEmptyCommentInput$.value ||
            this.attachmentInputService.attachments.length > 0
        ) {
            this.saveCurrentDraft();
        }
        this.destroy$.next();
    }

    focus(): void {
        this.editor?.focus();
        this.focusOnCommentInput.emit(true);

        this.discoveryService.openTip(
            { id: 'comments-fullscreen' },
            {
                title: 'DISCOVERY.COMMENTS_FULLSCREEN.TITLE',
                message: 'DISCOVERY.COMMENTS_FULLSCREEN.DESCRIPTION',
                image: 'comments',
                payload: { boundary: dashboardRoutes },
            },
        );
    }

    onKeyUp(
        event: KeyboardEvent,
        editCommentId?: number,
        parentComment?: IComment,
    ): void {
        if (
            event.ctrlKey &&
            event.key === KeyCode.ENTER &&
            !this.isEmptyCommentInput$.value
        ) {
            this.saveComment(editCommentId, parentComment);
        }

        if (event.key === KeyCode.ESCAPE && this.isFullScreen) {
            this.closeFullscreen(true);
        }
    }

    onCompactInputClick(): void {
        if (!this.alwaysCompact) {
            this.setViewMode('extended');
            this.postViewService.triggerShadow(this.post.id);
            this.focus();
        } else {
            this.openFullscreen();
        }
    }

    cancel(): void {
        if (this.isFullScreen) {
            this.closeFullscreen(false);
        }

        this.close();

        this.store.dispatch(
            PostActions.closeDraftComment({
                postId: this.post.id,
            }),
        );
    }

    saveComment(editCommentId?: number, parentComment?: IComment): void {
        const baseComment = {
            postId: this.post.id,
            delta: this.control.value,
            parentCommentId: parentComment?.id,
            addAttachments: this.attachmentInputService.attachments,
        };

        if (this.locationType === 'lightBoxStreaming') {
            //it's not possible to send correct streaming timestamp
            this.store.dispatch(
                PostActions.addStreamingComment({
                    ...baseComment,
                    location: 'lightBoxStreaming',
                    streamingTimestamp: undefined,
                }),
            );
        } else {
            this.store.dispatch(
                !editCommentId
                    ? PostActions.addComment({
                          ...baseComment,
                          location: this.locationType,
                      })
                    : PostActions.saveEditComment({
                          ...baseComment,
                          commentId: editCommentId,
                      }),
            );
        }
    }

    setViewMode(viewMode: Layout): void {
        if (viewMode === 'extended') {
            setTimeout(() => {
                this.focus();
            }, 350);
        }

        this.viewMode$.next(viewMode);
    }

    openFullscreen(): void {
        this.saveCurrentDraft();
        this.store.dispatch(
            openCommentFullScreen({
                postId: this.post.id,
                location: this.locationType,
                verticalAlign:
                    this.locationType === 'lightBoxStreaming'
                        ? 'end'
                        : 'center',
                overlayPalette:
                    this.locationType === 'lightBoxStreaming'
                        ? 'surface-A'
                        : 'black-not-black-80',
                size:
                    this.locationType === 'lightBoxStreaming'
                        ? 'small'
                        : 'regular',
            }),
        );
    }

    closeFullscreen(focusAfter: boolean): void {
        this.store.dispatch(closeCommentFullScreen());

        if (focusAfter) {
            this.store.dispatch(
                PostActions.saveCommentDraft({
                    postId: this.post.id,
                    draft: this.getCurrentDraft(),
                }),
            );

            setTimeout(() => {
                this.dashboardService.focusComment(this.post.id);
                this.postViewService.triggerShadow(this.post.id);
            }, 50);
        }
    }

    fileOver(isOver: boolean): void {
        if (this.locationType !== 'lightBoxStreaming')
            this.dropZoneOver$.next(isOver);
    }

    uploadCopiedFiles(files: File[]): void {
        this.attachmentInputService.uploader.addToQueue(files);
    }

    private getCurrentDraft(): ICommentDraft {
        return {
            ...this.draft,
            comment: {
                ...this.draft.comment,
                comment: this.control.value,
                attachments: this.attachmentInputService.attachments,
            },
            isEditing: true,
        };
    }

    private saveCurrentDraft(): void {
        const draft = this.getCurrentDraft();

        this.store.dispatch(
            PostActions.saveCommentDraft({
                postId: this.post.id,
                draft,
            }),
        );
    }

    private close(): void {
        this.control.reset();
        this.control.setValue(createDeltaFromString(''));
        this.attachmentInputService.clearPendingAttachments();
        this.postCommentCanDeactivateService?.setCommentDirty(false);
        this.focusOnCommentInput.emit(false);

        if (!this.alwaysExpanded) {
            this.viewMode$.next('compact');
        }
    }
}
