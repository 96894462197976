import { Injectable } from '@angular/core';
import { isDefined, unique } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import {
    IWorkflowDefinition,
    IWorkflowDefinitionForFilter,
} from '@modules/communities/models/workflow/workflow.model';
import { IWorkspace } from '@modules/communities/models/workspace.model';
import * as PostSelectors from '@modules/communities/store/post/post.selectors';
import { GoogleAPIService } from '@modules/core/services/google-api.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import {
    DraftType,
    PostOtherFiltersId,
} from '@modules/post/models/filter-post/filter-post.model';
import {
    countEnabledPostTypeFilter,
    otherFiltersHiddenInitState,
} from '@modules/post/models/filter-post/filter-post.utils';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Store } from '@ngrx/store';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { delay, filter, map, mapTo, share, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    currentCommunity$: Observable<ICommunity | undefined | null>;
    workspaces$: Observable<IWorkspace[]>;
    isPostListHidden$: Observable<boolean>;
    inCommunity$: Observable<boolean>;
    communitiesVisibleInDashboard$: Observable<ICommunity[]>;
    postDefinitionsForCommunitiesVisibleInDashboard$: Observable<
        IPostMetadata[]
    >;
    dashboardWorkflowDefinitions$: Observable<IWorkflowDefinitionForFilter[]>;
    otherFiltersHidden$!: Observable<Record<PostOtherFiltersId, boolean>>;
    showDraftType$!: Observable<DraftType | null>;

    readonly scrollFadeDuration = 1200;

    private scrollToTop = new Subject<void>();
    private focusComment$ = new Subject<{ postId: number }>();

    get scrollToTop$(): Observable<void> {
        return this.scrollToTop.asObservable();
    }

    constructor(
        private store: Store<AppState>,
        private communitiesStateService: CommunitiesStateService,
        private googleAPIService: GoogleAPIService,
    ) {
        const communityTree$ =
            this.communitiesStateService.state.communityTree$;

        this.currentCommunity$ =
            this.communitiesStateService.getCurrentCommunity();

        this.workspaces$ = communityTree$.pipe(
            map((communityTree) => communityTree?.workspaceList ?? []),
            shareReplay(1),
        );

        this.communitiesVisibleInDashboard$ =
            this.communitiesStateService.state.communitiesVisibleInDashboard$;

        this.postDefinitionsForCommunitiesVisibleInDashboard$ =
            this.communitiesVisibleInDashboard$.pipe(
                map((communities: ICommunity[]) =>
                    communities.map((c) => c.metadata!),
                ),
            );

        const weAreInDashboard$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filter(isDefined),
                map((route) => route.appBaseRoute === 'dashboard'),
            );
        const aCommunityIsSelected$ = this.store
            .select(PostSelectors.selectCurrentFilters('dashboard'))
            .pipe(map((filters) => isDefined(filters.communityId)));

        this.inCommunity$ = combineLatest([
            weAreInDashboard$,
            aCommunityIsSelected$,
        ]).pipe(
            map(([inDashboard, inCommunity]) => inDashboard && inCommunity),
            shareReplay(1),
        );

        this.isPostListHidden$ = merge(
            this.scrollToTop$.pipe(map((_) => true)),
            this.scrollToTop$.pipe(
                delay(this.scrollFadeDuration),
                map((_) => false),
            ),
        ).pipe(share());

        this.dashboardWorkflowDefinitions$ = combineLatest([
            this.postDefinitionsForCommunitiesVisibleInDashboard$,
            this.currentCommunity$.pipe(map((c) => c?.metadata)),
        ]).pipe(
            map(([postDefinitions, currentCommunityPostDefinition]) =>
                currentCommunityPostDefinition
                    ? [currentCommunityPostDefinition]
                    : postDefinitions,
            ),
            map((postDefinitions: IPostMetadata[]) =>
                postDefinitions
                    .map((p) => p.workflowDefinition)
                    .filter(isDefined),
            ),
            map((workflowDefinitions: IWorkflowDefinition[]) =>
                workflowDefinitions.map(
                    (wfDefinition: IWorkflowDefinition) => ({
                        id: wfDefinition.id,
                        title: wfDefinition.title,
                        states: wfDefinition.states.filter(
                            (s) => s.metadata.searchable,
                        ),
                    }),
                ),
            ),
            map((val) => unique(val, (val) => val.id)),
        );

        this.otherFiltersHidden$ = combineLatest([
            this.dashboardWorkflowDefinitions$,
            this.currentCommunity$.pipe(map((c) => c?.metadata)),
        ]).pipe(
            map(
                ([
                    currentWorkflowDefinitions,
                    currentCommunityPostDefinition,
                ]) => {
                    return {
                        ...otherFiltersHiddenInitState(),
                        [PostOtherFiltersId.WORKFLOW_STATES]:
                            !currentWorkflowDefinitions ||
                            currentWorkflowDefinitions.length === 0,
                        [PostOtherFiltersId.POST_HASHTAGS]:
                            !currentCommunityPostDefinition ||
                            !currentCommunityPostDefinition.hashTagEnabled ||
                            (
                                currentCommunityPostDefinition.activeHashtags ||
                                []
                            ).length === 0,
                        [PostOtherFiltersId.ATTACHMENTS_HASHTAGS]:
                            !currentCommunityPostDefinition ||
                            !currentCommunityPostDefinition.hashTagEnabled ||
                            (
                                currentCommunityPostDefinition.activeHashtags ||
                                []
                            ).length === 0,
                        [PostOtherFiltersId.VISIBILITY]:
                            !currentCommunityPostDefinition,
                        [PostOtherFiltersId.CUSTOM_FIELDS]:
                            !currentCommunityPostDefinition ||
                            currentCommunityPostDefinition.fieldMetadatas.every(
                                (f) => !f.searchable,
                            ),
                        [PostOtherFiltersId.SCREEN_CUSTOM_FIELDS]:
                            !currentCommunityPostDefinition ||
                            (
                                currentCommunityPostDefinition
                                    .workflowDefinition?.screenFieldMetadatas ??
                                []
                            ).every((f) => !f.searchable),
                        [PostOtherFiltersId.ATTACHMENTS_SOURCE]:
                            !this.googleAPIService
                                .isDriveEnabledForInstallation,
                        [PostOtherFiltersId.POST_TYPE]:
                            countEnabledPostTypeFilter(
                                currentCommunityPostDefinition,
                            ) <= 1, // To cover all post types disabled (from DB)
                    };
                },
            ),
        );
    }

    scrollTop(): void {
        this.scrollToTop.next();
    }

    focusComment(postId: number): void {
        this.focusComment$.next({ postId });
    }

    getFocusComment$(postId: number): Observable<void> {
        return this.focusComment$.asObservable().pipe(
            filter((focus) => focus.postId === postId),
            mapTo(void 0),
        );
    }
}
