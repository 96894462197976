import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Size, fadeInOutAnimation } from '@interacta-shared/ui';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import {
    AttachmentCategoryType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachment-card',
    templateUrl: './attachment-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutAnimation('selectionOverlay')],
})
export class AttachmentCardComponent {
    @Input({ required: true }) attachment!: IAttachment;
    @Input() aiEnabled = false;
    @Input() attachmentType = AttachmentCategoryType.MULTIMEDIA;
    @Input() enableSelection = true;
    @Input() enableMenu = true;
    @Input() selected = false;
    @Input() selectedPreview = false;
    @Input() isSelectionActive = false;
    @Input() canShare = false;
    @Input() size: Extract<Size, 'extra-large' | 'large'> = 'large';
    @Input() customButtons: ICustomButton[] = [];
    @Input() preview = false;
    @Input() hashtagsAvailable = false;
    @Input() showOriginField = false;

    @Output() itemMouseEnter = new EventEmitter();
    @Output() itemMouseLeave = new EventEmitter();
    @Output() toggleSelectAttachment = new EventEmitter();
    @Output() download = new EventEmitter<IAttachment>();
    @Output() openAttachment = new EventEmitter<boolean>();
    @Output() edit = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() manageVersion = new EventEmitter<void>();
    @Output() share = new EventEmitter<void>();
    @Output() sendToAI = new EventEmitter<void>();

    hoverCheck = false;
    hoverCard = false;

    attachmentCategoryType = AttachmentCategoryType;
}
