<div
    class="relative h-full rounded-2xl ring-6 bg-transparent ring-inset {{
        ringClass
    }}"
>
    <div
        [attr.aria-label]="tooltip"
        class="absolute bottom-0 left-0 w-40 h-40 p-6 flex justify-center items-center {{
            badgeClass
        }} rounded-full"
        [matTooltip]="tooltip"
    >
        <ng-content></ng-content>
    </div>
</div>
