import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Index, PaginatedList, toPaginatedList } from '@interacta-shared/util';
import { Observable, map } from 'rxjs';
import { toAttachmentSection } from '../models/attachment-section/attachment-section.deserialize';
import { AttachmentSection } from '../models/attachment-section/attachment-section.model';

@Injectable({
    providedIn: 'root',
})
export class AttachmentSectionService {
    private readonly attachmentsDataUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/attachments/data`;

    constructor(private http: HttpClient) {}

    getAttachmentSections(
        attachmentId: Index,
        containsText: string,
        pageToken: string | null,
        pageSize = 10,
        calculateTotalItemsCount = false,
    ): Observable<PaginatedList<AttachmentSection>> {
        return this.http
            .post<any>(
                `${this.attachmentsDataUrl}/${attachmentId}/sections
        `,
                {
                    pageToken,
                    pageSize: pageSize ?? 10,
                    calculateTotalItemsCount,
                    containsText,
                },
            )
            .pipe(
                map((res) => toPaginatedList(res)),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toAttachmentSection),
                })),
            );
    }
}
