<ng-container
    *ngIf="{
        viewMode: viewMode$ | async,
        isLoading: attachmentInputService.onLoading$ | async,
        pendingAttachments: attachmentInputService.pendingAttachments$ | async,
        isEmptyCommentInput: isEmptyCommentInput$ | async,
        toggleShadow: toggleShadow$ | async,
        showDropZonePanel: showDropZonePanel$ | async,
        inEdit: draft.comment?.id != null,
        canAddAttachments: locationType !== 'lightBoxStreaming'
    } as $"
>
    <div
        *ngIf="user"
        #container
        class="typo-sm flex"
        ng2FileDrop
        [ngClass]="{
            'min-h-46 bg-surface-300 items-center justify-center gap-x-8 rounded-3xl p-8':
                $.viewMode === 'compact',
            'rounded-tl-4xl flex-col gap-y-8 rounded-xl border border-solid p-12':
                $.viewMode === 'extended',
            'bg-surface-100 relative':
                $.showDropZonePanel && $.viewMode === 'extended',
            'border-border-300':
                !$.showDropZonePanel && $.viewMode === 'extended',
            'bg-surface-A': !$.showDropZonePanel && $.viewMode === 'extended',
            'shadow-primary': $.toggleShadow,
            'shadow-none': !$.toggleShadow
        }"
        [style.transition]="
            'transform 300ms ease, min-height 400ms ease, background 400ms ease, width 300ms ease, box-shadow 1s ease'
        "
        [uploader]="attachmentInputService.uploader"
        (fileOver)="fileOver($event)"
    >
        <div
            class="flex items-center justify-between"
            [ngClass]="{ invisible: $.showDropZonePanel }"
        >
            <div class="flex items-center space-x-8">
                <interacta-avatar
                    class="shrink-0"
                    [size]="$.viewMode === 'compact' ? 'small' : 'regular'"
                    [user]="user"
                />

                <span *ngIf="$.viewMode !== 'compact'" class="typo-sm-bold">
                    {{ user.fullName }}
                </span>
            </div>

            <button
                *ngIf="$.viewMode === 'extended' && !disableFullScreen"
                interacta-icon-button
                type="button"
                [icon]="isFullScreen ? 'fullscreen-exit' : 'fullscreen'"
                (click)="
                    isFullScreen ? closeFullscreen(true) : openFullscreen()
                "
            ></button>
            <button
                *ngIf="showCloseButton"
                interacta-icon-button
                type="button"
                [icon]="'close'"
                (click)="cancel()"
            ></button>
        </div>
        <div
            *ngIf="
                draft &&
                draft.comment &&
                draft.comment.creatorUser &&
                draft.comment.creatorUser.id != null &&
                draft.comment.creatorUser.id !== user.id
            "
            class="typo-xs text-text-mid-contrast flex items-center"
            [ngClass]="{ invisible: $.showDropZonePanel }"
        >
            {{
                'DASHBOARD.COMMENTS.EDIT_OTHER_CREATOR'
                    | translate
                        : { fullname: draft.comment.creatorUser.fullName }
            }}
        </div>

        <interacta-post-comment-quote
            *ngIf="draft.parentComment"
            [bgColor]="'gray-100'"
            [comment]="draft.parentComment"
            [ngClass]="{ invisible: $.showDropZonePanel }"
        />

        <interacta-mention-delta-editor
            #editor
            class="grow"
            [attr.aria-label]="
                'DASHBOARD.COMMENTS.INPUT_PLACEHOLDER' | translate
            "
            [canPasteFile]="$.canAddAttachments"
            [classes]="editorClasses"
            [control]="control"
            [enableMemberAutocomplete]="
                locationType !== 'lightBoxStreaming' && createMention
                    ? { communityId: post.communityId }
                    : undefined
            "
            [ngClass]="{ invisible: $.showDropZonePanel }"
            [placeholder]="
                $.viewMode === 'compact'
                    ? ('DASHBOARD.COMMENTS.INPUT_PLACEHOLDER' | translate)
                    : ' '
            "
            [withToolbar]="$.viewMode === 'extended'"
            (click)="$.viewMode === 'compact' && onCompactInputClick()"
            (copiedFiles)="uploadCopiedFiles($event)"
            (focusInput)="
                $.viewMode === 'compact' &&
                    !alwaysCompact &&
                    setViewMode('extended')
            "
            (focusout)="focusOnCommentInput.emit(false)"
            (keyup)="onKeyUp($event, draft?.comment?.id, draft?.parentComment)"
        />

        <div
            *ngIf="
                $.viewMode !== 'compact' &&
                $.pendingAttachments &&
                $.pendingAttachments.length > 0
            "
            class="flex flex-col gap-y-8 pb-12"
            [ngClass]="{ invisible: $.showDropZonePanel }"
        >
            <interacta-attachment-form-chip
                *ngFor="let attachment of $.pendingAttachments"
                [allowAddVersion]="false"
                [allowEdit]="false"
                [attachment]="attachment"
                [bgColor]="'surface-B'"
                (removeAttachment)="
                    attachmentInputService.removeAttachment(attachment)
                "
            />
        </div>

        <interacta-loader *ngIf="$.isLoading" />

        <interacta-post-comment-input-footer
            *ngIf="$.viewMode !== 'compact'"
            [canAddAttachments]="$.canAddAttachments"
            [canSaveComment]="
                !$.isEmptyCommentInput ||
                (this.attachmentInputService.attachments &&
                    this.attachmentInputService.attachments.length > 0)
            "
            [isCreatingComment]="post.isCreatingComment"
            [mode]="
                draft.comment && draft.comment.id
                    ? { tag: 'edit', commentId: draft.comment.id }
                    : { tag: 'creation' }
            "
            [ngClass]="{ invisible: $.showDropZonePanel }"
            [postId]="post.id"
            [uploader]="attachmentInputService.uploader"
            (cancel)="cancel()"
            (saveComment)="saveComment($event, draft.parentComment)"
        />

        <interacta-drop-zone-area
            *ngIf="$.showDropZonePanel && $.viewMode === 'extended'"
            class="absolute"
            [appearance]="'ghost'"
            [height]="container.clientHeight - 24"
            [width]="container.clientWidth - 24"
            (isMouseOver)="mouseHover$.next($event)"
        />
    </div>
</ng-container>
