import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { extractFilenameFromHttpResponseHeader } from '@modules/core/helpers/generic.utils';
import { SurveyPost } from '@modules/post/models/survey-post/survey-post.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ITaskFilters,
    TaskFilterSerialize,
    emptyTaskFilters,
} from '../models/filter-task.model';
import {
    toSurveyTaskConfirm,
    toSurveyTaskList,
} from '../models/task.deserialize';
import {
    ITaskQueryParamsRequest,
    ITaskType,
    SurveyTaskConfirmData,
    SurveyTaskConfirmResponse,
    SurveyTaskList,
} from '../models/task.model';
import { fromConfirmSurveyTask } from '../models/task.serialize';

@Injectable({
    providedIn: 'root',
})
export class SurveyTaskService {
    private dataBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks/data`;
    private manageBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks/manage`;

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    confirmSurveyTask(
        survey: SurveyPost,
        data: SurveyTaskConfirmData,
    ): Observable<SurveyTaskConfirmResponse> {
        return this.http
            .put<any>(
                `${this.manageBaseUrl}/posts/${survey.id}/survey-tasks/answer`,
                fromConfirmSurveyTask(data),
            )
            .pipe(
                map((record: any) =>
                    toSurveyTaskConfirm(
                        record,
                        this.configurationService,
                        survey.fieldDefinitions,
                    ),
                ),
            );
    }

    getExpiringAssignedToMeSurveyTaskList(
        post: SurveyPost,
    ): Observable<SurveyTaskList> {
        const filter: ITaskFilters = {
            ...emptyTaskFilters(ITaskType.SURVEY),
            state: {
                expiredAssignedToMe: null,
                expiringAssignedToMe: true,
                closedAssignedToMe: null,
            },
            pageSize: 100,
        };

        const queryParams: ITaskQueryParamsRequest = {
            loadDownloadLink: false,
            loadViewLink: false,
            loadPreviewImageLink: false,
        };

        return this.getSurveyPostTasks(post, filter, queryParams);
    }

    getAllAssignedToMeSurveyTaskList(
        post: SurveyPost,
        pageSize: number | null,
        pageToken: string | null,
    ): Observable<SurveyTaskList> {
        const filter: ITaskFilters = {
            ...emptyTaskFilters(ITaskType.SURVEY),
            state: {
                expiredAssignedToMe: true,
                expiringAssignedToMe: true,
                closedAssignedToMe: true,
            },
            pageToken: pageToken,
            pageSize: pageSize,
            order: {
                orderBy: 'assignee',
                orderDesc: false,
            },
        };

        const queryParams: ITaskQueryParamsRequest = {
            loadDownloadLink: false,
            loadViewLink: true,
            loadPreviewImageLink: true,
        };

        return this.getSurveyPostTasks(post, filter, queryParams);
    }

    getAllClosedSurveyTaskList(
        post: SurveyPost,
        pageSize: number,
        assigneeName: string | null,
        pageToken: string | null,
    ): Observable<SurveyTaskList> {
        const filter: ITaskFilters = {
            ...emptyTaskFilters(ITaskType.SURVEY),
            state: {
                expiredAssignedToMe: null,
                expiringAssignedToMe: null,
                closedAssignedToMe: false,
            },
            assigneeName,
            pageSize,
            pageToken,
            order: {
                orderBy: 'closingTimestamp',
                orderDesc: true,
            },
        };

        const queryParams: ITaskQueryParamsRequest = {
            loadDownloadLink: false,
            loadViewLink: true,
            loadPreviewImageLink: true,
        };

        return this.getSurveyPostTasks(post, filter, queryParams);
    }

    getAllOpenOrExpiredSurveyTaskList(
        post: SurveyPost,
        pageSize: number,
        assigneeName: string | null,
        pageToken: string | null,
    ): Observable<SurveyTaskList> {
        const filter: ITaskFilters = {
            ...emptyTaskFilters(ITaskType.SURVEY),
            assigneeName,
            pageSize,
            pageToken,
            state: {
                expiredAssignedToMe: false,
                expiringAssignedToMe: false,
                closedAssignedToMe: null,
            },
            order: {
                orderBy: 'assignee',
                orderDesc: false,
            },
        };

        const queryParams: ITaskQueryParamsRequest = {
            loadDownloadLink: false,
            loadViewLink: true,
            loadPreviewImageLink: true,
        };

        return this.getSurveyPostTasks(post, filter, queryParams);
    }

    private getSurveyPostTasks(
        post: SurveyPost,
        filter: ITaskFilters,
        queryParams: ITaskQueryParamsRequest,
    ): Observable<SurveyTaskList> {
        const body = TaskFilterSerialize.taskListFilter(filter);
        body.calculateTotalItemsCount = true;

        return this.http
            .post<any>(`${this.dataBaseUrl}/post-tasks/${post.id}`, body, {
                params: {
                    loadPreviewImageLink: String(
                        queryParams.loadPreviewImageLink,
                    ),
                    loadViewLink: String(queryParams.loadViewLink),
                    loadDownloadLink: String(queryParams.loadDownloadLink),
                },
            })
            .pipe(
                map((record) =>
                    toSurveyTaskList(
                        record,
                        post,
                        filter,
                        this.configurationService,
                    ),
                ),
            );
    }

    exportAnswersCsv(
        postId: number,
    ): Observable<{ blob: Blob; filename: string }> {
        return this.http
            .post(
                `${this.dataBaseUrl}/${postId}/survey-answers/csv-export`,
                {
                    postId,
                },
                {
                    observe: 'response',
                    responseType: 'blob',
                },
            )
            .pipe(
                map((response) => ({
                    blob: response.body as Blob,
                    filename: extractFilenameFromHttpResponseHeader(
                        response.headers,
                    ),
                })),
            );
    }
}
