import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ApplyPipe,
    AvatarComponent,
    ButtonComponent,
    ChipComponent,
    DeltaViewComponent,
    IconButtonComponent,
    LoaderComponent,
} from '@interacta-shared/ui';
import { LocalFileSelectComponent } from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { CommentFullscreenEditorComponent } from './components/comment-fullscreen-editor/comment-fullscreen-editor.component';
import { PostCommentInputFooterComponent } from './components/post-comment-input-footer/post-comment-input-footer.component';
import { PostCommentInputComponent } from './components/post-comment-input/post-comment-input.component';
import { PostCommentQuoteComponent } from './components/post-comment-quote/post-comment-quote.component';
import { PostCommentVoiceMessageComponent } from './components/post-comment-voice-message/post-comment-voice-message.component';

const components = [
    CommentFullscreenEditorComponent,
    PostCommentInputComponent,
    PostCommentQuoteComponent,
    PostCommentVoiceMessageComponent,
];

const standalonePipes = [ApplyPipe];

const standaloneComponents = [
    IconComponent,
    AvatarComponent,
    ButtonComponent,
    ChipComponent,
    IconButtonComponent,
    LoaderComponent,
    DeltaViewComponent,
    LocalFileSelectComponent,
];

@NgModule({
    declarations: [...components, PostCommentInputFooterComponent],
    exports: [...components],
    imports: [
        CommonModule,
        SharedV2Module,
        ...standaloneComponents,
        ...standalonePipes,
        AttachmentsModule,
    ],
})
export class PostCommentModule {}
