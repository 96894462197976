import { Injectable } from '@angular/core';
import { AppState } from '@modules/core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    catchError,
    concatMap,
    filter,
    map,
    of,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { AttachmentSectionService } from '../../services/attachment-section.service';
import * as sectionsActions from './sections.actions';
import { active, attachmentItems, containsText } from './sections.selectors';

@Injectable()
export class SectionsEffects {
    requestFetchSectionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sectionsActions.requestFetchSectionsList),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(active),
                        this.store.select(containsText),
                        this.store.select(
                            attachmentItems(action.attachment.id),
                        ),
                    ),
                ),
            ),
            filter(
                ([action, active, prevContainsText, items]) =>
                    active &&
                    (action.containsText !== prevContainsText ||
                        items == null ||
                        (items.nextPageToken.tag === 'firstLoading' &&
                            !items.isFetching)),
            ),
            map(([action]) => sectionsActions.fetchSectionsList(action)),
        ),
    );

    fetchSectionsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sectionsActions.fetchSectionsList),
            filter(
                ({ attachment }) =>
                    attachment.isMediaVideo || attachment.isMediaAudio,
            ),
            switchMap(({ attachment, containsText, pageToken }) =>
                this.attachmentSectionService
                    .getAttachmentSections(
                        attachment.id,
                        containsText,
                        pageToken,
                    )
                    .pipe(
                        map((items) => {
                            return sectionsActions.fetchSectionsListSuccess({
                                items,
                                containsText,
                                attachmentId: attachment.id,
                            });
                        }),
                        catchError((error) => {
                            return of(
                                sectionsActions.fetchSectionsListError({
                                    error,
                                    attachmentId: attachment.id,
                                }),
                            );
                        }),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private attachmentSectionService: AttachmentSectionService,
        private store: Store<AppState>,
    ) {}
}
