import { Injector } from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { IFilePickerAttachment } from '@modules/state/models/gallery-state.model';
import { createAction, props } from '@ngrx/store';

export const open = createAction(
    '[Post Preview] Open',
    props<{
        postId: number;
        left: number;
        top: number;
        visibleCustomFields: 'detail' | 'preview';
        showQuickActionsBar: boolean;
        target: 'detail' | 'edit';
        injector?: Injector | null;
    }>(),
);
export const close = createAction('[Post Preview] Close');

export const fetchPost = createAction(
    '[Post Preview] Fetch Post',
    props<{ postId: number }>(),
);

export const fetchPostSuccess = createAction(
    '[Post Preview] Fetch Post Success',
    props<{ post: GenericPost }>(),
);

export const fetchPostError = createAction(
    '[Post Preview] Fetch Post Error',
    props<{ postId: number; error: any }>(),
);

export const fetchFilePickerTemporaryContentViewLink = createAction(
    '[Post Preview] Fetch File Picker TemporaryContentViewLink',
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
    }>(),
);

export const fetchFilePickerTemporaryContentViewLinkSuccess = createAction(
    '[Post Preview] Fetch File Picker TemporaryContentViewLink Success',
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
        attachments: IAttachment[];
    }>(),
);
export const fetchFilePickerTemporaryContentViewLinkError = createAction(
    '[Post Preview] Fetch File Picker TemporaryContentViewLink Error',
    props<{
        error: any;
    }>(),
);
