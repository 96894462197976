<interacta-attachment-highlight
    *ngIf="attachment.addedInLastRework || attachment.addedInLastReview"
    [badgeClass]="
        attachment.addedInLastReview ? 'bg-system-warning' : 'bg-status-F'
    "
    [ringClass]="
        attachment.addedInLastReview ? 'ring-system-warning' : 'ring-status-F'
    "
    [tooltip]="
        (attachment.addedInLastReview
            ? 'DASHBOARD.ATTACHMENTS.LAST_REVIEW'
            : 'DASHBOARD.ATTACHMENTS.LAST_REWORK'
        ) | translate
    "
>
    <interacta-icon
        *ngIf="attachment.addedInLastReview; else lastRework"
        class="h-24 w-24"
        [icon]="'pen-pad'"
    ></interacta-icon>
    <ng-template #lastRework>
        <!-- Do not translate -->
        <span class="typo-xs-bold line-clamp-1">New</span></ng-template
    >
</interacta-attachment-highlight>
