<div class="flex flex-col gap-y-8 pb-8">
    <ng-container
        *ngFor="
            let attachment of attachments.attachments;
            let index = index;
            let first = first;
            trackBy: trackByFn
        "
    >
        <interacta-separator
            *ngIf="preview && !first"
            [thickness]="'soft'"
        ></interacta-separator>
        <interacta-attachment-row
            [aiEnabled]="aiEnabled"
            [attachment]="attachment"
            [attachmentSection]="attachmentSection"
            [canShare]="canShare"
            [customButtons]="customButtons"
            [enableMenu]="enableMenu"
            [enableSelection]="enableSelection"
            [hashtagsAvailable]="hashtagsAvailable"
            [preview]="preview"
            [selected]="
                enableSelection &&
                (selectionRecord[attachment.id]?.selected || false)
            "
            [selectedPreview]="
                enableSelection &&
                (selectionRecord[attachment.id]?.preview || false)
            "
            [showOriginField]="showOriginField"
            (delete)="delete.emit(attachment)"
            (download)="download.emit(attachment)"
            (edit)="edit.emit(attachment)"
            (editHashtags)="editHashtags.emit(attachment)"
            (itemMouseEnter)="itemMouseEnter.emit(attachment)"
            (itemMouseLeave)="itemMouseLeave.emit(attachment)"
            (manageVersion)="manageVersion.emit(attachment)"
            (openAttachment)="openAttachment.emit({ index, showInfo: $event })"
            (sendToAI)="sendToAI.emit(attachment)"
            (share)="share.emit(attachment)"
            (toggleSelectAttachment)="
                toggleSelectAttachment.emit({ attachment })
            "
        ></interacta-attachment-row>
    </ng-container>
    <ng-container
        *ngIf="
            attachments.isLoading &&
            attachments.pageTokenInfo.tag === 'firstLoading'
        "
    >
        <!-- PLACEHOLDERS -->
        <div
            *ngFor="let _ of 2 | array"
            class="bg-surface-300 h-44 w-full animate-pulse place-self-center rounded-lg"
        ></div>
    </ng-container>
    <interacta-load-more
        *ngIf="attachments.pageTokenInfo.tag === 'regularLoading'"
        class="self-center"
        [isLoading]="attachments.isLoading"
        [label]="'SHOW_MORE' | translate"
        [size]="'large'"
        (loadMore)="loadMore.emit()"
    >
    </interacta-load-more>
</div>
