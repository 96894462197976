<div
    class="flex items-center space-x-8 rounded-3xl px-16 py-4"
    [ngClass]="{
        'bg-surface-A ': bgColor === 'surface-A',
        'bg-surface-B': bgColor === 'surface-B'
    }"
>
    <interacta-image
        alt="{{ attachment.name }}'s icon"
        [classes]="slim ? 'w-16 h-16' : 'w-24 h-24'"
        [src]="attachment.iconPath || ''"
    ></interacta-image>

    <interacta-inner-ellipses-text
        class="typo-sm min-w-0"
        [bgColor]="bgColor"
        [matTooltip]="attachment.name"
        [ngClass]="{ 'typo-xs': slim }"
        [text]="attachment.name"
    ></interacta-inner-ellipses-text>

    <interacta-icon
        *ngIf="attachment.drive"
        class="mr-4 h-24 w-24"
        icon="google-drive"
        [attr.href]="attachment.drive?.webViewLink"
        [ngClass]="{ 'h-16 w-16': slim }"
    ></interacta-icon>

    <button
        *ngIf="showHashtagIcon && attachment.hashtags?.length"
        type="button"
        [disabled]="disableHashtagIcon"
        (click)="hashtagIconClick.emit()"
    >
        <interacta-icon
            icon="tag"
            [ngClass]="{ 'h-24 w-24': !slim, 'h-16 w-16': slim }"
        ></interacta-icon>
    </button>

    <button
        *ngIf="showVersionIcon && (attachment.versionNumber ?? 1) > 1"
        type="button"
        (click)="versionIconClick.emit(); $event.stopPropagation()"
    >
        <interacta-icon
            class="h-24' w-24"
            icon="history-clock"
            [attr.aria-label]="
                'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT' | translate
            "
            [matTooltip]="'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT' | translate"
        ></interacta-icon>
    </button>
</div>
