import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Palette } from '@interacta-shared/ui';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachment-chip[attachment][bgColor]',
    templateUrl: './attachment-chip.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentChipComponent {
    @Input() attachment!: IAttachment;
    @Input() bgColor!: Extract<Palette, 'surface-A' | 'surface-B'>;
    @Input() slim = false;
    @Input() showVersionIcon = false;
    @Input() showHashtagIcon = true;
    @Input() disableHashtagIcon = false;

    @Output() hashtagIconClick = new EventEmitter<void>();
    @Output() versionIconClick = new EventEmitter<void>();
}
