<div
    *ngIf="{
        attachments: attachments$ | async,
        thumbList: thumbList$ | async,
        chipList: chipList$ | async,

        pendingAttachments: attachmentInputService.pendingAttachments$ | async,
        onLoading: attachmentInputService.onLoading$ | async,
        selectionRecord: selectionService.getSelectionRecord$() | async,
        selection: selection$ | async,
        driveEnabled: allowDrive && googleAPIService.driveIntegrationEnabled
    } as $"
>
    <interacta-attachment-selection-actions-bar
        *ngIf="
            $.attachments &&
            $.attachments.length > 0 &&
            $.selection &&
            $.selection.attachments.length > 0
        "
        class="mt-12 block"
        @selection-bar
        [bgColor]="bgColor"
        [canDelete]="$.selection.canDelete"
        [canDownload]="false"
        [canShare]="$.selection.canShare"
        [canTag]="$.selection.canTag"
        [itemsSelected]="$.selection.attachments"
        [withSelectAll]="true"
        (delete)="deleteAttachments($.selection.attachments)"
        (deselectAll)="selectionService.reset()"
        (editHashtags)="openEditHashtagsDialog($.selection.attachments)"
        (selectAll)="selectionService.selectAll()"
    ></interacta-attachment-selection-actions-bar>

    <!-- lista allegati -->
    <div
        *ngIf="$.selectionRecord && $.attachments && $.attachments.length"
        class="flex flex-col space-y-16 py-16"
    >
        <div
            *ngIf="$.thumbList && $.thumbList.length"
            class="flex flex-wrap gap-16"
        >
            <interacta-attachment-form-thumb
                *ngFor="let attachment of $.thumbList; trackBy: trackAttachment"
                [allowAddVersion]="canAddVersion"
                [attachment]="attachment"
                [driveEnabled]="$.driveEnabled"
                [hashtagsAvailable]="availableHashtags.length > 0"
                [isSelectionActive]="
                    $.selectionRecord | apply: isSelectionActive
                "
                [selected]="$.selectionRecord[attachment.id]?.selected || false"
                [selectedPreview]="
                    $.selectionRecord[attachment.id]?.preview || false
                "
                (cancelUpload)="cancelUpload(attachment)"
                (editAttachment)="openRenameDialog(attachment)"
                (itemMouseEnter)="selectionService.hover(attachment.id)"
                (itemMouseLeave)="selectionService.unhover()"
                (manageVersion)="manageVersion(attachment)"
                (removeAttachment)="removeAttachment(attachment)"
                (restoreAttachment)="restoreAttachment(attachment)"
                (retryUpload)="retryUpload(attachment)"
                (toggleSelectAttachment)="
                    selectionService.toggleSelect(attachment.id)
                "
            ></interacta-attachment-form-thumb>
        </div>

        <div
            *ngIf="$.chipList && $.chipList.length"
            class="flex w-full flex-col space-y-16"
        >
            <div
                *ngFor="let attachment of $.chipList; trackBy: trackAttachment"
                class="flex items-baseline"
            >
                <interacta-checkbox
                    variant="circle"
                    [checked]="$.selectionRecord[attachment.id]?.selected"
                    [value]="attachment.id"
                    (changeCheck)="selectionService.toggleSelect(attachment.id)"
                    (mouseenter)="selectionService.hover(attachment.id)"
                    (mouseleave)="selectionService.unhover()"
                ></interacta-checkbox>

                <interacta-attachment-form-chip
                    class="min-w-0"
                    [allowAddVersion]="canAddVersion"
                    [attachment]="attachment"
                    [bgColor]="bgColor"
                    [driveEnabled]="$.driveEnabled"
                    [hashtagsAvailable]="availableHashtags.length > 0"
                    [selected]="
                        $.selectionRecord[attachment.id]?.selected || false
                    "
                    (cancelUpload)="cancelUpload(attachment)"
                    (editAttachment)="openRenameDialog(attachment)"
                    (editHashtags)="openEditHashtagsDialog([attachment])"
                    (manageVersion)="manageVersion(attachment)"
                    (removeAttachment)="removeAttachment(attachment)"
                    (restoreAttachment)="restoreAttachment(attachment)"
                    (retryUpload)="retryUpload(attachment)"
                >
                </interacta-attachment-form-chip>
            </div>
        </div>
    </div>

    <div
        *ngIf="allowFileSystem || allowDrive"
        class="flex flex-wrap items-center gap-12"
    >
        <!-- Allegati locali -->
        <interacta-local-file-select
            *ngIf="allowFileSystem"
            [acceptedMimeType]="acceptedMimeType"
            [appearance]="'border'"
            [label]="'POST_CREATE.CHOOSE_FILE' | translate"
            [uploader]="fileUploader"
        />
        <interacta-drive-file-select
            *ngIf="allowDrive && googleAPIService.driveIntegrationEnabled"
            size="regular"
            [acceptedMimeType]="acceptedMimeType"
            [appearance]="'border'"
            [label]="'POST_CREATE.ADD_FROM_DRIVE' | translate"
            (addDriveAttachments)="addDriveAttachments($event)"
        ></interacta-drive-file-select>
    </div>
</div>

<ng-container *ngIf="isEditingAttachment$ | async as isEditingAttachment">
    <interacta-attachment-edit-dialog
        *ngIf="isEditingAttachment.attachments"
        [attachments]="isEditingAttachment.attachments"
        [editMode]="isEditingAttachment.editMode"
        [hashtags]="availableHashtags"
        [isDialogOpen]="isEditingAttachment.isOpen"
        (closeDialog)="closeDialog()"
        (editAttachment)="editAttachment($event)"
        (editHashtags)="editHashtags($event)"
    >
    </interacta-attachment-edit-dialog>
</ng-container>
