import { VoiceCommentPlaybackRateType } from '@modules/post/models/comment.model';
import { createReducer, on } from '@ngrx/store';
import {
    setVoiceCommentsCurrentlyPlaying,
    setVoiceCommentsPlaybackRate,
} from './comments.actions';

export const commentsFeatureKey = 'comments';

export interface CommentsState {
    voiceCommentsPlaybackRate: VoiceCommentPlaybackRateType;
    voiceCommentsCurrentlyPlaying: string | null;
}

const initialState: CommentsState = {
    voiceCommentsPlaybackRate: 1,
    voiceCommentsCurrentlyPlaying: null,
};

export const commentsReducer = createReducer<CommentsState>(
    initialState,

    on(setVoiceCommentsPlaybackRate, (state, { rate }) => ({
        ...state,
        voiceCommentsPlaybackRate: rate,
    })),

    on(setVoiceCommentsCurrentlyPlaying, (state, { id }) => ({
        ...state,
        voiceCommentsCurrentlyPlaying: id,
    })),
);
