import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import {
    IScreen,
    IScreenContext,
    IScreenEdit,
    ScreenSerialize,
} from '@modules/communities/models/screen.model';
import {
    IWorkflowChangeScreenData,
    IWorkflowChangeStatus,
    IWorkflowOperation,
} from '@modules/communities/models/workflow/workflow.model';
import { ListenChangesDeserialize } from './../../post/models/listen-changes/listen-changes.model';
import {
    ScreenDeserialize,
    ScreenOnChangeType,
} from './../models/screen.model';

import { Location } from '@angular/common';
import { AuthService } from '@interacta-shared/data-access-auth';
import { CustomError } from '@interacta-shared/data-access-error';
import { Index } from '@interacta-shared/util';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import { OnChangeMessage } from '@modules/post/models/listen-changes/listen-changes.model';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICustomFieldDefinition } from '../models/custom-metadata/custom-metadata.model';
import {
    toWorkflowChangeStatus,
    toWorkflowScreenEdit,
} from '../models/workflow/workflow.deserialize';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;

    private errorHandler = (error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
            error = new CustomError(
                'WORKFLOW.LABEL_TRANSITION_CONCURRENCY_ERROR',
                true,
                true,
            );
        }
        return throwError(error);
    };

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private location: Location,
    ) {}

    public setWorkflowState(
        post: ICustomPost,
        workflowOperation: IWorkflowOperation,
        screen: IScreenEdit | null,
    ): Observable<IWorkflowChangeStatus> {
        const currUser = this.authService.getCurrentUserData();
        if (!currUser) {
            throw new Error(
                'User must be logged in order to execute post workflow operation',
            );
        }
        return this.http
            .post<any>(
                `${this.baseUrl}/manage/execute-post-workflow-operation/${post.id}/${workflowOperation.id}`,
                ScreenSerialize.executeScreenInTransition(screen),
            )
            .pipe(
                map((res) => toWorkflowChangeStatus(res, currUser)),
                catchError(this.errorHandler),
            );
    }

    public editScreen(
        post: ICustomPost,
        screen: IScreenEdit,
    ): Observable<IWorkflowChangeScreenData> {
        const dst = ScreenSerialize.editScreen(screen);

        return this.http
            .put<any>(
                `${this.baseUrl}/manage/edit-post-workflow-screen-data/${
                    post.id
                }/${screen.occToken ?? ''}`,
                dst,
            )
            .pipe(
                map((res) => toWorkflowScreenEdit(res)),
                catchError(this.errorHandler),
            );
    }

    public getScreenOnChange(
        communityId: number,
        screen: IScreen,
        fieldDefinitions: ICustomFieldDefinition[],
        context: IScreenContext,
        changedScreenFieldId: number,
    ): Observable<ScreenOnChangeType> {
        return this.http
            .post<{
                messageInfo: OnChangeMessage | null;
                postDataHasChanged: boolean;
                dataHasChanged: boolean;
                screenData: any;
            }>(
                `${this.baseUrl}/manage/post-workflow-screen-data-for-change/${communityId}`,
                { context: ScreenSerialize.screenContext(context) },
                { params: { changedScreenFieldId } },
            )
            .pipe(
                map((dataForChange) => {
                    const record = {
                        screen,
                        currentWorkflowState: screen.currentWorkflowState,
                        screenData: dataForChange.screenData,
                        screenOccToken: screen.occToken,
                    };
                    const screenDeserialized = ScreenDeserialize.screenDetails(
                        context.postId,
                        fieldDefinitions,
                        record,
                        screen.workflowScreen,
                    );

                    return {
                        screen: screenDeserialized,
                        dataHasChanged: dataForChange.dataHasChanged,
                        messageInfo: ListenChangesDeserialize.formatMessage(
                            dataForChange.messageInfo,
                        ),
                    };
                }),
                catchError(this.errorHandler),
            );
    }

    public getScreenForEdit(
        post: ICustomPost,
        fieldDefinitions: ICustomFieldDefinition[],
        transitionId?: Index,
    ): Observable<IScreen> {
        const queryParam = transitionId
            ? `?workflowOperationId=${transitionId}`
            : ''; // optional param - if this value is null API get last screen available if exists
        return this.http
            .get<any>(
                `${this.baseUrl}/manage/post-workflow-screen-data-for-edit/${post.id}${queryParam}`,
            )
            .pipe(
                map((record) =>
                    ScreenDeserialize.screenDetailsForEdit(
                        post.id,
                        fieldDefinitions,
                        record,
                    ),
                ),
                catchError(this.errorHandler),
            );
    }

    async delegateOperation(
        operation: IWorkflowOperation,
        postId: number,
    ): Promise<void> {
        if (!operation.metadata?.delegateUrl) {
            throw new Error(`Operation ${operation.id} is not delegate`);
        }

        const postDetailLink = `${this.getFullBaseHref()}/post/${postId}`;
        let url = operation.metadata.delegateUrl
            .replace('{postId}', postId.toString())
            .replace('{workflowOperationId}', operation.id.toString())
            .replace('{callbackUrl}', btoa(postDetailLink));

        if (url.includes('{accessToken}')) {
            url = url.replace(
                '{accessToken}',
                await firstValueFrom(this.authService.renewAccessToken()),
            );
        }

        location.href = url;
    }

    private getFullBaseHref(): string {
        const angularRoute = this.location.path();
        return window.location.href.replace(`${angularRoute}`, '');
    }
}
