import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Palette } from '@interacta-shared/ui';
import { Index } from '@interacta-shared/util';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachment-selection-actions-bar',
    templateUrl: './attachment-selection-actions-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentSelectionActionsBarComponent implements OnChanges {
    @Input() customButtons: ICustomButton[] = [];
    @Input() communityId!: number;
    @Input() itemsSelected: IAttachment[] = [];

    @Input() canShare = true;
    @Input() canTag = true;
    @Input() canDelete = true;
    @Input() canDownload = true;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() withSelectAll = false;

    @Output() selectAll = new EventEmitter<void>();
    @Output() deselectAll = new EventEmitter<void>();
    @Output() share = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() delete = new EventEmitter<number>();
    @Output() downloadSelected = new EventEmitter<void>();

    areAttachmentsRefPresent = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.itemsSelected)
            this.areAttachmentsRefPresent = this.itemsSelected.some(
                (a) => a.isAttachmentRef,
            );
    }

    attachmentIds(attachments: IAttachment[]): Index[] {
        return attachments.map((attachment) => attachment.id);
    }
}
