import { AttachmentSection } from './attachment-section.model';

export const toAttachmentSection = (record: any): AttachmentSection => {
    return {
        id: record.id,
        audioVideoSectionPosition: record.audioVideoSectionPosition,
        docSectionPosition: record.docSectionPosition,
        highlights: record.highlightsMap
            ? '<..' + record.highlightsMap.searchableContent[0] + '...>'
            : null,
        imageSectionPosition: record.imageSectionPosition,
        searchableContent: record.searchableContent,
        sectionDiarizationInfo: record.sectionDiarizationInfo,
        sectionPosition: record.sectionPosition,
        spreadsheetSectionPosition: record.spreadsheetSectionPosition,
    };
};
