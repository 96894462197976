import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commentsFeatureKey, CommentsState } from './comments.reducer';

const selectComments = createFeatureSelector<{
    [commentsFeatureKey]: CommentsState;
}>('post');

export const selectCommentsFeature = createSelector(
    selectComments,
    (state) => state[commentsFeatureKey],
);

export const selectPlaybackRate = createSelector(
    selectCommentsFeature,
    (state) => state.voiceCommentsPlaybackRate,
);

export const selectCurrentlyPlaying = createSelector(
    selectCommentsFeature,
    (state) => state.voiceCommentsCurrentlyPlaying,
);
