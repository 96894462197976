<interacta-menu-decorator *ngIf="{ theme: theme$ | async } as $">
    <interacta-button-menu
        *ngIf="canEditHashtags"
        icon="tag"
        [label]="'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate"
        (click)="editHashtags.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="canEdit"
        icon="pen-pad"
        [label]="'DASHBOARD.ATTACHMENTS.RENAME' | translate"
        (click)="edit.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="canManageVersion || versionNumber > 1"
        icon="history-clock"
        [label]="
            (canManageVersion
                ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
            ) | translate
        "
        (click)="manageVersion.emit()"
    ></interacta-button-menu>

    <interacta-button-menu
        *ngIf="canDownload"
        icon="download"
        [label]="'DOWNLOAD' | translate"
        (click)="download.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="!canDownload && isDrive"
        icon="external-link"
        [label]="'OPEN_IN_GOOGLE_DRIVE' | translate"
        (click)="openDrive.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="canDelete"
        icon="trash-bin"
        [label]="'DASHBOARD.ATTACHMENTS.DELETE' | translate"
        (click)="delete.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="canShare && !attachment.isAttachmentRef"
        icon="share"
        [label]="'DASHBOARD.ATTACHMENTS.SHARE' | translate"
        (click)="share.emit()"
    ></interacta-button-menu>
    <interacta-button-menu
        *ngIf="
            $.theme && aiEnabled && attachment | apply: isAttachmentSupported
        "
        (click)="sendToAI.emit()"
    >
        <interacta-image
            alt=""
            [classes]="'w-24 h-24'"
            [src]="
                $.theme === 'dark'
                    ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                    : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
            "
        ></interacta-image>
        <span>{{ 'AI.ASK_AINTERACTA' | translate }}</span>
    </interacta-button-menu>

    <ng-container
        *ngIf="
            customButtons
                | filterAttachmentCustomButtons: attachment as customButtonsList
        "
    >
        <ng-container *ngIf="customButtonsList?.length">
            <interacta-separator class="px-16"></interacta-separator>
            <interacta-custom-button
                *ngFor="let customButton of customButtonsList"
                [communityId]="attachment.communityId"
                [customButton]="customButton"
                [ids]="[attachment.id]"
                [mode]="'detail'"
            ></interacta-custom-button
        ></ng-container>
    </ng-container>
</interacta-menu-decorator>
