import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachment-feedback-highlight',
    templateUrl: './attachment-feedback-highlight.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFeedbackHighlightComponent {
    @Input({ required: true }) attachment!: Pick<
        IAttachment,
        'addedInLastRework' | 'addedInLastReview'
    >;
}
