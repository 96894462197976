import { fromDateTimeToLocalString } from '@interacta-shared/data-access-configuration';
import { flatten, mapArrayById } from '@interacta-shared/util';
import { FieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { fromFieldMetadataV2 } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import {
    cleanFromMetadataBase,
    extractCustomFieldTypeFromAdminInputForm,
} from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import {
    cleanEmptyi18nString,
    getCleanI18nDelta2Server,
} from '@modules/core/helpers/generic.utils';
import { extractMemberInnerIdFromMembersArray } from '@modules/core/models/member/member.utils';
import { fromCustomFieldsConfigurationEnumValues } from '@modules/custom-fields-configuration/models/custom-fields-configuration.serialize';
import { getFieldId } from '@modules/custom-fields-configuration/models/custom-fields-configuration.utils';
import {
    SurveyPostEdit,
    SurveyQuestionForCreate,
} from '@modules/post/models/survey-post/survey-post.model';
import { BasePostSerialize } from '../base-post.model';
import { SurveyPostCreate } from './survey-post.model';
export class SurveyPostSerialize extends BasePostSerialize {
    static surveyRecipients = (post: SurveyPostCreate): any => {
        const groupIds = extractMemberInnerIdFromMembersArray(
            post.recipients?.groups,
        );

        const groupTagIds =
            mapArrayById(post.recipients?.groupTags) ?? undefined;

        const groupTags = post.recipients?.groupTagExcludedGroups
            ? post.recipients.groupTagExcludedGroups.map((gteg) => ({
                  tagId: gteg.tagId,
                  excludedGroupIds:
                      extractMemberInnerIdFromMembersArray(
                          gteg.excludedGroups,
                      ) ?? [],
              }))
            : undefined;

        return {
            userIds: extractMemberInnerIdFromMembersArray(
                post.recipients?.users,
            ),
            groupIds,
            groupTags,
            sendEditPostNotifications: false,
            recipientGroupCardinality:
                groupIds?.length || groupTagIds?.length
                    ? post.recipients?.recipientGroupCardinality
                    : undefined,
        };
    };

    static surveyPostDetails = (post: SurveyPostCreate): any => {
        const startAt = post.startAt
            ? fromDateTimeToLocalString(post.startAt.localDatetime)
            : undefined;
        const expiration = post.expiration
            ? fromDateTimeToLocalString(post.expiration.localDatetime)
            : undefined;
        const closeAt = post.scheduledClosing
            ? fromDateTimeToLocalString(post.scheduledClosing.localDatetime)
            : undefined;

        const record: any = {
            languageCode: post.surveyLanguage.code,
            survey: {
                surveyType: post.surveyType,
                startAt: { localDatetime: startAt },
                expiration: { localDatetime: expiration },
                scheduledClosing: { localDatetime: closeAt },
                timezone: post.timezone ? post.timezone.zoneId : undefined,
                editAnswerEnabled: post.editAnswerEnabled,
                customRecipients: SurveyPostSerialize.surveyRecipients(post),
                answerViewersTypeId: post.answerViewerType,
                recipientAnswerViewers: post.recipientAnswerViewers,
                customAnswerViewerUserIds: extractMemberInnerIdFromMembersArray(
                    post.customAnswerViewer?.users,
                ),
                customAnswerViewerGroupIds:
                    extractMemberInnerIdFromMembersArray(
                        post.customAnswerViewer?.groups,
                    ),
                managerUserIds: extractMemberInnerIdFromMembersArray(
                    post.managers?.users,
                ),
                managerGroupIds: extractMemberInnerIdFromMembersArray(
                    post.managers?.groups,
                ),
                reminders: [],
                fieldDefinitions: (post.surveyQuestions || []).map((q, idx) =>
                    fromSurveyQuestionForCreate(
                        q,
                        idx,
                        post.surveyQuestions ?? [],
                    ),
                ),
            },
        };

        return BasePostSerialize.basePostDetails(record, post);
    };

    static newSurveyPost = (post: SurveyPostCreate): any => {
        const record: any = SurveyPostSerialize.surveyPostDetails(post);
        return BasePostSerialize.newBasePost(record, post);
    };

    static editSurveyPost = (post: SurveyPostEdit): any => {
        const record: any = SurveyPostSerialize.surveyPostDetails(post);
        record.survey.customRecipients = {
            ...record.survey.customRecipients,
            sendEditPostNotifications: post.sendNotification ?? false,
        };
        return BasePostSerialize.editBasePost(record, post);
    };

    static copySurveyPost = (post: SurveyPostEdit): any => {
        const record = SurveyPostSerialize.surveyPostDetails(post);
        return SurveyPostSerialize.copyBasePost(record, post);
    };
}

export const fromSurveyQuestionForCreate = (
    question: SurveyQuestionForCreate,
    order: number,
    allQuestions: SurveyQuestionForCreate[],
): any => {
    const type = extractCustomFieldTypeFromAdminInputForm(
        question,
        question.localParentId ?? undefined,
    );
    const allOtherEnumValues =
        type === FieldType.SELECT_HIERARCHICAL
            ? (allQuestions || [])
                  .filter(
                      (f) =>
                          getFieldId(f) !== getFieldId(question) &&
                          f.enumValues?.length,
                  )
                  .map((f) => f.enumValues!)
            : null;
    const flattenedOtherEnumValues = allOtherEnumValues
        ? flatten(allOtherEnumValues)
        : [];

    const record = {
        id: question.id ?? null,
        //localId (as clientRef) only needed if there's no database id AND there are children that refer to this field
        clientRef:
            !question.id && question.childIds?.length > 0
                ? question.localId
                : null,
        label: cleanEmptyi18nString(question.label),
        description: getCleanI18nDelta2Server(question.description),
        type,
        validations: { ...question.validations },
        metadata: {
            ...cleanFromMetadataBase(question.originalMetadata),
            ...fromFieldMetadataV2(question.metadata),
        },
        enumValues: (question.enumValues || []).map((i) =>
            fromCustomFieldsConfigurationEnumValues(
                i,
                flattenedOtherEnumValues,
            ),
        ),
        parentId: question.parentId ?? null,
        parentClientRef: question.localParentId ?? null,
        required: question.required ?? false,
        readonly: question.readonly ?? false,
        order,
        deleted: question.deleted ?? false,
    };
    return record;
};
