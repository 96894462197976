import { getNextPageToken } from '@interacta-shared/util';
import { PostLinkFilters } from './post-link-and-relation.model';

export const fromPostLinkAndRelationFilters = (
    filters: PostLinkFilters,
): any => ({
    pageToken: getNextPageToken(filters.pageToken),
    pageSize: filters.pageSize,
    calculateTotalItemsCount: filters.calculateTotalItemsCount ?? true,
    orderDesc: filters.order?.orderDesc,
    orderBy: filters.order?.orderBy,
    currentWorkflowStatusIds: filters.workflowStatus?.ids,
});
