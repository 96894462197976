<ng-container
    *ngIf="{
        canEdit: (attachment | apply: canEditAttachment),
        canEditHashtags:
            ({ attachment, hashtagsAvailable } | apply: canEditHashtags),
        canDelete: attachment.capabilities?.canDelete || false,
        canManageVersion:
            ({
                attachment,
                driveIntegrationEnabled:
                    googleAPIService.driveIntegrationEnabled
            } | apply: canManageVersionAttachment),
        versionNumber: attachment.versionNumber ?? 1,
        originFieldLabel: originFieldLabel$ | async
    } as $"
>
    <div
        class="flex select-none items-center"
        [ngClass]="{
            'gap-x-16': attachmentSection === 'community',
            'gap-x-4': attachmentSection === 'detail'
        }"
        (mouseenter)="itemMouseEnter.emit()"
        (mouseleave)="itemMouseLeave.emit()"
    >
        <div *ngIf="enableSelection" class="shrink-0">
            <button
                interacta-icon-button
                type="button"
                [active]="selected"
                [icon]="
                    selected || selectedPreview
                        ? 'check-circle-true'
                        : 'radio-button-false'
                "
                [ngClass]="{
                    'text-text-mid-contrast': !selected,
                    'text-text-on-avatar': !selected && selectedPreview
                }"
                (click)="clickToggleSelectAttachment()"
            ></button>
        </div>

        <div
            class="relative flex min-w-0 grow cursor-pointer items-center gap-x-16"
            [ngClass]="{
                'border-border-primary border-2': selected,
                'border-transparent-black-not-black-30 border-2':
                    selectedPreview,
                'bg-surface-A rounded-full px-16 py-8': !preview
            }"
            (click)="clickAttachment()"
        >
            <div
                *ngIf="selectedPreview"
                class="absolute inset-0 z-10 rounded-full"
                @selectionOverlay
                [ngClass]="{
                    'bg-transparent-black-not-black-30':
                        !selected && selectedPreview
                }"
            ></div>
            <div class="shrink-0">
                <interacta-image
                    alt="{{ attachment.name }}'s preview image"
                    [classes]="
                        (preview ? 'w-28 h-28' : 'w-32 h-32') +
                        ' rounded object-cover'
                    "
                    [src]="attachment.iconPath || ''"
                ></interacta-image>
            </div>
            <div class="flex min-w-0 grow flex-col gap-2">
                <interacta-inner-ellipses-text
                    class="typo-sm-bold h-20 text-left"
                    [bgColor]="preview ? 'surface-C' : 'surface-A'"
                    [matTooltip]="attachment.name"
                    [text]="attachment.name"
                ></interacta-inner-ellipses-text>
                <div class="typo-xs flex h-20 items-center">
                    <p class="line-clamp-1 shrink-0">
                        <span>{{ attachment.creatorUser?.fullName }}</span>

                        <ng-container
                            *ngIf="
                                attachmentSection === 'community' &&
                                attachment.post &&
                                (attachment.post.title || attachment.post.code)
                            "
                        >
                            <span>
                                {{
                                    'DASHBOARD.ATTACHMENTS.IN'
                                        | translate
                                        | lowercase
                                }}</span
                            ><a
                                class="text-text-primary text-left"
                                [routerLink]="['/post', attachment.post?.id]"
                                (click)="$event.stopPropagation()"
                            >
                                {{
                                    attachment.post.code
                                        | postCodeTitle: attachment.post.title
                                }}
                            </a>
                        </ng-container>
                    </p>
                    <interacta-chip
                        *ngIf="
                            attachment.hashtags && attachment.hashtags.length
                        "
                        class="ml-8"
                        iconLeft="tag"
                        size="small"
                        [label]="'' + attachment.hashtags.length"
                        matTooltip="{{
                            'DASHBOARD.FILTERS.HASHTAG_BANNER_COUNTER'
                                | translate
                                    : { counter: attachment.hashtags.length }
                        }}"
                        (click)="
                            openAttachment.emit(true); $event.stopPropagation()
                        "
                    ></interacta-chip>
                    <interacta-chip
                        *ngIf="$.versionNumber > 1"
                        class="ml-8"
                        iconLeft="history-clock"
                        size="small"
                        [label]="'' + $.versionNumber"
                        matTooltip="{{
                            'LIGHTBOX.VERSION'
                                | translate: { number: $.versionNumber }
                        }}"
                        (click)="
                            openAttachment.emit(true); $event.stopPropagation()
                        "
                    ></interacta-chip>
                    @if (showOriginField && $.originFieldLabel) {
                        <interacta-icon
                            class="mx-4 size-12"
                            [icon]="'dot'"
                        ></interacta-icon>
                        <p
                            class="typo-xs text-text-mid-contrast shrink overflow-hidden text-ellipsis text-nowrap"
                            [matTooltip]="$.originFieldLabel"
                        >
                            {{ 'DASHBOARD.ATTACHMENTS.IN' | translate }}
                            <span class="typo-xs-bold">{{
                                $.originFieldLabel
                            }}</span>
                        </p>
                    }
                </div>
            </div>
            <div
                class="flex items-center"
                [ngClass]="{
                    'gap-x-16': attachmentSection === 'community',
                    'gap-x-8': attachmentSection === 'detail'
                }"
            >
                <div *ngIf="!preview" class="flex shrink-0 items-center gap-8">
                    <interacta-icon
                        *ngIf="attachment.drive"
                        class="text-text-mid-contrast h-24 w-24"
                        icon="google-drive"
                        matTooltip="{{
                            'DASHBOARD.ATTACHMENTS.SOURCE'
                                | translate
                                    : {
                                          source:
                                              'DASHBOARD.ATTACHMENTS.SOURCES.GOOGLE_DRIVE'
                                              | translate
                                      }
                        }}"
                    ></interacta-icon>
                    <div
                        class="text-text-mid-contrast typo-xs min-w-72 text-right"
                        [matTooltip]="
                            attachment.lastModifyTimestamp | dateTooltip
                        "
                    >
                        {{
                            attachment.lastModifyTimestamp
                                | interactaDate: 'date'
                        }}
                    </div>
                </div>
                <div
                    #openMenuBtn="cdkOverlayOrigin"
                    cdkOverlayOrigin
                    class="flex items-center"
                >
                    <button
                        *ngIf="
                            enableMenu &&
                            ($.canEdit ||
                                $.canDelete ||
                                $.canManageVersion ||
                                $.versionNumber > 1 ||
                                attachment.drive ||
                                attachment.canDownload)
                        "
                        icon="ellipsis-vertical"
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="
                            'DASHBOARD.ATTACHMENTS.OPEN_MENU' | translate
                        "
                        (click)="isMenuOpen = true; $event.stopPropagation()"
                    ></button>
                    <interacta-menu
                        positionX="before"
                        positionY="below"
                        [open]="isMenuOpen"
                        [origin]="openMenuBtn"
                        (closing)="isMenuOpen = false"
                    >
                        <interacta-attachment-action-menu
                            [aiEnabled]="aiEnabled"
                            [attachment]="attachment"
                            [canDelete]="$.canDelete"
                            [canDownload]="attachment.canDownload ?? false"
                            [canEdit]="$.canEdit"
                            [canEditHashtags]="$.canEditHashtags"
                            [canManageVersion]="$.canManageVersion"
                            [canShare]="canShare"
                            [customButtons]="customButtons"
                            [isDrive]="!!attachment.drive"
                            [versionNumber]="$.versionNumber"
                            (click)="isMenuOpen = false"
                            (delete)="delete.emit()"
                            (download)="download.emit()"
                            (edit)="edit.emit()"
                            (editHashtags)="editHashtags.emit()"
                            (manageVersion)="manageVersion.emit()"
                            (openDrive)="openDrive()"
                            (sendToAI)="sendToAI.emit()"
                            (share)="share.emit()"
                        >
                        </interacta-attachment-action-menu>
                    </interacta-menu>
                </div>
            </div>
        </div>
    </div>
</ng-container>
