import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { fadeInOutAnimation } from '@interacta-shared/ui';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    canAddVersionAttachment,
    canDeleteAttachment,
    canEditAttachment,
    canEditHashtagsAttachment,
    srcFallback,
} from '@modules/post/models/attachment/attachment.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-attachment-form-thumb',
    templateUrl: './attachment-form-thumb.component.html',
    styles: [],
    animations: [fadeInOutAnimation('selectionOverlay')],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFormThumbComponent {
    @Input({ required: true }) attachment!: IAttachment;
    @Input() allowDelete = true;
    @Input() allowEdit = true;
    @Input() allowAddVersion = true;
    @Input() selected = false;
    @Input() selectedPreview = false;
    @Input() isSelectionActive = false;
    @Input() hashtagsAvailable = false;
    @Input() driveEnabled = false;

    @Output() retryUpload = new EventEmitter<void>();
    @Output() cancelUpload = new EventEmitter<void>();
    @Output() editAttachment = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() removeAttachment = new EventEmitter<void>();
    @Output() restoreAttachment = new EventEmitter<void>();
    @Output() toggleSelectAttachment = new EventEmitter();
    @Output() manageVersion = new EventEmitter<void>();
    @Output() itemMouseEnter = new EventEmitter();
    @Output() itemMouseLeave = new EventEmitter();

    hoverError$ = new BehaviorSubject<boolean>(false);
    hoverRow$ = new BehaviorSubject<boolean>(false);

    hoverCheck = false;
    previewLoaded = false;

    srcFallback = srcFallback;

    getPreview(attachment: IAttachment): string | undefined {
        return (
            attachment.temporaryContentPreviewImageHiResLink ??
            attachment.temporaryContentPreviewImageLink ??
            (attachment.isMediaImage
                ? attachment.temporaryContentViewLink
                : undefined)
        );
    }

    canAddVersionAttachment = canAddVersionAttachment;
    canDeleteAttachment = canDeleteAttachment;
    canEditAttachment = canEditAttachment;
    canEditHashtags = canEditHashtagsAttachment;
}
