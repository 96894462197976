import { IEditPostLinkSourcePost } from './post-link.model';

export const fromEditPostLink = (
    sourcePosts: IEditPostLinkSourcePost[],
    targetPostId: number,
): any => ({
    sourcePosts: sourcePosts.map((p) => ({
        id: p.id,
        occToken: p.occToken,
    })),
    targetPostIds: [targetPostId],
});
