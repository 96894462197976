import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-media-list-different-size',
    templateUrl: './media-list-different-size.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaListDifferentSizeComponent {
    @Input() mediaList!: IAttachment[];
    @Output() openMedia = new EventEmitter<{ index: number }>();

    previewLoaded = false;

    getPreview(attachment: IAttachment): string | undefined {
        const aaa =
            attachment.temporaryContentPreviewImageHiResLink ??
            attachment.temporaryContentPreviewImageLink ??
            (attachment.isMediaImage
                ? attachment.temporaryContentViewLink
                : undefined);
        return aaa;
    }
}
