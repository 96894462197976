<ng-container
    *ngIf="{
        state: dialogState$ | async,
        chipAttachment: chipAttachment$ | async,
        useVersionNameForAttachment:
            (useVersionNameForAttachment$ | async) ?? false
    } as $"
>
    <ng-container
        *ngIf="{
            isOpen: $.state?.isOpen ?? false,
            attachment: $.state?.content?.attachment,
            canAddVersion: $.state?.content?.canAddVersion ?? false,
            canEditPost: $.state?.content?.canEditPost ?? false,
            acceptedMimeType: $.state?.content?.acceptedMimeType ?? undefined,
            confirmLabel: $.state?.content?.confirmLabel ?? ''
        } as val"
    >
        <interacta-dialog
            *ngIf="val.attachment"
            [closeOnBackdropClick]="false"
            [isOpen]="val.isOpen"
            [size]="'regular'"
            (closing)="attachmentsVersionsService.close()"
        >
            <div class="typo-t6 flex" title>
                {{
                    (val.canAddVersion && !val.attachment.isAttachmentRef
                        ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                        : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
                    ) | translate
                }}
            </div>

            <interacta-dialog-description
                *ngIf="val.canAddVersion || val.canEditPost"
            >
                <ng-container *ngIf="val.attachment.isAttachmentRef">
                    <span>{{
                        'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT_SUBTITLE_ATTACHMENTS_REF'
                            | translate
                    }}</span>
                    <button
                        *ngIf="val.attachment.referencedAttachmentId"
                        class="text-text-primary underline"
                        type="button"
                        (click)="
                            goToAttachments(
                                val.attachment.referencedAttachmentId
                            )
                        "
                    >
                        {{
                            'DASHBOARD.ATTACHMENTS.VERSIONS.GO_ORIGINAL_ATTACHMENT'
                                | translate
                        }}
                    </button>
                </ng-container>

                <span
                    *ngIf="val.canAddVersion && !val.attachment.isAttachmentRef"
                    >{{
                        'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE_SUBTITLE'
                            | translate
                    }}</span
                >
                <ng-container
                    *ngIf="
                        !val.attachment.isAttachmentRef &&
                        !val.canAddVersion &&
                        val.canEditPost &&
                        (!val.attachment.drive ||
                            (val.attachment.drive &&
                                googleAPIService.driveIntegrationEnabled)) &&
                        val.attachment.post &&
                        val.attachment.post.id != null
                    "
                >
                    <span>{{
                        'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT_SUBTITLE_1'
                            | translate
                    }}</span>
                    <span
                        class="text-text-primary cursor-pointer underline"
                        (click)="goToPost(val.attachment.post.id)"
                    >
                        {{
                            'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT_SUBTITLE_2'
                                | translate
                        }}
                    </span>
                </ng-container>
            </interacta-dialog-description>

            <div
                class="mt-12 flex flex-col space-y-16 overflow-y-hidden"
                content
            >
                <ng-container *ngIf="val.canAddVersion">
                    <interacta-attachment-chip
                        *ngIf="$.chipAttachment"
                        class="mx-auto w-min max-w-full"
                        [attachment]="$.chipAttachment"
                        [bgColor]="'surface-B'"
                        [disableHashtagIcon]="true"
                    >
                    </interacta-attachment-chip>

                    <interacta-local-file-select
                        *ngIf="
                            !val.attachment.drive &&
                            !newVersionAttachments$.value.length &&
                            !val.attachment.isAttachmentRef
                        "
                        class="block py-4 text-center"
                        [acceptedMimeType]="val.acceptedMimeType"
                        [appearance]="'border'"
                        [disabled]="false"
                        [label]="
                            'DASHBOARD.ATTACHMENTS.VERSIONS.ADD' | translate
                        "
                        [uploader]="attachmentInputService.uploader"
                    />

                    <interacta-drive-file-select
                        *ngIf="
                            val.attachment.drive != null &&
                            googleAPIService.driveIntegrationEnabled &&
                            !newVersionAttachments$.value.length &&
                            !val.attachment.isAttachmentRef
                        "
                        class="block py-4 text-center"
                        [acceptedMimeType]="val.acceptedMimeType"
                        [appearance]="'border'"
                        [label]="'POST_CREATE.ADD_FROM_DRIVE' | translate"
                        [size]="'regular'"
                        (addDriveAttachments)="addDriveAttachments($event)"
                    ></interacta-drive-file-select>
                </ng-container>

                <div
                    *ngIf="attachments$ | async as versions"
                    #container
                    class="flex flex-col space-y-12 overflow-y-auto"
                    [ngClass]="{
                        'pr-8': container.scrollHeight > container.clientHeight
                    }"
                >
                    <ng-container
                        *ngFor="
                            let version of versions;
                            trackBy: trackAttachment
                        "
                    >
                        <interacta-attachment-version
                            [control]="newVersionNameControl"
                            [useVersionNameForAttachment]="
                                $.useVersionNameForAttachment
                            "
                            [version]="version"
                            (cancelUpload)="cancelUpload(version)"
                            (changeCheck)="changeCheck($event)"
                            (removeVersion)="removeVersion(version)"
                        >
                        </interacta-attachment-version>

                        <interacta-separator
                            *ngIf="val.canAddVersion"
                            [thickness]="'soft'"
                        >
                        </interacta-separator>
                    </ng-container>
                </div>
            </div>

            <interacta-dialog-buttons *ngIf="val.canAddVersion">
                <button
                    interacta-dialog-button
                    type="button"
                    [appearance]="'ghost'"
                    [bgColor]="'surface-A'"
                    [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
                    (click)="attachmentsVersionsService.close()"
                ></button>
                <button
                    interacta-dialog-button
                    type="button"
                    [appearance]="'fill'"
                    [bgColor]="'primary'"
                    [disabled]="isLoadingAttachment"
                    [label]="
                        (newVersionAttachments$.value.length
                            ? val.confirmLabel
                            : 'SHARED.BUTTON.CLOSE'
                        ) | translate
                    "
                    (click)="save(val.attachment)"
                ></button>
            </interacta-dialog-buttons>
        </interacta-dialog>
    </ng-container>
</ng-container>
