export function getColorFromCss(itemColor: string | undefined): string {
    if (itemColor) {
        const r = document.querySelector(':root');
        const computed = r ? getComputedStyle(r) : null;

        const color = computed?.getPropertyValue(itemColor);
        if (color) return color;
    }

    return '';
}
