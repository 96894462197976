import { PaginatedList } from '@interacta-shared/util';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { createAction, props } from '@ngrx/store';
import {
    CallToAction,
    CallToActionWidget,
    DigitalWorkplaceDefinition,
    DigitalWorkplaceWidget,
    MySpaceCounters,
    MySpaceCountersDetail,
    PostListWidget,
} from '../../digital-workplace/models/digital-workplace.model';

export type DigitalWorkplaceError = any;

export const fetchWidgetPostsSuccess = createAction(
    '[Digital Workplace API] Fetch Widget Posts Success',
    props<{
        posts: PaginatedList<GenericPost>;
        widgetId: DigitalWorkplaceWidget['id'];
        homeId?: DigitalWorkplaceDefinition['id'];
        updateIfDiff: boolean;
    }>(),
);

export const fetchWidgetPostsError = createAction(
    '[Digital Workplace API] Fetch Widget Posts Error',
    props<{
        error: DigitalWorkplaceError;
        widgetId: DigitalWorkplaceWidget['id'];
    }>(),
);

export const actualizeWidgetFiltersSuccess = createAction(
    '[Digital Workplace API] Actualize Widget Filters Success',
    props<{
        widget: PostListWidget;
        updateIfDiff: boolean;
    }>(),
);

export const actualizeWidgetFiltersError = createAction(
    '[Digital Workplace API] Actualize Widget Filters Error',
    props<{
        error: DigitalWorkplaceError;
        widgetId: PostListWidget['id'];
    }>(),
);

export const fetchWidgetPostsPageSuccess = createAction(
    '[Digital Workplace API] Fetch Widget Posts Page Success',
    props<{
        posts: PaginatedList<GenericPost>;
        widgetId: DigitalWorkplaceWidget['id'];
    }>(),
);

export const fetchWidgetPostsPageError = createAction(
    '[Digital Workplace API] Fetch Widget Posts Page Error',
    props<{
        error: DigitalWorkplaceError;
        widgetId: DigitalWorkplaceWidget['id'];
    }>(),
);

export const fetchCTAPostSuccess = createAction(
    '[Digital Workplace API] Fetch CTA Post Success',
    props<{
        widgetId: DigitalWorkplaceWidget['id'];
        CTAUuid: CallToAction['uuid'];
        list: PaginatedList<GenericPost>;
    }>(),
);

export const fetchCTAPostError = createAction(
    '[Digital Workplace API] Fetch CTA Post Error',
    props<{
        error: DigitalWorkplaceError;
        widgetId: DigitalWorkplaceWidget['id'];
        CTAUuid: CallToAction['uuid'];
    }>(),
);

export const actualizeCtaWidgetFiltersSuccess = createAction(
    '[Digital Workplace API] Actualize Cta Widget Filters Success',
    props<{
        widget: CallToActionWidget;
    }>(),
);

export const actualizeCtaWidgetFiltersError = createAction(
    '[Digital Workplace API] Actualize Cta Widget Filters Error',
    props<{
        error: DigitalWorkplaceError;
        widgetId: CallToActionWidget['id'];
    }>(),
);

export const fetchMySpaceCountersSuccess = createAction(
    '[Digital Workplace API] Fetch My Space Counters Success',
    props<{
        counters: MySpaceCounters;
    }>(),
);

export const fetchMySpaceCountersError = createAction(
    '[Digital Workplace API] Fetch My Space Counters Error',
    props<{ error: DigitalWorkplaceError }>(),
);

export const fetchMySpaceCountersDetailSuccess = createAction(
    '[Digital Workplace API] Fetch My Space Counters Detail Success',
    props<{
        countersDetail: MySpaceCountersDetail;
    }>(),
);

export const fetchMySpaceCountersDetailError = createAction(
    '[Digital Workplace API] Fetch My Space Counters Detail Error',
    props<{ error: DigitalWorkplaceError }>(),
);
