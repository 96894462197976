import { isDefined } from '@interacta-shared/util';
import {
    DigitalWorkplaceDefinition,
    WidgetType,
} from '@modules/digital-workplace/models/digital-workplace.model';
import { isUsefulLinkVisibleInWeb } from '@modules/useful-link/models/useful-link.utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    HomePagesFeatureState,
    homePagesFeatureKey,
} from './home-pages.reducer';

const selectCommunitiesFeature = createFeatureSelector<{
    [homePagesFeatureKey]: HomePagesFeatureState;
}>('communities');

const selectHomePagesFeature = createSelector(
    selectCommunitiesFeature,
    (state) => state[homePagesFeatureKey],
);

export const selectStale = createSelector(
    selectHomePagesFeature,
    (state) => state.stale,
);

export const selectHomeDefinitions = createSelector(
    selectHomePagesFeature,
    (state) =>
        state.homeDefinitionsOrder
            ?.map((id) => state.homeDefinitions?.[id])
            .filter(isDefined),
);

export const selectDefaultHomeDefinition = createSelector(
    selectStale,
    selectHomeDefinitions,
    (stale, homeDefinitions) =>
        homeDefinitions && !stale
            ? homeDefinitions.length
                ? homeDefinitions[0]
                : null
            : undefined,
);

export const selecHomeWithUsefulLinks = createSelector(
    selectHomeDefinitions,
    (selectHomeDefinitions) => {
        if (selectHomeDefinitions) {
            return selectHomeDefinitions
                .filter((dw) =>
                    dw.widgets.some(
                        (w) =>
                            w.tag === WidgetType.USEFUL_LINK &&
                            w.linksGroups.some((g) =>
                                g.links.some(isUsefulLinkVisibleInWeb),
                            ),
                    ),
                )
                .map(
                    (dw) =>
                        <DigitalWorkplaceDefinition>{
                            ...dw,
                            widgets: dw.widgets.filter(
                                (w) =>
                                    w.tag === WidgetType.USEFUL_LINK &&
                                    w.linksGroups.length,
                            ),
                        },
                );
        }
    },
);
