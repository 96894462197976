import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Layout, Palette, Size } from '@interacta-shared/ui';
import {
    AttachmentCategoryType,
    AttachmentInList,
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    attachmentsToAttachmentList,
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';

@Component({
    selector: 'interacta-attachments-list',
    templateUrl: './attachments-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsListComponent implements OnChanges {
    @Input({ required: true }) attachments!: IAttachment[];
    @Input() pagination = 4;
    @Input() initialLength = 4;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-B';
    @Input() layout: Layout = 'extended';
    @Input() mediaSize: 'same' | 'different' = 'same';
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Output() openAttachment = new EventEmitter<AttachmentInList>();

    mediaList!: IListAttachments;
    documentsList!: IListAttachments;

    AttachmentCategoryType = AttachmentCategoryType;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachments) {
            const medias = this.attachments.filter(isMediaAttachment);
            const documents = this.attachments.filter(isFileAttachment);

            this.mediaList = attachmentsToAttachmentList(
                medias,
                AttachmentCategoryType.MULTIMEDIA,
            );
            this.documentsList = attachmentsToAttachmentList(
                documents,
                AttachmentCategoryType.DOCUMENT,
            );
        }
    }
}
