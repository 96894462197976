import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Palette, openHeightAnimation } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { postEditRoutes } from '@modules/app-routing/routes';
import { AttachmentsVersionsService } from '@modules/attachments/services/attachments-versions.service';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import { BoundedTipService } from '@modules/core/services/bounded-tip.service';
import { GoogleAPIService } from '@modules/core/services/google-api.service';
import {
    SelectionRecord,
    SelectionService,
} from '@modules/core/services/selection.service';
import { AttachmentsSelection } from '@modules/dashboard/models/attachments-selection.model';
import {
    canDeleteAttachment,
    canEditAttachment,
    canEditHashtagsAttachment,
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import { FileUploader } from 'ng2-file-upload';
import {
    BehaviorSubject,
    Observable,
    Subject,
    concat,
    concatMap,
    distinctUntilKeyChanged,
    filter,
    map,
    merge,
    of,
    takeUntil,
    tap,
    withLatestFrom,
    zip,
} from 'rxjs';
import {
    AttachmentVisualizationType,
    IAttachment,
} from '../../../post/models/attachment/attachment.model';
import { AttachmentsHashtagsEdit } from '../attachment-edit-dialog/attachment-edit-dialog.component';

@Component({
    selector: 'interacta-attachment-input',
    templateUrl: './attachment-input.component.html',
    providers: [AttachmentInputService, SelectionService],
    animations: [openHeightAnimation('selection-bar', '*')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) control!: FormControl<IAttachment[] | null>;
    @Input() inputId = 'file-picker';
    @Input() availableHashtags: IHashTag[] = [];
    @Input() acceptedMimeType?: string[];
    @Input() maxSize?: number;
    @Input() maxAttachments?: number;
    @Input() allowDrive = true;
    @Input() allowFileSystem = true;
    @Input() canAddVersion = false;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-B';
    @Input() attachmentVisualizationType: AttachmentVisualizationType = 'chip';

    @Output() attachmentsUploaded = new EventEmitter<boolean>();

    attachments$ = new BehaviorSubject<IAttachment[]>([]);
    thumbList$ = this.attachments$.pipe(
        map((attachments) =>
            attachments.filter(
                (a) =>
                    this.attachmentVisualizationType === 'thumb' &&
                    isMediaAttachment(a),
            ),
        ),
    );
    chipList$ = this.attachments$.pipe(
        map((attachments) =>
            attachments.filter(
                (a) =>
                    this.attachmentVisualizationType === 'chip' ||
                    isFileAttachment(a),
            ),
        ),
    );
    isEditingAttachment$ = new BehaviorSubject<{
        isOpen: boolean;
        attachments: IAttachment[] | null;
        editMode: 'rename' | 'hashtags';
    }>({
        isOpen: false,
        attachments: null,
        editMode: 'hashtags',
    });

    selection$!: Observable<AttachmentsSelection>;

    private uploadingCopiedFilesData: {
        isUploading: boolean;
        numberOfFiles: number;
    } | null = null;
    private initialized = false;
    private changeControl$ = new Subject<void>();
    private destroy$ = new Subject<void>();

    get fileUploader(): FileUploader {
        return this.attachmentInputService.uploader;
    }

    constructor(
        public googleAPIService: GoogleAPIService,
        public attachmentInputService: AttachmentInputService,
        public selectionService: SelectionService<IAttachment>,
        private attachmentsVersionsService: AttachmentsVersionsService,
        private tipService: BoundedTipService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['control']) {
            this.changeControl$.next();
            this.initialized = false;

            concat(of(this.control.value), this.control.valueChanges)
                .pipe(
                    concatMap((value) =>
                        of(value).pipe(withLatestFrom(this.attachments$)),
                    ),
                    takeUntil(this.changeControl$),
                    takeUntil(this.destroy$),
                )
                .subscribe(([value, attachments]) => {
                    if (value !== attachments)
                        this.attachments$.next(value ?? []);
                });
        }
    }

    ngOnInit(): void {
        this.resetAttachments();

        this.subscribeToStartLoadingAttachment();
        this.subscribeToItemUpdates();

        this.attachments$
            .pipe(takeUntil(this.destroy$))
            .subscribe((attachments: IAttachment[]) => {
                this.control.setValue(attachments);
                this.initialized && this.control.markAsDirty();
                this.initialized = true;
            });

        zip(this.thumbList$, this.chipList$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(([thumbList, chipList]) =>
                this.selectionService.setSelectableItems([
                    ...thumbList,
                    ...chipList,
                ]),
            );

        const loadAttachmentsEvent$ =
            this.attachmentInputService.loadAttachmentsEvent$.pipe(
                tap((_) => this.showSuccessCopiedProTip()),
            );

        merge(
            loadAttachmentsEvent$,
            this.attachmentInputService.limitReachedEvent$,
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => this.attachmentsUploaded.emit(true));

        this.selection$ = this.selectionService.getSelection$().pipe(
            map((attachments) => ({
                attachments,
                canDelete: attachments.some(canDeleteAttachment),
                canTag: attachments.every((attachment) =>
                    canEditHashtagsAttachment({
                        attachment,
                        hashtagsAvailable: this.availableHashtags?.length > 0,
                    }),
                ),
                canRename: attachments.every(canEditAttachment),
                canShare: false,
                canDownload: false,
            })),
        );
    }

    resetAttachments(): void {
        this.attachmentInputService.init({
            maxSize: this.maxSize,
            acceptedType: this.acceptedMimeType,
            limit: this.maxAttachments,
            initialAttachments: this.control.value || [],
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    trackAttachment(_idx: number, attachment: IAttachment): number | File {
        return attachment.fileItem?._file || _idx;
    }

    cancelUpload(attachment: IAttachment): void {
        this.cancelAttachments([attachment]);
    }

    openRenameDialog(attachment: IAttachment): void {
        this.isEditingAttachment$.next({
            isOpen: true,
            attachments: [attachment],
            editMode: 'rename',
        });
    }

    closeDialog(): void {
        this.isEditingAttachment$.next({
            isOpen: false,
            attachments: null,
            editMode: 'hashtags',
        });
    }

    editAttachment(data: IAttachment): void {
        const nextAttachments = this.attachments$.getValue().map((a) =>
            a.id === data.id
                ? {
                      ...a,
                      inUpdating: !a.isDraft,
                      hashtags: data.hashtags,
                      name: data.name,
                  }
                : a,
        );
        this.attachments$.next(nextAttachments);
    }

    editAttachmentVersion(data: IAttachment): void {
        const nextAttachments = this.attachments$.getValue().map((a) =>
            a.id === data.id
                ? {
                      ...a,
                      inUpdating: !a.isDraft,
                      inPending: a.inPending,
                      hashtags: data.hashtags,
                      name: data.name,
                      drive: data.drive ?? a.drive,
                      temporaryContentViewLink: data.temporaryContentViewLink,
                      temporaryContentPreviewImageLink:
                          data.temporaryContentPreviewImageLink,
                      versions: data.versions,
                      versionNumber: data.versions?.[0].versionNumber ?? 1,
                      iconPath: data.iconPath ?? a.iconPath,
                      contentMimeType: data.contentMimeType,
                  }
                : a,
        );
        this.attachments$.next(nextAttachments);
    }

    editHashtags(data: AttachmentsHashtagsEdit): void {
        const hashtagsMap = idArraytoMap(this.availableHashtags);
        const nextAttachments = this.attachments$.getValue().map((a) => {
            const hashtagIds = new Set<number>([
                ...(a.hashtags?.map((h) => h.id) || []),
                ...data.addHashtags.map((h) => h.id),
            ]);
            data.removeHashtags.forEach((h) => hashtagIds.delete(h.id));
            const hashtags = [...hashtagIds.values()].map(
                (id) => hashtagsMap[id],
            );
            return data.attachmentIds.includes(a.id)
                ? { ...a, hashtags, inUpdating: !a.isDraft }
                : a;
        });
        this.attachments$.next(nextAttachments);
    }

    restoreAttachment(attachment: IAttachment): void {
        const restored = { ...attachment, inDeleting: false };
        const updateList = this.attachments$
            .getValue()
            .map((i) => (i === attachment ? restored : i));
        this.attachmentInputService.restoreAttachment(restored);

        this.attachments$.next(updateList);
    }

    removeAttachment(attachment: IAttachment): void {
        const updateList = this.attachments$
            .getValue()
            .map((a) =>
                a.id === attachment.id ? { ...a, inDeleting: true } : a,
            );

        this.attachmentInputService.removeAttachment(attachment);

        this.attachments$.next(updateList);
    }

    retryUpload(attachment: IAttachment): void {
        if (!attachment.fileItem) {
            throw new Error(
                "Can't retry upload for undefined attachment.fileItem ",
            );
        }
        const updateList = this.attachments$.getValue().map((i) =>
            i === attachment
                ? {
                      ...i,
                      markAsHandled: false,
                      fileItem: undefined,
                  }
                : i,
        );
        this.attachments$.next(updateList);
        this.attachmentInputService.startLoadingProcedure([
            attachment.fileItem,
        ]);
    }

    deleteAttachments(attachments: IAttachment[]): void {
        const deletableAttachments = attachments.filter(canDeleteAttachment);
        for (const attachment of deletableAttachments) {
            if (attachment.uploadProgress && attachment.uploadProgress < 100) {
                this.cancelUpload(attachment);
            } else {
                this.removeAttachment(attachment);
            }
        }

        this.selectionService.reset();
    }

    openEditHashtagsDialog(attachments: IAttachment[]): void {
        this.isEditingAttachment$.next({
            isOpen: true,
            attachments,
            editMode: 'hashtags',
        });
    }

    addDriveAttachments(attachments: IAttachment[]): void {
        this.attachments$.next([
            ...this.attachments$.getValue(),
            ...attachments,
        ]);
    }

    manageVersion(attachment: IAttachment): void {
        this.attachmentsVersionsService
            .open({
                attachment,
                canAddVersion:
                    this.canAddVersion &&
                    (!attachment.drive ||
                        (attachment.drive &&
                            this.allowDrive &&
                            this.googleAPIService.driveIntegrationEnabled)),
                canEditPost: true,
                acceptedMimeType: this.acceptedMimeType,
                confirmLabel: 'BUTTON.LABEL_BUTTON_CONFIRM',
            })
            .pipe(filter(isDefined), takeUntil(this.destroy$))
            .subscribe((attachment) => this.editAttachmentVersion(attachment));
    }

    uploadCopiedFiles(files: File[]): void {
        if (this.uploadingCopiedFilesData != null) {
            this.uploadingCopiedFilesData.numberOfFiles += files.length;
        } else {
            this.uploadingCopiedFilesData = {
                isUploading: true,
                numberOfFiles: files.length,
            };
        }
        this.fileUploader.addToQueue(files);
    }

    showSuccessProTip(isMultiFile: boolean): void {
        this.tipService
            .openBoundedTip({
                title: isMultiFile
                    ? 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_TITLE_PLUR'
                    : 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_TITLE_SING',
                message: isMultiFile
                    ? 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_DESC_PLUR'
                    : 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_DESC_SING',
                closeBehavior: 'duration',
                image: 'attachments',
                payload: { boundary: postEditRoutes },
                direction: 'vertical',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    isSelectionActive(selectionRecord: SelectionRecord): boolean {
        return Object.values(selectionRecord).some(({ selected }) => selected);
    }

    private showSuccessCopiedProTip(): void {
        if (
            this.uploadingCopiedFilesData &&
            this.uploadingCopiedFilesData.isUploading
        ) {
            this.showSuccessProTip(
                this.uploadingCopiedFilesData.numberOfFiles > 1,
            );
            this.uploadingCopiedFilesData = null;
        }
    }

    private cancelAttachments(attachments: IAttachment[]): void {
        this.attachmentInputService.cancelAttachmentsUploading(attachments);
    }

    private getAttachmentInError(
        oldList: IAttachment[],
        newItem: IAttachment,
    ): IAttachment | undefined {
        return oldList.find(
            (a) =>
                a.isLoadingError === true &&
                a.name === newItem.name &&
                a.markAsHandled === false,
        );
    }

    private updateAttachmentFromList(
        newItem: IAttachment,
        actualList: IAttachment[],
    ): IAttachment[] {
        return actualList.map((i) =>
            i.isPartial && i.name === newItem.name ? newItem : i,
        );
    }

    private subscribeToStartLoadingAttachment(): void {
        this.attachmentInputService.startLoadingAttachmentEvent$
            .pipe(
                withLatestFrom(this.attachments$),
                tap((_) => this.attachmentsUploaded.emit(false)),
                takeUntil(this.destroy$),
            )
            .subscribe(([newAttachments, oldList]) => {
                const isReload =
                    newAttachments.length === 1 &&
                    !!this.getAttachmentInError(oldList, newAttachments[0]);

                let newList: IAttachment[] = [];
                if (isReload) {
                    const attachmentToReplace = this.getAttachmentInError(
                        oldList,
                        newAttachments[0],
                    );
                    newList = oldList.map((i) =>
                        i == attachmentToReplace ? newAttachments[0] : i,
                    );
                } else {
                    newList = [...oldList, ...newAttachments];
                }
                this.attachments$.next(newList);
            });
    }

    private subscribeToItemUpdates(): void {
        merge(
            this.attachmentInputService.singleAttachmentLoadedEvent$,
            this.attachmentInputService.progressItemEvent$.pipe(
                distinctUntilKeyChanged('uploadProgress'),
            ),
        )
            .pipe(
                withLatestFrom(this.attachments$),
                map(([newItem, actualList]) =>
                    this.updateAttachmentFromList(newItem, actualList),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((list) => {
                this.attachments$.next(list);
            });
    }
}
