import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { openHeightAnimation } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { postEditRoutes } from '@modules/app-routing/routes';
import { AttachmentsVersionsService } from '@modules/attachments/services/attachments-versions.service';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { idArraytoMap, mPartition } from '@modules/core/helpers/generic.utils';
import { BoundedTipService } from '@modules/core/services/bounded-tip.service';
import { GoogleAPIService } from '@modules/core/services/google-api.service';
import { SelectionService } from '@modules/core/services/selection.service';
import { AttachmentsSelection } from '@modules/dashboard/models/attachments-selection.model';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    canDeleteAttachment,
    canEditAttachment,
    canEditHashtagsAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import { FileUploader } from 'ng2-file-upload';
import {
    BehaviorSubject,
    Observable,
    Subject,
    distinctUntilKeyChanged,
    filter,
    map,
    merge,
    takeUntil,
    tap,
} from 'rxjs';
import { AttachmentsHashtagsEdit } from '../attachment-edit-dialog/attachment-edit-dialog.component';

@Component({
    selector: 'interacta-attachment-input-list',
    templateUrl: './attachment-input-list.component.html',
    providers: [AttachmentInputService, SelectionService],
    animations: [openHeightAnimation('selection-bar', '*')],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentInputListComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() attachmentsList!: IListAttachments;
    @Input() allowDrive = true;
    @Input() allowFileSystem = true;
    @Input() acceptedMimeType?: string[];
    @Input() canAddVersion = false;
    @Input() canEditAttachments = false;
    @Input() availableHashtags: IHashTag[] = [];
    @Input() maxSize?: number;
    @Input() maxAttachments?: number;

    @Output() loadMore = new EventEmitter<void>();
    @Output() attachmentsUploaded = new EventEmitter<boolean>();
    @Output() updateAttachmentsList = new EventEmitter<IAttachment[]>();

    selection$!: Observable<AttachmentsSelection>;
    isEditingAttachment$ = new BehaviorSubject<{
        isOpen: boolean;
        attachments: IAttachment[] | null;
        editMode: 'rename' | 'hashtags';
    }>({
        isOpen: false,
        attachments: null,
        editMode: 'hashtags',
    });

    private uploadingCopiedFilesData: {
        isUploading: boolean;
        numberOfFiles: number;
    } | null = null;
    private destroy$ = new Subject<void>();

    get fileUploader(): FileUploader {
        return this.attachmentInputService.uploader;
    }

    constructor(
        public googleAPIService: GoogleAPIService,
        private attachmentService: AttachmentService,
        public attachmentInputService: AttachmentInputService,
        public selectionService: SelectionService<IAttachment>,
        private attachmentsVersionsService: AttachmentsVersionsService,
        private tipService: BoundedTipService,
    ) {}

    ngOnInit(): void {
        this.resetAttachments();
        this.subscribeToStartLoadingAttachment();
        this.subscribeToItemUpdates();

        const loadAttachmentsEvent$ =
            this.attachmentInputService.loadAttachmentsEvent$.pipe(
                tap((_) => this.showSuccessCopiedProTip()),
            );

        merge(
            loadAttachmentsEvent$,
            this.attachmentInputService.limitReachedEvent$,
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => this.attachmentsUploaded.emit(true));

        this.selection$ = this.selectionService.getSelection$().pipe(
            map((attachments) => ({
                attachments,
                canDelete: attachments.some(canDeleteAttachment),
                canTag: attachments.every((attachment) =>
                    canEditHashtagsAttachment({
                        attachment,
                        hashtagsAvailable: this.availableHashtags?.length > 0,
                    }),
                ),
                canRename: attachments.every(canEditAttachment),
                canShare: false,
                canDownload: false,
            })),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachmentsList) {
            this.selectionService.setSelectableItems(
                this.attachmentsList.attachments,
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    addDriveAttachments(attachments: IAttachment[]): void {
        this.updateAttachmentsList.emit([
            ...this.attachmentsList.attachments,
            ...attachments,
        ]);
        if (this.availableHashtags.length) {
            this.isEditingAttachment$.next({
                isOpen: true,
                attachments,
                editMode: 'hashtags',
            });
        }
    }

    editAttachment(attachment: IAttachment): void {
        const attachments = this.attachmentsList.attachments.map((a) =>
            a.id === attachment.id
                ? {
                      ...a,
                      inUpdating: !a.isDraft,
                      hashtags: attachment.hashtags,
                      name: attachment.name,
                  }
                : a,
        );
        this.updateAttachmentsList.emit(attachments);
    }

    editAttachmentVersion(attachment: IAttachment): void {
        const attachments = this.attachmentsList.attachments.map((a) =>
            a.id === attachment.id
                ? {
                      ...a,
                      inUpdating: !a.isDraft,
                      inPending: a.inPending,
                      hashtags: attachment.hashtags,
                      name: attachment.name,
                      drive: attachment.drive ?? a.drive,
                      temporaryContentViewLink:
                          attachment.temporaryContentViewLink,
                      temporaryContentPreviewImageLink:
                          attachment.temporaryContentPreviewImageLink,
                      versions: attachment.versions,
                      versionNumber:
                          attachment.versions?.[0].versionNumber ?? 1,
                      iconPath: attachment.iconPath ?? a.iconPath,
                      contentMimeType: attachment.contentMimeType,
                  }
                : a,
        );
        this.updateAttachmentsList.emit(attachments);
    }

    removeAttachments(
        attachments: IAttachment[],
        clearSelection = false,
    ): void {
        const deletableAttachments = attachments.filter(canDeleteAttachment);

        const [uploadInProgress, readyToRemove] = mPartition(
            deletableAttachments,
            (attachment: IAttachment) =>
                !!(
                    attachment.uploadProgress && attachment.uploadProgress < 100
                ),
        );

        this.cancelUploads(uploadInProgress);

        if (readyToRemove.length) {
            const nextAttachments = this.attachmentsList.attachments.map((a) =>
                readyToRemove.some((d) => d.id === a.id)
                    ? { ...a, inDeleting: true }
                    : a,
            );

            this.attachmentInputService.removeAttachments(readyToRemove);
            this.updateAttachmentsList.emit(nextAttachments);
        }

        if (clearSelection) {
            this.selectionService.reset();
        }
    }

    restoreAttachment(index: number): void {
        const attachments = this.attachmentsList.attachments.map((i, idx) =>
            idx == index ? { ...i, inDeleting: false } : i,
        );
        this.attachmentInputService.restoreAttachment(attachments[index]);
        this.updateAttachmentsList.emit(attachments);
    }

    editHashtags(attachmentsHashtags: AttachmentsHashtagsEdit): void {
        const hashtagsMap = idArraytoMap(this.availableHashtags);
        const attachments = this.attachmentsList.attachments.map((a) => {
            const hashtagIds = new Set<number>([
                ...(a.hashtags?.map((h) => h.id) || []),
                ...attachmentsHashtags.addHashtags.map((h) => h.id),
            ]);
            attachmentsHashtags.removeHashtags.forEach((h) =>
                hashtagIds.delete(h.id),
            );
            const hashtags = [...hashtagIds.values()].map(
                (id) => hashtagsMap[id],
            );
            return attachmentsHashtags.attachmentIds.includes(a.id)
                ? { ...a, hashtags, inUpdating: !a.isDraft }
                : a;
        });

        this.updateAttachmentsList.emit(attachments);
    }

    retryUpload(index: number, attachment: IAttachment): void {
        if (!attachment.fileItem) {
            throw new Error(
                "Can't retry upload for undefined attachment.fileItem ",
            );
        }
        const attachments = this.attachmentsList.attachments.map((a, idx) =>
            index == idx
                ? {
                      ...a,
                      markAsHandled: false,
                      fileItem: undefined,
                  }
                : a,
        );
        this.updateAttachmentsList.emit(attachments);

        this.attachmentInputService.startLoadingProcedure([
            attachment.fileItem,
        ]);
    }

    manageVersion(attachment: IAttachment): void {
        this.attachmentsVersionsService
            .open({
                attachment,
                canAddVersion:
                    this.canAddVersion &&
                    (!attachment.drive ||
                        (attachment.drive &&
                            this.allowDrive &&
                            this.googleAPIService.driveIntegrationEnabled)),
                canEditPost: true,
                acceptedMimeType: this.acceptedMimeType,
                confirmLabel: 'BUTTON.LABEL_BUTTON_CONFIRM',
            })
            .pipe(filter(isDefined), takeUntil(this.destroy$))
            .subscribe((attachment) => this.editAttachmentVersion(attachment));
    }

    trackAttachment(_idx: number, attachment: IAttachment): number | File {
        return attachment.fileItem?._file || _idx;
    }

    openEditHashtagsDialog(attachments: IAttachment[]): void {
        this.isEditingAttachment$.next({
            isOpen: true,
            attachments,
            editMode: 'hashtags',
        });
    }

    openRenameDialog(attachment: IAttachment): void {
        this.isEditingAttachment$.next({
            isOpen: true,
            attachments: [attachment],
            editMode: 'rename',
        });
    }

    closeDialog(): void {
        this.isEditingAttachment$.next({
            isOpen: false,
            attachments: null,
            editMode: 'hashtags',
        });
    }

    download(attachment: IAttachment): void {
        this.attachmentService.downloadAttachment(attachment);
    }

    downloadAll(attachments: IAttachment[]): void {
        attachments.forEach((attachment) =>
            this.download(attachment as IAttachment),
        );
    }

    uploadCopiedFiles(files: File[]): void {
        if (this.uploadingCopiedFilesData != null) {
            this.uploadingCopiedFilesData.numberOfFiles += files.length;
        } else {
            this.uploadingCopiedFilesData = {
                isUploading: true,
                numberOfFiles: files.length,
            };
        }
        this.fileUploader.addToQueue(files);
    }

    showSuccessProTip(isMultiFile: boolean): void {
        this.tipService
            .openBoundedTip({
                title: isMultiFile
                    ? 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_TITLE_PLUR'
                    : 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_TITLE_SING',
                message: isMultiFile
                    ? 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_DESC_PLUR'
                    : 'DASHBOARD.ATTACHMENTS.PASTED_ATTACHMENTS_DESC_SING',
                closeBehavior: 'duration',
                image: 'attachments',
                payload: { boundary: postEditRoutes },
                direction: 'vertical',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    cancelUploads(attachments: IAttachment[]): void {
        this.attachmentInputService.cancelAttachmentsUploading(attachments);
    }

    private resetAttachments(): void {
        this.attachmentInputService.init({
            maxSize: this.maxSize,
            acceptedType: this.acceptedMimeType,
            limit: this.maxAttachments,
            initialAttachments: this.attachmentsList.attachments || [],
        });
    }

    private showSuccessCopiedProTip(): void {
        if (
            this.uploadingCopiedFilesData &&
            this.uploadingCopiedFilesData.isUploading
        ) {
            this.showSuccessProTip(
                this.uploadingCopiedFilesData.numberOfFiles > 1,
            );
            this.uploadingCopiedFilesData = null;
        }
    }

    private getAttachmentInError(
        oldList: IAttachment[],
        newItem: IAttachment,
    ): IAttachment | undefined {
        return oldList.find(
            (a) =>
                a.isLoadingError === true &&
                a.name === newItem.name &&
                a.markAsHandled === false,
        );
    }

    private updateAttachmentFromList(
        newItem: IAttachment,
        actualList: IAttachment[],
    ): IAttachment[] {
        return actualList.map((i) =>
            i.isPartial && i.name === newItem.name ? newItem : i,
        );
    }

    private subscribeToItemUpdates(): void {
        merge(
            this.attachmentInputService.singleAttachmentLoadedEvent$,
            this.attachmentInputService.progressItemEvent$.pipe(
                distinctUntilKeyChanged('uploadProgress'),
            ),
        )
            .pipe(
                map((newItem) =>
                    this.updateAttachmentFromList(
                        newItem,
                        this.attachmentsList.attachments,
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((list) => {
                this.updateAttachmentsList.emit(list);
            });
    }

    private subscribeToStartLoadingAttachment(): void {
        this.attachmentInputService.startLoadingAttachmentEvent$
            .pipe(
                takeUntil(this.destroy$),
                tap((_) => this.attachmentsUploaded.emit(false)),
            )
            .subscribe((newAttachments) => {
                const isReload =
                    newAttachments.length === 1 &&
                    !!this.getAttachmentInError(
                        this.attachmentsList.attachments,
                        newAttachments[0],
                    );

                let newList: IAttachment[] = [];
                if (isReload) {
                    const attachmentToReplace = this.getAttachmentInError(
                        this.attachmentsList.attachments,
                        newAttachments[0],
                    );
                    newList = this.attachmentsList.attachments.map((i) =>
                        i == attachmentToReplace ? newAttachments[0] : i,
                    );
                } else {
                    newList = [
                        ...this.attachmentsList.attachments,
                        ...newAttachments,
                    ];
                }
                this.updateAttachmentsList.emit(newList);
                if (this.availableHashtags.length) {
                    this.openEditHashtagsDialog(newAttachments);
                }
            });
    }
}
