import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Palette } from '@interacta-shared/ui';
import { getNextPageToken } from '@interacta-shared/util';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachments-chip-list[attachmentsList]',
    templateUrl: './attachments-chip-list.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsChipListComponent implements OnChanges {
    @Input() attachmentsList!: IListAttachments;
    @Input() initialLength = 4;
    @Input() pagination = 4;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() expandable = false;
    @Input() canShowVersion = true;
    @Input() orientation: 'column' | 'row' = 'column';
    @Input() canOpenDocument = true;

    @Output() openDocument = new EventEmitter<{ index: number }>();
    @Output() showMore = new EventEmitter<{
        pageSize: number;
        nextPageToken?: string;
    } | null>();
    @Output() hashtagIconClick = new EventEmitter<void>();
    @Output() versionIconClick = new EventEmitter<IAttachment>();

    showingCount = 4;
    showMoreCount = 0;
    canShowMore = false;

    private hiddenCount = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialLength) {
            this.showingCount = this.initialLength;
        }

        if (changes.attachmentsList) {
            this.showingCount =
                this.orientation === 'column'
                    ? this.showingCount
                    : this.attachmentsList.attachments.length;
            this.canShowMore =
                this.showingCount < this.attachmentsList.totalCount;
        }
    }

    requestShowMore(): void {
        if (this.expandable) {
            this.showingCount += this.pagination;
            this.canShowMore =
                this.showingCount < this.attachmentsList.totalCount;

            if (
                this.showingCount > this.attachmentsList.attachments.length &&
                this.attachmentsList.pageTokenInfo.tag === 'regularLoading'
            ) {
                const remainingForNextPagination =
                    this.showingCount - this.attachmentsList.attachments.length;

                this.showMore.emit({
                    pageSize: remainingForNextPagination,
                    nextPageToken:
                        getNextPageToken(this.attachmentsList.pageTokenInfo) ??
                        undefined,
                });
            }
        } else {
            this.showMore.emit(null);
        }
    }

    onHiddenElements(hiddenCount: number): void {
        this.hiddenCount = hiddenCount;
        this.updateShowCount();
    }

    private updateShowCount(): void {
        const shownCount =
            this.attachmentsList.attachments.length - this.hiddenCount;
        this.showMoreCount = this.attachmentsList.totalCount - shownCount;
    }
}
