import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TipService } from '@interacta-shared/feature-tip';
import { filterMap, isDefined } from '@interacta-shared/util';
import { forbiddenAccess } from '@modules/app-routing/routes';
import {
    AttachmentsVersionsDialogState,
    AttachmentsVersionsService,
} from '@modules/attachments/services/attachments-versions.service';
import { PostDetailSection } from '@modules/core';
import { filterEmptyValuesFromObject } from '@modules/core/helpers/generic.utils';
import { GoogleAPIService } from '@modules/core/services/google-api.service';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { AttachmentInputService } from '@modules/shared-v2/services/attachment-input.service';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    concat,
    merge,
    of,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-attachments-versions-dialog',
    templateUrl: './attachments-versions-dialog.component.html',
    providers: [AttachmentInputService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsVersionsDialogComponent implements OnInit, OnDestroy {
    newVersionAttachments$ = new BehaviorSubject<IAttachment[]>([]);
    attachments$!: Observable<Array<IAttachment>>;
    attachmentSavedVersions: IAttachment[] = [];

    chipAttachment$!: Observable<IAttachment | null>;

    dialogState$!: Observable<AttachmentsVersionsDialogState>;

    useVersionNameForAttachment$ = new BehaviorSubject<boolean>(false);
    newVersionNameControl = new FormControl<string>('');
    isLoadingAttachment = false;

    private destroy$ = new Subject<void>();

    constructor(
        public attachmentsVersionsService: AttachmentsVersionsService,
        public attachmentInputService: AttachmentInputService,
        public googleAPIService: GoogleAPIService,
        private attachmentService: AttachmentService,
        private tipService: TipService,
        private router: Router,
    ) {
        this.dialogState$ = this.attachmentsVersionsService
            .getState()
            .pipe(shareReplay(1), takeUntil(this.destroy$));
    }

    ngOnInit(): void {
        this.isLoadingAttachment = false;
        this.dialogState$
            .pipe(filter((state) => state.isOpen && state.content != null))
            .subscribe((state) =>
                this.attachmentInputService.init({
                    acceptedType: state.content.acceptedMimeType ?? undefined,
                    limit: 1,
                }),
            );

        const dialogStateAttachment$ = this.dialogState$.pipe(
            takeUntil(this.destroy$),
            filterMap((state) =>
                state.isOpen &&
                state.content?.attachment?.id != null &&
                typeof state.content.attachment.id === 'number'
                    ? state.content.attachment
                    : undefined,
            ),
        );

        dialogStateAttachment$.subscribe((attachment) => {
            const initAttachment = (attachment.versions || []).filter(
                (v) => v.inPending,
            );
            this.attachmentInputService.setInitialAttachments(initAttachment);
            this.changeCheck(attachment.drive != null);
            this.newVersionAttachments$.next(initAttachment);
        });

        const attachmentVersions$ = dialogStateAttachment$.pipe(
            switchMap((attachment) =>
                concat(
                    of([]),
                    this.attachmentService.getAttachmentVersions(
                        attachment.id as number,
                    ),
                ).pipe(tap((att) => (this.attachmentSavedVersions = att))),
            ),
            takeUntil(this.destroy$),
        );

        this.attachments$ = combineLatest([
            this.newVersionAttachments$,
            attachmentVersions$,
        ]).pipe(
            tap(([newVersion, _]) => {
                if (newVersion && newVersion.length) {
                    this.newVersionNameControl.setValue(
                        newVersion[0].versionName ?? newVersion[0].name,
                        {
                            emitEvent: false,
                        },
                    );
                }
            }),
            map(([newVersion, versions]) => [...newVersion, ...versions]),
            takeUntil(this.destroy$),
        );

        this.chipAttachment$ = combineLatest([
            this.newVersionAttachments$.pipe(
                map((attachments) =>
                    attachments.length ? attachments[0] : null,
                ),
            ),
            dialogStateAttachment$,
            this.useVersionNameForAttachment$,
        ]).pipe(
            map(([newVersion, stateAttachment, useVersionName]) => {
                const att: IAttachment = {
                    ...stateAttachment,
                    ...(newVersion ?? {}),
                    name:
                        newVersion && useVersionName
                            ? newVersion.name
                            : stateAttachment.name,
                    versionNumber: 1,
                    isMediaAudio: false,
                    isMediaVideo: false,
                    isMediaImage: false,
                    isMediaGif: false,
                };

                return !att.drive ||
                    (att.drive && this.googleAPIService.driveIntegrationEnabled)
                    ? att
                    : null;
            }),
        );

        this.newVersionNameControl.valueChanges
            .pipe(
                filter(isDefined),
                distinctUntilChanged(),
                withLatestFrom(this.newVersionAttachments$),
                takeUntil(this.destroy$),
            )
            .subscribe(([name, newVersions]) => {
                const newArray = newVersions.map((i, idx) =>
                    idx === 0
                        ? {
                              ...i,
                              name,
                          }
                        : i,
                );

                this.newVersionAttachments$.next(newArray);
            });

        this.subscribeToStartLoadingAttachment();
        this.subscribeToItemUpdates();

        this.attachmentInputService.loadAttachmentsEvent$
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => (this.isLoadingAttachment = false));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    trackAttachment(
        _idx: number,
        attachment: Partial<IAttachment>,
    ): number | File {
        return attachment.fileItem?._file || _idx;
    }

    goToPost(postId: number): void {
        this.attachmentsVersionsService.goToPost(postId);
    }

    goToAttachments(attachmentId: number): void {
        this.attachmentService
            .getAttachmentDetail(attachmentId)
            .pipe(
                catchError((response) => {
                    if (response.status === 403)
                        this.router.navigate([`/${forbiddenAccess}`]);
                    return of(null);
                }),
            )
            .subscribe(
                (attachment: IAttachment | null) =>
                    attachment?.post &&
                    this.router.navigate([
                        '/post',
                        attachment.post.id,
                        PostDetailSection.ATTACHMENTS.valueOf(),
                    ]),
            );
    }

    removeVersion(version: IAttachment): void {
        this.attachmentInputService.removeAttachment(version);
        this.newVersionAttachments$.next([]);
        this.isLoadingAttachment = false;
    }

    cancelUpload(version: IAttachment): void {
        this.cancelAttachments([version]);
        this.tipService.info('UPLOAD.UPLOAD_CANCELED');
        this.removeVersion(version);
    }

    save(attachment: IAttachment): void {
        if (this.newVersionAttachments$.getValue().length > 0) {
            const loadedAttachment = this.newVersionAttachments$.getValue()[0];
            const attachmentName = this.useVersionNameForAttachment$.value
                ? this.newVersionNameControl.value ?? ''
                : attachment.name;

            const newAttachment: IAttachment = {
                ...attachment,
                ...filterEmptyValuesFromObject(loadedAttachment),
                id: attachment.id,
                versionNumber: this.attachmentSavedVersions.length + 1,
                name: attachmentName,
                versionName:
                    this.newVersionNameControl.value ?? loadedAttachment.name,
            };
            newAttachment.versions = [
                newAttachment as IAttachment,
                ...this.attachmentSavedVersions,
            ];
            this.attachmentsVersionsService.close(newAttachment);
        } else {
            this.attachmentsVersionsService.close();
        }
    }

    addDriveAttachments(attachments: IAttachment[]): void {
        this.newVersionAttachments$.next(attachments);
    }

    changeCheck(check: boolean): void {
        this.useVersionNameForAttachment$.next(check);
    }

    private cancelAttachments(attachments: IAttachment[]): void {
        this.attachmentInputService.cancelAttachmentsUploading(attachments);
    }

    private updateAttachmentFromList(
        newItem: IAttachment,
        actualList: IAttachment[],
    ): IAttachment[] {
        return actualList.map((i) =>
            i.isPartial && i.name === newItem.name ? newItem : i,
        );
    }

    private getAttachmentInError(
        oldList: IAttachment[],
        newItem: IAttachment,
    ): IAttachment | undefined {
        return oldList.find(
            (a) =>
                a.isLoadingError === true &&
                a.name === newItem.name &&
                a.markAsHandled === false,
        );
    }

    private subscribeToStartLoadingAttachment(): void {
        this.attachmentInputService.startLoadingAttachmentEvent$
            .pipe(
                tap((_) => (this.isLoadingAttachment = true)),
                withLatestFrom(this.newVersionAttachments$),
                takeUntil(this.destroy$),
            )
            .subscribe(([newAttachments, oldList]) => {
                const isReload =
                    newAttachments.length === 1 &&
                    !!this.getAttachmentInError(oldList, newAttachments[0]);

                let newList: IAttachment[] = [];
                if (isReload) {
                    const attachmentToReplace = this.getAttachmentInError(
                        oldList,
                        newAttachments[0],
                    );
                    newList = oldList.map((i) =>
                        i == attachmentToReplace ? newAttachments[0] : i,
                    );
                } else {
                    newList = [...oldList, ...newAttachments];
                }
                this.newVersionAttachments$.next(newList);
            });
    }

    private subscribeToItemUpdates(): void {
        merge(
            this.attachmentInputService.singleAttachmentLoadedEvent$,
            this.attachmentInputService.progressItemEvent$.pipe(
                distinctUntilKeyChanged('uploadProgress'),
            ),
        )
            .pipe(
                withLatestFrom(this.newVersionAttachments$),
                map(([newItem, actualList]) =>
                    this.updateAttachmentFromList(newItem, actualList),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((list) => {
                this.newVersionAttachments$.next(list);
            });
    }
}
