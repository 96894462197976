<div
    *ngIf="{
        selectAll: withSelectAll && !itemsSelected,
        selectedCount: itemsSelected.length
    } as $"
    class="flex flex-wrap items-start gap-8"
>
    <button
        interacta-button
        size="regular"
        type="button"
        [bgColor]="bgColor"
        [icon]="$.selectAll ? 'workspace' : 'check-circle-undeterminate'"
        [label]="
            $.selectAll
                ? ('DASHBOARD.ATTACHMENTS.SELECT' | translate)
                : ($.selectedCount
                  | i18nPlural
                      : {
                            '=1': 'DASHBOARD.ATTACHMENTS.COUNTER_SING',
                            other: 'DASHBOARD.ATTACHMENTS.COUNTER_PLUR'
                        }
                  | translate: { counter: $.selectedCount })
        "
        (click)="$.selectAll ? selectAll.emit() : deselectAll.emit()"
    ></button>
    <button
        *ngIf="$.selectedCount > 0 && canShare && !areAttachmentsRefPresent"
        icon="share"
        interacta-button
        size="regular"
        type="button"
        [bgColor]="bgColor"
        [label]="'DASHBOARD.ATTACHMENTS.SHARE' | translate"
        (click)="share.emit()"
    ></button>

    <ng-container *ngIf="$.selectedCount > 1 && canTag">
        <button
            icon="tag"
            interacta-button
            size="regular"
            type="button"
            [bgColor]="bgColor"
            [label]="'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate"
            (click)="editHashtags.emit()"
        ></button
    ></ng-container>

    <button
        *ngIf="$.selectedCount > 0 && canDelete"
        icon="trash-bin"
        interacta-button
        size="regular"
        type="button"
        [bgColor]="bgColor"
        [label]="'DASHBOARD.ATTACHMENTS.DELETE' | translate"
        (click)="delete.emit($.selectedCount)"
    ></button>

    <button
        *ngIf="$.selectedCount > 0 && canDownload"
        icon="download"
        interacta-button
        size="regular"
        type="button"
        [bgColor]="bgColor"
        [label]="'DASHBOARD.ATTACHMENTS.DOWNLOAD_SELECTED' | translate"
        (click)="downloadSelected.emit()"
    ></button>
    <ng-container
        *ngIf="
            customButtons
                | filterAttachmentCustomButtons
                    : itemsSelected as customButtonsList
        "
    >
        <ng-container *ngIf="customButtonsList?.length">
            <interacta-custom-button
                *ngFor="let customButton of customButtonsList"
                size="regular"
                [communityId]="communityId"
                [customButton]="customButton"
                [ids]="itemsSelected | apply: attachmentIds"
                [mode]="'list'"
            ></interacta-custom-button
        ></ng-container>
    </ng-container>
</div>
