import { Injectable, OnDestroy } from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { Index } from '@interacta-shared/util';
import { BehaviorSubject, Observable, Subject, fromEvent, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

export interface SelectableItemState {
    selected: boolean;
    preview: boolean;
}

export type SelectionRecord = Record<Index, SelectableItemState>;

type Range = { start: number; end: number };

@Injectable({
    providedIn: 'root',
})
export class SelectionService<T extends { id: Index }> implements OnDestroy {
    selectableItems: T[] = [];

    private shiftDown$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        filter((evt) => evt.key === 'Shift'),
        map((_) => true),
    );
    private shiftUp$ = fromEvent<KeyboardEvent>(window, 'keyup').pipe(
        filter((evt) => evt.key === 'Shift'),
        map((_) => false),
    );
    private shift$ = merge(this.shiftDown$, this.shiftUp$).pipe(
        distinctUntilChanged(),
    );

    private selectableListState = new BehaviorSubject<SelectionRecord>({});
    private isShiftDown = false;
    private lastSelectedIdx: number | null = null;
    private currHoverId: Index | null = null;
    private hasSelectedYet = false;

    private destroy$ = new Subject<void>();

    constructor(private tipService: TipService) {
        this.shift$.pipe(takeUntil(this.destroy$)).subscribe((isShiftDown) => {
            this.isShiftDown = isShiftDown;

            if (this.isShiftDown && this.currHoverId) {
                this.createPreview(this.currHoverId);
            }

            if (!this.isShiftDown) {
                this.resetPreviews();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    setSelectableItems(items: T[]): void {
        this.selectableItems = items;
        this.selectableListState.next(this.selectableListState.value);
    }

    toggleSelect(id: Index): boolean {
        this.selectableListState.value[id]?.selected
            ? this.deselect(id)
            : this.select(id);

        return this.isShiftDown;
    }

    select(id: Index): void {
        this.changeRange(id, (_) => ({ preview: false, selected: true }));
        this.lastSelectedIdx = this.getIdxOfItem(id);

        if (!this.hasSelectedYet) {
            this.tipService.info(
                'NOTIFICATION_MESSAGE.LABEL_ATTACHMENTS_SHIFT_SELECTION',
            );
            this.hasSelectedYet = true;
        }
    }

    selectAll(): void {
        const curr = this.selectableListState.value;
        const next = { ...curr };

        for (const item of this.selectableItems) {
            next[item.id] = {
                preview: false,
                selected: true,
            };
        }
        this.selectableListState.next(next);

        this.lastSelectedIdx = null;
    }

    deselect(id: Index): void {
        this.changeRange(id, (_) => ({ preview: false, selected: false }));
        this.lastSelectedIdx = null;
    }

    hover(id: Index): void {
        this.currHoverId = id;

        if (this.isShiftDown && this.lastSelectedIdx != null) {
            this.resetPreviews();
            this.createPreview(id);
        }
    }

    unhover(): void {
        this.currHoverId = null;
    }

    reset(): void {
        this.selectableListState.next({});
    }

    resetPreviews(): void {
        const curr = this.selectableListState.value;
        const next = { ...curr };

        for (const item of Object.values(next)) {
            item.preview = false;
        }

        this.selectableListState.next(next);
    }

    resetLastSelected(): void {
        this.lastSelectedIdx = null;
    }

    getSelection(): T[] {
        return this.selectableItems.filter(
            (item) => this.selectableListState.value[item.id]?.selected,
        );
    }

    getSelectionRecord$(): Observable<SelectionRecord> {
        return this.selectableListState.asObservable();
    }

    getSelection$(): Observable<T[]> {
        return this.getSelectionRecord$().pipe(map(() => this.getSelection()));
    }

    private createPreview(id: Index): void {
        if (this.lastSelectedIdx != null) {
            this.changeRange(id, (item) => ({ ...item, preview: true }));
        }
    }

    private getRangeTo(idx: number): Range {
        let end = idx;

        let start =
            this.isShiftDown && this.lastSelectedIdx != null
                ? Math.max(0, this.lastSelectedIdx)
                : end;

        if (start > end) {
            [start, end] = [end, start];
        }

        return { start, end };
    }

    private changeRange(
        id: Index,
        mapper: (item: SelectableItemState) => SelectableItemState,
    ): void {
        const curr = this.selectableListState.value;
        const next = { ...curr };

        const itemIdx = this.selectableItems.findIndex(
            (item) => item.id === id,
        );

        if (itemIdx === -1) {
            console.warn(
                `[Selection Service]: Item with id ${id} not found. You may have forgotten to call setSelectableItems().`,
            );
            return;
        }

        const { start, end } = this.getRangeTo(itemIdx);

        if (start < 0 || end < 0) return;

        for (let i = start; i <= end; i++) {
            const id = this.selectableItems[i].id;
            next[id] = mapper(curr[id]);
        }

        this.selectableListState.next(next);
    }

    private getIdxOfItem(id: Index): number {
        return this.selectableItems.findIndex((item) => item.id === id);
    }
}
