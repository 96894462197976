import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { getServerValue } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import {
    CustomPostDeserialize,
    CustomPostRangeNumber,
    CustomPostRangeNumberDeserialize,
    CustomPostSerialize,
    ICustomPost,
    ICustomPostCreate,
    ICustomPostEdit,
    ICustomPostForContext,
    PostOnChangeType,
} from '@modules/post/models/custom-post.model';
import { toIEditPostLinksResponse } from '@modules/post/models/post-link/post-link.deserialize';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IBasePost, IPostMetadata } from '../models/base-post.model';
import { IPostFiltersCustomField } from '../models/filter-post/filter-post.model';
import {
    ListenChangesDeserialize,
    OnChangeMessage,
} from '../models/listen-changes/listen-changes.model';
import {
    IEditPostLinkSourcePost,
    IEditPostLinksResponse,
} from '../models/post-link/post-link.model';
import { fromEditPostLink } from '../models/post-link/post-link.serialize';
import { PostService } from './post.service';

@Injectable({ providedIn: 'root' })
export class CustomPostService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;

    constructor(
        private authService: AuthService,
        private postService: PostService,
        private communitiesStateService: CommunitiesStateService,
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public getPostForCreate(
        communityId: number,
        q?: any,
    ): Observable<ICustomPost> {
        const requests = [
            this.communitiesStateService.getPostMetadata(communityId),
            this.http.get(
                `${this.baseUrl}/manage/post-data-for-create/${communityId}`,
                q ? { params: { q } } : undefined,
            ),
        ];
        return forkJoin(requests).pipe(
            map((results: any[]) => {
                const user = this.authService.currentUserData();
                if (!user) {
                    throw new Error('Current User must be defined');
                }
                return CustomPostDeserialize.customPostDetailsForCreate(
                    results[1],
                    results[0],
                    user,
                    this.configurationService,
                );
            }),
        );
    }

    getPostOnChange(
        communityId: number,
        context: ICustomPostForContext,
        basePost: IBasePost,
        changedFieldId: number,
    ): Observable<PostOnChangeType> {
        const postForChange$ = this.http.post<{
            contentData: any;
            postDataHasChanged: boolean;
            messageInfo: OnChangeMessage | null;
        }>(
            `${this.baseUrl}/manage/post-data-for-change/${communityId}`,
            { context: CustomPostSerialize.contextCustomPost(context) },
            { params: { changedFieldId } },
        );
        return forkJoin([
            this.communitiesStateService.getPostMetadata(communityId),
            postForChange$,
        ]).pipe(
            map(([metadata, dataForChange]) => {
                const user = this.authService.currentUserData();
                if (!user) {
                    throw new Error('Current User must be defined');
                }
                const post = CustomPostDeserialize.customPostDetailsOnChange(
                    dataForChange,
                    metadata,
                    user,
                    basePost.id,
                    basePost.occToken,
                    this.configurationService,
                );
                return {
                    post,
                    postDataHasChanged: dataForChange.postDataHasChanged,
                    messageInfo: ListenChangesDeserialize.formatMessage(
                        dataForChange.messageInfo,
                    ),
                };
            }),
        );
    }

    public createPost(
        communityId: number,
        post: ICustomPostCreate,
        postDefinition?: IPostMetadata,
    ): Observable<ICustomPost> {
        return this.http
            .post<any>(
                `${this.baseUrl}/manage/create-post/${communityId}`,
                CustomPostSerialize.newCustomPost(post),
            )
            .pipe(
                map(
                    (res: {
                        postId: number;
                        occToken: string;
                        postData: any;
                    }) =>
                        CustomPostDeserialize.customPostDetails(
                            this.configurationService,
                            res.postData,
                            postDefinition,
                        ),
                ),
            );
    }

    public updatePost(
        post: ICustomPostEdit,
        metadata: IPostMetadata,
        publishDraft: boolean,
    ): Observable<ICustomPost> {
        return this.http
            .put<{
                nextOccToken: number;
                postData: any;
            }>(
                `${this.baseUrl}/manage/edit-post/${post.id}/${post.occToken}`,
                CustomPostSerialize.editCustomPost(post),
            )
            .pipe(
                mergeMap((result) =>
                    forkJoin([
                        this.postService.getMentions(post.id),
                        publishDraft
                            ? this.postService.getPostCapabilities(post.id)
                            : of(post.capabilities),
                    ]).pipe(
                        map(([memberMentions, capabilities]) => ({
                            nextOccToken: result.nextOccToken,
                            postData: {
                                ...result.postData,
                                memberMentions,
                            },
                            capabilities,
                        })),
                    ),
                ),
                map((res) => {
                    const newPost = CustomPostDeserialize.customPostDetails(
                        this.configurationService,
                        res.postData,
                        metadata,
                    );
                    newPost.occToken = res.nextOccToken;
                    newPost.capabilities = res.capabilities;
                    return newPost;
                }),
            );
    }

    public copyPost(
        idCopy: number,
        post: ICustomPostEdit,
        metadata: IPostMetadata,
    ): Observable<ICustomPost> {
        return this.http
            .put<{
                postId: number;
                occToken: number;
                postData: any;
            }>(
                `${this.baseUrl}/manage/copy-post/${idCopy}/${post.occToken}`,
                CustomPostSerialize.copyCustomPost(post),
            )
            .pipe(
                map((res) => {
                    // update info post
                    const post = CustomPostDeserialize.customPostDetails(
                        this.configurationService,
                        res.postData,
                        metadata,
                    );
                    post.occToken = res.occToken;
                    return post;
                }),
            );
    }

    public editCustomData(
        postId: number,
        occToken: number,
        customFieldsData: IPostFiltersCustomField[],
        postDefinition?: IPostMetadata,
    ): Observable<ICustomPost> {
        const customData = customFieldsData.reduce(
            (acc, { definition, value }) => ({
                ...acc,
                [definition.id]: getServerValue(value, definition),
            }),
            {},
        );

        return this.http
            .put(
                `${this.baseUrl}/manage/edit-post-custom-data/${postId}/${occToken}`,
                { customData },
            )
            .pipe(
                map((res: any) =>
                    CustomPostDeserialize.customPostDetails(
                        this.configurationService,
                        res.postData,
                        postDefinition,
                    ),
                ),
            );
    }

    public addOrRemovePostFromRelations(
        postId: number,
        postPickerIds: number[] | null,
        removePostIds: number[] | null,
        addPostIds?: number[] | null,
    ): Observable<ICustomPost> {
        return this.http
            .put(`${this.baseUrl}/manage/${postId}/post-pickers`, {
                postPickerIds,
                addPostIds,
                removePostIds,
            })
            .pipe(
                map((res: any) =>
                    CustomPostDeserialize.customPostDetails(
                        this.configurationService,
                        res.postData,
                    ),
                ),
            );
    }

    public editPostPicker(
        sourcePosts: IEditPostLinkSourcePost[],
        targetPostId: number,
        postPickerFieldId: number,
    ): Observable<IEditPostLinksResponse> {
        return this.http
            .put(
                `${this.baseUrl}/manage/edit-post-picker/${postPickerFieldId}`,
                fromEditPostLink(sourcePosts, targetPostId),
            )
            .pipe(map((res: any) => toIEditPostLinksResponse(res)));
    }

    public getFieldRangeByFieldModeType(
        fieldModeType: MetadataFieldModeType,
        fieldId: number,
        communityId: number,
    ): Observable<CustomPostRangeNumber> {
        const rangePath = this.getRangePathByType(fieldModeType);
        return this.http
            .get(
                `${this.baseUrl}/data/range/${rangePath}/${fieldId}/communities/${communityId}`,
            )
            .pipe(
                map((res: any) =>
                    CustomPostRangeNumberDeserialize.getRange(res),
                ),
            );
    }

    private getRangePathByType(type: MetadataFieldModeType): string {
        let rangePath: string = '';
        switch (type) {
            case MetadataFieldModeType.POST:
                rangePath = 'post-fields';
                break;
            case MetadataFieldModeType.SCREEN:
                rangePath = `workflow-screen-fields`;
                break;
        }

        return rangePath;
    }
}
