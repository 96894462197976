import { ZonedDateTime } from '@interacta-shared/data-access-configuration';
import { DigitalWorkplaceStatusProperties } from '@modules/digital-workplace-admin/models/digital-workplace-admin.model';
import {
    MentionFilterType,
    TaskTabs,
} from '@modules/manage/models/manage.model';
import {
    ActualizeFiltersOptions,
    ActualizeFiltersService,
} from '@modules/post-filters/services/actualize-filters.service';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import { DraftType } from '@modules/post/models/filter-post/filter-post.model';
import { format } from 'date-fns';
import { catchError, from, map, mergeMap, Observable, of, toArray } from 'rxjs';
import {
    CallToActionWidget,
    CoverWidget,
    CoverWidgetSize,
    DigitalWorkplaceStatus,
    DigitalWorkplaceWidget,
    MySpaceCounters,
    MySpaceCountersDetail,
    MySpaceCountersDrafts,
    MySpaceCountersMentionedIn,
    MySpaceCountersProcesses,
    MySpaceCountersTasks,
    MySpaceWidget,
    MySpaceWidgetCard,
    MySpaceWidgetCardContent,
    MySpaceWidgetCardType,
    MySpaceWidgetInfoBox,
    MySpaceWidgetSize,
    PostListCoverWidget,
    PostListCoverWidgetCardSettingsBase,
    PostListWidget,
    PostRollWidget,
    PostRollWidgetCardSettingsBase,
    RollWidget,
    StaticCoverWidget,
    TextPostRollWidget,
    TextPostRollWidgetCardSettings,
    UsefulLinkWidget,
    VisualPostRollWidget,
    VisualPostRollWidgetCardSettings,
    WidgetCardSettingsBase,
    WidgetType,
} from './digital-workplace.model';

const FIXED_HEIGHT = 140; //altezza ottenuta da: altezza dei pallini, più la distanza tra essi e la card  e lo spazio tra i widget

export function getResizedHeroHeight(clientHeight: number): number {
    return clientHeight - FIXED_HEIGHT;
}

export function isPostRollWidget(
    widget: DigitalWorkplaceWidget,
): widget is PostRollWidget {
    return isVisualPostRollWidget(widget) || isTextPostRollWidget(widget);
}

export function isVisualPostRollWidget(
    widget: DigitalWorkplaceWidget,
): widget is VisualPostRollWidget {
    return widget.type === WidgetType.VISUAL_POST_ROLL;
}

export function isTextPostRollWidget(
    widget: DigitalWorkplaceWidget,
): widget is TextPostRollWidget {
    return widget.type === WidgetType.TEXT_POST_ROLL;
}

export function asPostRollWidget(
    widget: DigitalWorkplaceWidget,
): PostRollWidget | undefined {
    return isPostRollWidget(widget) ? widget : undefined;
}

export function isMainCoverWidget(widget: DigitalWorkplaceWidget): boolean {
    return isCoverWidget(widget) && widget.mainCover;
}

export function isCoverWidget(
    widget: DigitalWorkplaceWidget,
): widget is CoverWidget {
    return isPostListCoverWidget(widget) || isStaticCoverWidget(widget);
}

export function isPostListCoverWidget(
    widget: DigitalWorkplaceWidget,
): widget is PostListCoverWidget {
    return widget.type === WidgetType.POST_LIST_COVER;
}

export function isStaticCoverWidget(
    widget: DigitalWorkplaceWidget,
): widget is StaticCoverWidget {
    return widget.type === WidgetType.STATIC_COVER;
}

export function asCoverWidget(
    widget: DigitalWorkplaceWidget,
): CoverWidget | undefined {
    return isCoverWidget(widget) ? widget : undefined;
}

export function isPostListWidget(
    widget: DigitalWorkplaceWidget,
): widget is PostListWidget {
    return [
        WidgetType.POST_LIST_COVER,
        WidgetType.TEXT_POST_ROLL,
        WidgetType.VISUAL_POST_ROLL,
    ].includes(widget.type);
}

export function asPostListWidget(
    widget: DigitalWorkplaceWidget,
): PostListWidget | undefined {
    return isPostListWidget(widget) ? widget : undefined;
}

export function isUsefulLinkWidget(
    widget: DigitalWorkplaceWidget,
): widget is UsefulLinkWidget {
    return widget.type === WidgetType.USEFUL_LINK;
}

export function isCallToActionWidget(
    widget: DigitalWorkplaceWidget,
): widget is CallToActionWidget {
    return widget.type === WidgetType.CALL_TO_ACTION;
}

export function isMySpaceWidget(
    widget: DigitalWorkplaceWidget,
): widget is MySpaceWidget {
    return widget.type === WidgetType.MY_SPACE;
}

export const isRollWidget = (
    widget: DigitalWorkplaceWidget,
): widget is RollWidget => {
    return (
        isUsefulLinkWidget(widget) ||
        isPostRollWidget(widget) ||
        isMySpaceWidget(widget)
    );
};

export const buildDefaultCountersValues = (
    maxCounters: number,
    communityDefinition: IPostMetadata | null,
): Omit<
    WidgetCardSettingsBase,
    | 'showCommunity'
    | 'showWorkspace'
    | 'showAuthor'
    | 'showPublicationTimestamp'
> => {
    const showViewsCounter = true;
    let actualCounter = 1;

    const showLikesCounter =
        actualCounter < maxCounters &&
        (!communityDefinition || communityDefinition.likeEnabled);
    showLikesCounter && actualCounter++;

    const showCommentsCounter = actualCounter < maxCounters;
    showCommentsCounter && actualCounter++;

    const showAttachmentsCounter =
        actualCounter < maxCounters &&
        (!communityDefinition || communityDefinition.attachmentEnabled);
    showAttachmentsCounter && actualCounter++;

    const showStandardTasksCounter = actualCounter < maxCounters;
    showStandardTasksCounter && actualCounter++;

    return {
        showViewsCounter,
        showLikesCounter,
        showCommentsCounter,
        showAttachmentsCounter,
        showStandardTasksCounter,
    };
};

const buildDefaultPostRollWidgetCardSettings = (
    communityIds: number[],
    size: PostRollWidget['size'],
    communityDefinition: IPostMetadata | null,
): PostRollWidgetCardSettingsBase => {
    const defaultShowState = !!communityDefinition?.workflowDefinition;
    const maxCounters = computePostRollWidgetCountersLimit(size);
    return {
        showCommunity: communityIds.length > 1 && size != 'small',
        showWorkspace: false,
        showAuthor: false,
        showPublicationTimestamp: false,
        showTitle: true,
        showCustomId: false,
        showState: defaultShowState,
        ...buildDefaultCountersValues(maxCounters, communityDefinition),
    };
};

export const buildDefaultPostVisualWidgetCardSettings = (
    communityIds: number[],
    size: PostRollWidget['size'],
    communityDefinition: IPostMetadata | null,
): VisualPostRollWidgetCardSettings => {
    return buildDefaultPostRollWidgetCardSettings(
        communityIds,
        size,
        communityDefinition,
    );
};

export const buildDefaultPostTextWidgetCardSettings = (
    communityIds: number[],
    size: PostRollWidget['size'],
    communityDefinition: IPostMetadata | null,
): TextPostRollWidgetCardSettings => {
    return {
        ...buildDefaultPostRollWidgetCardSettings(
            communityIds,
            size,
            communityDefinition,
        ),
        showDescription: true,
        showCustomFields: false,
    };
};

export const buildDefaultPostListCoverWidgetCardSettings = (
    communityIds: number[],
    size: CoverWidgetSize,
    communityDefinition: IPostMetadata | null,
): PostListCoverWidgetCardSettingsBase => {
    const maxCounters = computePostListCoverWidgetCountersLimit(size);
    return {
        showCommunity: communityIds.length > 1,
        showWorkspace: false,
        showAuthor: false,
        showPublicationTimestamp: false,
        ...buildDefaultCountersValues(maxCounters, communityDefinition),
    };
};

export const showWidgetCounters = (
    settings: WidgetCardSettingsBase,
): boolean => {
    return (
        settings.showLikesCounter ||
        settings.showViewsCounter ||
        settings.showCommentsCounter ||
        settings.showAttachmentsCounter ||
        settings.showStandardTasksCounter
    );
};

export const computeActiveCounters = (
    settings: WidgetCardSettingsBase,
): number => {
    let counter = 0;
    settings.showViewsCounter && counter++;
    settings.showLikesCounter && counter++;
    settings.showCommentsCounter && counter++;
    settings.showAttachmentsCounter && counter++;
    settings.showStandardTasksCounter && counter++;

    return counter;
};

export const computePostRollWidgetCountersLimit = (
    size: PostRollWidget['size'],
): number => {
    return size === 'large' ? 3 : size === 'regular' ? 2 : 1;
};

export const computePostListCoverWidgetCountersLimit = (
    _size: CoverWidget['size'],
): number => {
    return 5;
};

export const computeCountersLimit = (widget: PostListWidget): number => {
    if (isPostRollWidget(widget)) {
        return computePostRollWidgetCountersLimit(widget.size);
    } else if (isPostListCoverWidget(widget)) {
        return computePostListCoverWidgetCountersLimit(widget.size);
    } else return 0;
};

export const computePostRollWidgetCustomFieldsLimit = (
    size: PostRollWidget['size'],
): number => {
    return size === 'large' ? 4 : size === 'regular' ? 3 : 2;
};

export const homeStatusPropertyByStatus = (
    status: DigitalWorkplaceStatus,
): { label: string; colorCode: string } => {
    const baseLabel = 'DIGITAL_WORKPLACE.ADMIN.HOME_HEADER.CHIP_STATUS';
    let colorCode = 'M';
    switch (status) {
        case DigitalWorkplaceStatus.ONLINE:
            colorCode = 'L';
            break;
        case DigitalWorkplaceStatus.OFFLINE:
            colorCode = 'J';
            break;
        default:
            colorCode = 'M';
    }

    return {
        label: `${baseLabel}.${status.toUpperCase()}`,
        colorCode,
    };
};

export const getPropertiesFromHomeStatusList = (
    statusList: DigitalWorkplaceStatus[],
): DigitalWorkplaceStatusProperties => {
    return {
        isDraft:
            statusList.length === 1 &&
            statusList[0] === DigitalWorkplaceStatus.DRAFT,
        isOnline:
            statusList.length === 1 &&
            statusList[0] === DigitalWorkplaceStatus.ONLINE,
        isOffline:
            statusList.length === 1 &&
            statusList[0] === DigitalWorkplaceStatus.OFFLINE,
        isOnlineDirty:
            statusList.length === 2 &&
            statusList.includes(DigitalWorkplaceStatus.ONLINE) &&
            statusList.includes(DigitalWorkplaceStatus.DIRTY),
        isOfflineDirty:
            statusList.length === 2 &&
            statusList.includes(DigitalWorkplaceStatus.OFFLINE) &&
            statusList.includes(DigitalWorkplaceStatus.DIRTY),
    };
};

export const actualizeCtaFilters = (
    widget: CallToActionWidget,
    actualizeFiltersService: ActualizeFiltersService,
    options?: ActualizeFiltersOptions,
): Observable<CallToActionWidget> => {
    return from(widget.callToActions).pipe(
        mergeMap((cta) =>
            cta.examplePostData?.filters
                ? actualizeFiltersService
                      .actualizeFilters$(
                          cta.examplePostData,
                          cta.communityId,
                          options,
                      )
                      .pipe(
                          map((val) => ({
                              ...cta,
                              examplePostData: val,
                          })),
                          catchError((error) => {
                              console.error(
                                  'error while actualizing filters',
                                  error,
                              );
                              return of(cta);
                          }),
                      )
                : of(cta),
        ),
        toArray(),
        map((callToActions) => ({
            ...widget,
            callToActions,
        })),
    );
};

export const actualizePostListFilters = (
    widget: PostListWidget,
    actualizeFiltersService: ActualizeFiltersService,
    options?: ActualizeFiltersOptions,
): Observable<PostListWidget> => {
    return actualizeFiltersService
        .actualizeFilters$(
            widget,
            widget.communityIds.length === 1
                ? widget.communityIds[0]
                : 'dashboard',
            options,
        )
        .pipe(
            catchError((error) => {
                console.error('error while actualizing filters', error);
                return of(widget);
            }),
        );
};

export const defaultMySpaceCounters = (): MySpaceCounters => {
    return {
        toManageWorkflowPosts: 10,
        expiringTasks: 3,
        expiredTasks: 1,
        createdByMeClosedTasks: 8,
        createdByMeExpiringTasks: 6,
        createdByMeExpiredTasks: 3,
        standardDraftPosts: 1,
        scheduledDraftPosts: 2,
        mentionedInPostAndComments: 15,
        commentReplies: 7,
    };
};

export const defaultMySpaceCountersDetail = (): MySpaceCountersDetail => {
    return {
        processes: {
            communityCounters: [
                {
                    community: {
                        id: -1,
                        name: 'Marketing',
                    },
                    today: 1,
                    lastWeek: 0,
                    lastMonth: 0,
                    other: 0,
                },
                {
                    community: {
                        id: -2,
                        name: 'IT Support',
                    },
                    today: 0,
                    lastWeek: 2,
                    lastMonth: 0,
                    other: 0,
                },
                {
                    community: {
                        id: -3,
                        name: 'Onboarding',
                    },
                    today: 0,
                    lastWeek: 0,
                    lastMonth: 4,
                    other: 0,
                },
                {
                    community: {
                        id: -4,
                        name: 'Reports',
                    },
                    today: 0,
                    lastWeek: 0,
                    lastMonth: 0,
                    other: 3,
                },
            ],
        },
        tasks: {
            assignedToMeExpired: 1,
            assignedToMeExpiringToday: 2,
            assignedToMeExpiringTomorrow: 0,
            assignedToMeExpiringThisWeek: 0,
            createdByMeExpiredTasks: [
                {
                    count: 1,
                    entity: {
                        id: 'u--1',
                        user: {
                            id: -1,
                            firstName: 'Mario',
                            lastName: 'Rossi',
                        },
                        group: null,
                    },
                },
            ],
            createdByMeClosedTasks: [
                {
                    count: 1,
                    entity: {
                        id: -2,
                        firstName: 'John',
                        lastName: 'Smith',
                    },
                },
                {
                    count: 2,
                    entity: {
                        id: -3,
                        firstName: 'Juanita',
                        lastName: 'Pérez',
                    },
                },
            ],
        },
        drafts: {
            staleStandardDraftPosts: 33,
            publishingScheduledDraftPosts: [],
        },
        mentionedIn: {
            mostMentioner: null,
            lastMonthMentioners: [],
            todayMentionerUser: {
                id: -1,
                firstName: 'Mario',
                lastName: 'Rossi',
            },
            todayMentionedGroup: null,
            lastMonthMostMentionedGroup: null,
            lastWeekRepliedCommentsCount: 0,
            todayRepliedCommentsCount: 2,
            onFireComment: null,
        },
    };
};

const getProcessesInfoBoxes = (
    processes: MySpaceCountersProcesses,
): MySpaceWidgetInfoBox[] => {
    const messageMap = {
        today: {
            singular: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_TODAY_ONE',
            plural: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_TODAY_MULTIPLE',
        },
        lastWeek: {
            singular: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_LAST_WEEK_ONE',
            plural: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_LAST_WEEK_MULTIPLE',
        },
        lastMonth: {
            singular: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_LAST_MONTH_ONE',
            plural: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_LAST_MONTH_MULTIPLE',
        },
        other: {
            singular: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_OTHER_ONE',
            plural: 'MANAGE.WIDGET.PROCESSES.TO_MANAGE_OTHER_MULTIPLE',
        },
    };

    processes.communityCounters.sort((a, b) => {
        if (a.today !== b.today) {
            return b.today - a.today;
        } else if (a.lastWeek !== b.lastWeek) {
            return b.lastWeek - a.lastWeek;
        } else if (a.lastMonth !== b.lastMonth) {
            return b.lastMonth - a.lastMonth;
        } else {
            return b.other - a.other;
        }
    });

    return processes.communityCounters.map((item) => {
        let message = '';
        let number = '';
        if (item.today > 0) {
            message =
                item.today === 1
                    ? messageMap.today.singular
                    : messageMap.today.plural;
            number = String(item.today);
        } else if (item.lastWeek > 0) {
            message =
                item.lastWeek === 1
                    ? messageMap.lastWeek.singular
                    : messageMap.lastWeek.plural;
            number = String(item.lastWeek);
        } else if (item.lastMonth > 0) {
            message =
                item.lastMonth === 1
                    ? messageMap.lastMonth.singular
                    : messageMap.lastMonth.plural;
            number = String(item.lastMonth);
        } else {
            message =
                item.other === 1
                    ? messageMap.other.singular
                    : messageMap.other.plural;
            number = String(item.other);
        }
        return {
            title: item.community.name,
            message,
            number,
            community: item.community,
        } as MySpaceWidgetInfoBox;
    });
};

const getTaskAssignedToMeInfoBoxes = (
    counter: MySpaceCounters,
    tasks: MySpaceCountersTasks,
): MySpaceWidgetInfoBox[] => {
    const boxes: MySpaceWidgetInfoBox[] = [];

    if (tasks.assignedToMeExpired > 0) {
        boxes.push({
            message:
                tasks.assignedToMeExpired === 1
                    ? 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRED_ONE'
                    : 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRED_MULTIPLE',
            number: String(tasks.assignedToMeExpired),
            warning: true,
        });
    }
    if (tasks.assignedToMeExpiringToday > 0) {
        boxes.push({
            message:
                tasks.assignedToMeExpiringToday === 1
                    ? 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_TODAY_ONE'
                    : 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_TODAY_MULTIPLE',
            number: String(tasks.assignedToMeExpiringToday),
        });
    }
    if (tasks.assignedToMeExpiringTomorrow > 0) {
        boxes.push({
            message:
                tasks.assignedToMeExpiringTomorrow === 1
                    ? 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_TOMORROW_ONE'
                    : 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_TOMORROW_MULTIPLE',
            number: String(tasks.assignedToMeExpiringTomorrow),
        });
    }
    if (tasks.assignedToMeExpiringThisWeek > 0) {
        boxes.push({
            message:
                tasks.assignedToMeExpiringThisWeek === 1
                    ? 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_THIS_WEEK_ONE'
                    : 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_THIS_WEEK_MULTIPLE',
            number: String(tasks.assignedToMeExpiringThisWeek),
        });
    }
    if (counter.createdByMeExpiringTasks > 0) {
        boxes.push({
            message: 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.EXPIRING_OTHER',
        });
    }

    return boxes;
};

const getTaskToMonitorInfoBoxes = (
    tasks: MySpaceCountersTasks,
): MySpaceWidgetInfoBox[] => {
    const boxes: MySpaceWidgetInfoBox[] = [];

    if (tasks.createdByMeExpiredTasks.length > 0) {
        const list = tasks.createdByMeExpiredTasks;
        boxes.push({
            message:
                list.length === 1
                    ? 'MANAGE.WIDGET.TASK_TO_MONITOR.EXPIRED_ONE'
                    : list.length === 2
                      ? 'MANAGE.WIDGET.TASK_TO_MONITOR.EXPIRED_TWO'
                      : list.length === 3
                        ? 'MANAGE.WIDGET.TASK_TO_MONITOR.EXPIRED_THREE'
                        : 'MANAGE.WIDGET.TASK_TO_MONITOR.EXPIRED_MULTIPLE',
            number: String(list.length === 1 ? list[0].count : list.length - 2),
            name1:
                list[0].entity.user?.firstName ||
                list[0].entity.group?.fullName,
            name2:
                list.length > 1
                    ? list[1].entity.user?.firstName ||
                      list[1].entity.group?.fullName
                    : undefined,
            name3:
                list.length > 2
                    ? list[2].entity.user?.firstName ||
                      list[2].entity.group?.fullName
                    : undefined,
            warning: true,
        });
    }

    let totalClosedCount = 0;
    const itemWithHigherCounter = tasks.createdByMeClosedTasks.length
        ? tasks.createdByMeClosedTasks.reduce((prev, current) => {
              totalClosedCount += current.count;
              return prev.count > current.count ? prev : current;
          })
        : null;
    if (itemWithHigherCounter) {
        boxes.push({
            message: 'MANAGE.WIDGET.TASK_TO_MONITOR.CLOSED',
            number: String(itemWithHigherCounter.count),
            name1: itemWithHigherCounter.entity.firstName,
        });
    }
    if (totalClosedCount > 0) {
        boxes.push({
            message: 'MANAGE.WIDGET.TASK_TO_MONITOR.CLOSED_TOTAL',
            number: String(totalClosedCount),
        });
    }

    return boxes;
};

const getDraftInfoBoxes = (
    counters: MySpaceCounters,
    drafts: MySpaceCountersDrafts,
): MySpaceWidgetInfoBox[] => {
    if (drafts.staleStandardDraftPosts > 0) {
        return [
            {
                message: 'MANAGE.WIDGET.DRAFTS.OLD',
                number: counters.standardDraftPosts.toString(),
            },
        ];
    } else if (counters.standardDraftPosts > 0) {
        return [{ message: 'MANAGE.WIDGET.DRAFTS.STANDARD' }];
    }
    return [];
};

const getScheduledInfoBoxes = (
    counters: MySpaceCounters,
    drafts: MySpaceCountersDrafts,
    locale: Locale,
): MySpaceWidgetInfoBox[] => {
    const posts = drafts.publishingScheduledDraftPosts;

    const boxes: MySpaceWidgetInfoBox[] = [];
    if (posts && posts.length > 0) {
        boxes.push({
            message: 'MANAGE.WIDGET.SCHEDULED.SINGLE',
            day: posts[0].scheduledPublication
                ? extractDay(posts[0].scheduledPublication, locale)
                : '',
            postTitle: posts[0].title,
        });
        if (posts.length === 2) {
            boxes.push({
                message: 'MANAGE.WIDGET.SCHEDULED.SINGLE',
                day: posts[1].scheduledPublication
                    ? extractDay(posts[1].scheduledPublication, locale)
                    : '',
                postTitle: posts[1].title,
            });
        } else if (posts.length > 2) {
            boxes.push({
                message: 'MANAGE.WIDGET.SCHEDULED.MULTIPLE',
                number: String(posts.length),
            });
        }
    } else if (counters.scheduledDraftPosts > 0) {
        boxes.push({
            message: 'MANAGE.WIDGET.SCHEDULED.OTHER',
        });
    }
    return boxes;
};

const extractDay = (date: ZonedDateTime, locale: Locale): string => {
    if (date.zonedDatetime) {
        return format(date.zonedDatetime, 'EEEE', { locale });
    } else return '';
};

const getMentionInfoBoxes = (
    mentionedIn: MySpaceCountersMentionedIn,
): MySpaceWidgetInfoBox[] => {
    const boxes = [];
    if (mentionedIn.mostMentioner && mentionedIn.mostMentioner.count > 0) {
        boxes.push({
            message: 'MANAGE.WIDGET.MENTIONS.USER_COUNT',
            number: String(mentionedIn.mostMentioner.count),
            name1: mentionedIn.mostMentioner.entity.firstName,
        });
    }
    if (mentionedIn.todayMentionerUser && mentionedIn.todayMentionerUser) {
        boxes.push({
            message: 'MANAGE.WIDGET.MENTIONS.TODAY_SINGLE',
            name1: mentionedIn.todayMentionerUser.firstName,
        });
    }
    if (mentionedIn.lastMonthMentioners.length > 0) {
        const list = mentionedIn.lastMonthMentioners.slice();
        boxes.push({
            message:
                list.length === 1
                    ? 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_ONE'
                    : list.length === 2
                      ? 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_TWO'
                      : list.length == 3
                        ? 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_THREE'
                        : 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_MULTIPLE',
            number: String(list.length),
            name1: mentionedIn.lastMonthMentioners[0].firstName,
            name2:
                list.length > 1
                    ? mentionedIn.lastMonthMentioners[1].firstName
                    : undefined,
            name3:
                list.length > 2
                    ? mentionedIn.lastMonthMentioners[2].firstName
                    : undefined,
        });
    }
    if (
        mentionedIn.todayMentionedGroup &&
        mentionedIn.todayMentionedGroup.count > 0
    ) {
        boxes.push({
            message:
                mentionedIn.todayMentionedGroup.count === 1
                    ? 'MANAGE.WIDGET.MENTIONS.TODAY_GROUP_SINGLE'
                    : 'MANAGE.WIDGET.MENTIONS.TODAY_GROUP_MULTIPLE',
            number: String(mentionedIn.todayMentionedGroup.count),
            name1: mentionedIn.todayMentionedGroup.entity.fullName,
        });
    }
    if (
        mentionedIn.lastMonthMostMentionedGroup &&
        mentionedIn.lastMonthMostMentionedGroup.count > 0
    ) {
        boxes.push({
            message:
                mentionedIn.lastMonthMostMentionedGroup.count === 1
                    ? 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_GROUP_SINGLE'
                    : 'MANAGE.WIDGET.MENTIONS.LAST_MONTH_GROUP_MULTIPLE',
            number: String(mentionedIn.lastMonthMostMentionedGroup.count),
            name1: mentionedIn.lastMonthMostMentionedGroup.entity.fullName,
        });
    }
    return boxes;
};

const getRepliesInfoBoxes = (
    mentionedIn: MySpaceCountersMentionedIn,
): MySpaceWidgetInfoBox[] => {
    const boxes = [];
    if (mentionedIn.todayRepliedCommentsCount > 0) {
        boxes.push({
            message:
                mentionedIn.todayRepliedCommentsCount === 1
                    ? 'MANAGE.WIDGET.REPLIES.TODAY_ONE'
                    : 'MANAGE.WIDGET.REPLIES.TODAY_MULTIPLE',
            number: String(mentionedIn.todayRepliedCommentsCount),
        });
    }
    if (mentionedIn.onFireComment) {
        boxes.push({
            message: 'MANAGE.WIDGET.REPLIES.ON_FIRE',
        });
    }
    if (mentionedIn.lastWeekRepliedCommentsCount > 0) {
        boxes.push({
            message:
                mentionedIn.lastWeekRepliedCommentsCount === 0
                    ? 'MANAGE.WIDGET.REPLIES.LAST_WEEK_ONE'
                    : 'MANAGE.WIDGET.REPLIES.LAST_WEEK_MULTIPLE',
            number: String(mentionedIn.lastWeekRepliedCommentsCount),
        });
    }
    return boxes;
};

export const mySpaceWidgetCardList = (
    widgetSize: MySpaceWidgetSize,
    locale: Locale,
    counters: MySpaceCounters = defaultMySpaceCounters(),
    countersDetail: MySpaceCountersDetail = defaultMySpaceCountersDetail(),
    showAllByDefault = true,
): MySpaceWidgetCard[] => [
    {
        id: MySpaceWidgetCardContent.PROCESSES,
        type: MySpaceWidgetCardType.SIMPLE,
        title: 'MANAGE.WIDGET.PROCESSES.LABEL',
        imgPath: 'assets/shared/images/pro-tip/process.png',
        background: 'bg-status-F',
        redirectUrl: '/manage/processes',
        queryParams: null,
        counter: counters.toManageWorkflowPosts,
        show: showAllByDefault || counters.toManageWorkflowPosts > 0,
        detailChip: null,
        detailCounter: null,
        detailType: null,
        infoBoxes:
            widgetSize === 'large' && countersDetail
                ? getProcessesInfoBoxes(countersDetail.processes).slice(0, 6)
                : null,
    },
    {
        id: MySpaceWidgetCardContent.TASK_ASSIGNED_TO_ME,
        type: MySpaceWidgetCardType.SIMPLE,
        title: 'MANAGE.WIDGET.TASK_ASSIGNED_TO_ME.LABEL',
        imgPath: 'assets/shared/images/pro-tip/task.png',
        background: 'bg-status-A',
        redirectUrl: '/manage/tasks',
        queryParams: { tab: TaskTabs.ASSIGNED_TO_ME },
        counter: counters.expiringTasks + counters.expiredTasks,
        show: true,
        detailChip: 'MANAGE.WIDGET.EXPIRED',
        detailCounter: counters.expiredTasks,
        detailType: 'warning',
        infoBoxes:
            widgetSize === 'large' && countersDetail
                ? getTaskAssignedToMeInfoBoxes(
                      counters,
                      countersDetail.tasks,
                  ).slice(0, 2)
                : null,
    },
    {
        id: MySpaceWidgetCardContent.TASK_TO_MONITOR,
        type: MySpaceWidgetCardType.SIMPLE,
        title: 'MANAGE.WIDGET.TASK_TO_MONITOR.LABEL',
        imgPath: 'assets/shared/images/pro-tip/task.png',
        background: 'bg-status-A',
        redirectUrl: '/manage/tasks',
        queryParams: { tab: TaskTabs.TO_MONITOR },
        counter:
            counters.createdByMeExpiringTasks +
            counters.createdByMeExpiredTasks,
        show:
            showAllByDefault ||
            counters.createdByMeExpiringTasks +
                counters.createdByMeExpiredTasks >
                0,
        detailChip: 'MANAGE.WIDGET.COMPLETED_RECENTLY',
        detailCounter: counters.createdByMeClosedTasks,
        detailType: 'default',
        infoBoxes:
            widgetSize === 'large' && countersDetail
                ? getTaskToMonitorInfoBoxes(countersDetail.tasks).slice(0, 2)
                : null,
    },
    {
        id: MySpaceWidgetCardContent.DRAFTS_AND_SCHEDULED,
        type: MySpaceWidgetCardType.SPLIT,
        imgPath: 'assets/shared/images/pro-tip/post.png',
        background: 'bg-status-G',
        redirectUrl: '/manage/drafts',
        queryParams: null,
        show: true,
        topCounter: counters.standardDraftPosts,
        topLabel: 'MANAGE.WIDGET.DRAFTS.LABEL',
        topIcon: 'document',
        topInfoBoxes:
            widgetSize === 'large' && countersDetail
                ? getDraftInfoBoxes(counters, countersDetail.drafts).slice(0, 1)
                : null,
        topQueryParams: { view: DraftType.DRAFT },
        bottomCounter: counters.scheduledDraftPosts,
        bottomLabel: 'MANAGE.WIDGET.SCHEDULED.LABEL',
        bottomIcon: 'clock',
        bottomInfoBoxes:
            widgetSize === 'large' && countersDetail
                ? getScheduledInfoBoxes(
                      counters,
                      countersDetail.drafts,
                      locale,
                  ).slice(0, 1)
                : null,
        bottomQueryParams: { view: DraftType.SCHEDULED },
    },
    {
        id: MySpaceWidgetCardContent.MENTIONS_AND_REPLIES,
        type: MySpaceWidgetCardType.SPLIT,
        imgPath: 'assets/shared/images/pro-tip/comments.png',
        background: 'bg-status-C',
        redirectUrl: '/manage/mentions',
        queryParams: null,
        show: true,
        topCounter: counters.mentionedInPostAndComments,
        topLabel: 'MANAGE.WIDGET.MENTIONS.LABEL',
        topIcon: 'at',
        topInfoBoxes:
            widgetSize === 'large' && countersDetail
                ? getMentionInfoBoxes(countersDetail.mentionedIn).slice(0, 1)
                : null,
        topQueryParams: { view: MentionFilterType.MENTION },
        bottomCounter: counters.commentReplies,
        bottomIcon: 'reply',
        bottomInfoBoxes:
            widgetSize === 'large' && countersDetail
                ? getRepliesInfoBoxes(countersDetail.mentionedIn).slice(0, 1)
                : null,
        bottomLabel: 'MANAGE.WIDGET.REPLIES.LABEL',
        bottomQueryParams: { view: MentionFilterType.REPLY },
    },
];
