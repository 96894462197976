import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Observable, forkJoin, map, mergeMap } from 'rxjs';
import { IPostMetadata } from '../models/base-post.model';
import { GenericPost } from '../models/generic-post.model';
import { SurveyPostDeserialize } from '../models/survey-post/survey-post.deserialize';
import { SurveyPostSerialize } from '../models/survey-post/survey-post.serialize';
import {
    SurveyPost,
    SurveyPostCreate,
    SurveyPostEdit,
    SurveyType,
} from './../models/survey-post/survey-post.model';
import { PostService } from './post.service';

@Injectable({
    providedIn: 'root',
})
export class SurveyPostService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;

    constructor(
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private communitiesStateService: CommunitiesStateService,
        private http: HttpClient,
        private postService: PostService,
    ) {}

    getPostForCreate(
        communityId: number,
        q?: any,
        surveyType: SurveyType = SurveyType.SURVEY,
    ): Observable<SurveyPost> {
        const currentUser = this.authService.getCurrentUserData();
        if (!currentUser) {
            throw new Error('Current User must be defined');
        }
        const postDefinition$ =
            this.communitiesStateService.getPostMetadata(communityId);

        const params: {
            q?: string;
            surveyType: SurveyType;
        } = {
            surveyType,
        };
        if (q) {
            params.q = q;
        }

        const api$ = this.http.get<any>(
            `${this.baseUrl}/manage/survey-post-data-for-create/${communityId}`,
            { params },
        );

        return forkJoin([postDefinition$, api$]).pipe(
            map(([metadata, postForCreate]) =>
                SurveyPostDeserialize.surveyPostDetailsForCreate(
                    this.configurationService,
                    postForCreate,
                    metadata,
                    currentUser,
                    surveyType,
                ),
            ),
        );
    }

    createPost(
        communityId: number,
        post: SurveyPostCreate,
    ): Observable<SurveyPost> {
        return this.http
            .post<{
                postData: any;
                toCreateAckTasksCount: number;
            }>(
                `${this.baseUrl}/manage/create-survey-post/${communityId}`,
                SurveyPostSerialize.newSurveyPost(post),
            )
            .pipe(
                map((res) =>
                    SurveyPostDeserialize.surveyPostDetails(
                        this.configurationService,
                        res.postData,
                        res.toCreateAckTasksCount,
                        undefined,
                        post.surveyType,
                    ),
                ),
            );
    }

    updatePost(
        post: SurveyPostEdit,
        metadata: IPostMetadata,
    ): Observable<GenericPost> {
        return this.http
            .put<{
                nextOccToken: number;
                postData: any;
            }>(
                `${this.baseUrl}/manage/edit-survey-post/${post.id}/${post.occToken}`,
                SurveyPostSerialize.editSurveyPost(post),
            )
            .pipe(
                mergeMap((result) =>
                    this.postService.getMentions(post.id).pipe(
                        map((memberMentions) => ({
                            nextOccToken: result.nextOccToken,
                            postData: { ...result.postData, memberMentions },
                        })),
                    ),
                ),
                map((res) => {
                    const newPost = SurveyPostDeserialize.surveyPostDetails(
                        this.configurationService,
                        res.postData,
                        undefined,
                        metadata,
                        post.surveyType,
                    );
                    newPost.occToken = res.nextOccToken;
                    newPost.capabilities = post.capabilities;
                    return newPost;
                }),
            );
    }

    copyPost(
        idCopy: number,
        post: SurveyPostEdit,
        metadata: IPostMetadata,
    ): Observable<SurveyPost> {
        return this.http
            .put<any>(
                `${this.baseUrl}/manage/copy-survey-post/${idCopy}/${post.occToken}`,
                SurveyPostSerialize.copySurveyPost(post),
            )
            .pipe(
                map(
                    (res: {
                        postId: number;
                        occToken: number;
                        postData: any;
                    }) => {
                        const copiedPost =
                            SurveyPostDeserialize.surveyPostDetails(
                                this.configurationService,
                                res.postData,
                                undefined,
                                metadata,
                                post.surveyType,
                            );
                        copiedPost.occToken = res.occToken;
                        return copiedPost;
                    },
                ),
            );
    }

    closeSurvey(
        postId: number,
        occToken: number,
    ): Observable<{ closedTimestamp: Date; nextOccToken: number }> {
        return this.http.post<{ closedTimestamp: Date; nextOccToken: number }>(
            `${this.baseUrl}/manage/posts/${postId}/survey/close`,
            { occToken: occToken },
        );
    }
}
