import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PostCommentCanDeactivateService {
    private _isCommentDirty = new BehaviorSubject(false);

    setCommentDirty(isDirty: boolean): void {
        this._isCommentDirty.next(isDirty);
    }

    getCommentDirty(): boolean {
        return this._isCommentDirty.value;
    }
}
