import { digitalWorkplaceHome } from '@modules/app-routing/routes';
import { AppSelectors } from '@modules/core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeaderState, headerFeatureKey } from './header.reducer';

const selectHeaderFeature =
    createFeatureSelector<HeaderState>(headerFeatureKey);

const selectShowHomeSelector = createSelector(
    selectHeaderFeature,
    AppSelectors.selectRouteState,
    (headerState, routeState) =>
        routeState?.appRoute === digitalWorkplaceHome &&
        headerState.homeSelectorItems.length > 0,
);

export const selectHomeSelectorItems = createSelector(
    selectHeaderFeature,
    selectShowHomeSelector,
    (state, showHomeSelector) =>
        showHomeSelector ? state.homeSelectorItems : [],
);

export const selectCurrentHomeId = createSelector(
    AppSelectors.selectRouteState,
    (route) => {
        if (route?.appRoute === digitalWorkplaceHome) {
            const homeId = parseInt((route?.params.homeId ?? '') as string);
            return isNaN(homeId) ? undefined : homeId;
        }
        return undefined;
    },
);
