export const tipDefaultDuration = 5000;

export enum TipSeverity {
    SUCCESS,
    INFO,
    PRO,
    WARN,
    ERROR,
}

export type TipCloseBehavior = 'duration' | 'manual' | 'forceReload';

export interface TipData {
    readonly severity: TipSeverity;
    readonly message: unknown;
    readonly closeBehavior: TipCloseBehavior;
    readonly translateParams?: Record<string, unknown>;
}

export type ProTipImage =
    | 'attachments'
    | 'comments'
    | 'community'
    | 'gardening'
    | 'indici'
    | 'performance'
    | 'person'
    | 'pinning'
    | 'post'
    | 'reading'
    | 'roles'
    | 'empty'
    | 'workspace'
    | 'process'
    | 'catalog';

export type TipCloseAction = 'close';

export interface TipAction<T> {
    id: string;
    label: string;
    data: T;
}

export interface TipActionCallback<T> {
    action: TipAction<T>;
    callback: (action: TipAction<T>) => void;
}

export interface ProTipData<A, P> extends TipData {
    readonly severity: TipSeverity.PRO;
    readonly closeBehavior: 'duration' | 'manual';
    readonly title: string | undefined;
    readonly image: ProTipImage;
    readonly actionCallbacks: TipActionCallback<A>[];
    readonly payload: P | undefined;
    readonly closeCallback: (() => void) | undefined;
    readonly hideCloseButton: boolean;
    readonly direction: 'vertical' | 'horizontal';
}

export type ProTipPartialData<A, P> = Partial<
    Omit<ProTipData<A, P>, 'severity'>
> &
    Pick<ProTipData<A, P>, 'message'>;

export type ProTipConfig<A, P> = Omit<
    ProTipPartialData<A, P>,
    'actionCallbacks' | 'closeCallback'
> & {
    tipActions?: TipAction<A>[];
    canOpen?: (payload: P | undefined) => Promise<boolean>;
};
