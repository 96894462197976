import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { CommentEditor } from '@modules/core/store/UI/ui.reducer';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Store } from '@ngrx/store';
import { Observable, concat, map, mergeMap, of } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    shareReplay,
    switchMap,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-comment-fullscreen-editor',
    templateUrl: './comment-fullscreen-editor.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutAnimation('container')],
})
export class CommentFullscreenEditorComponent implements OnInit {
    post$!: Observable<GenericPost | null | undefined>;
    commentFullScreenEditor$!: Observable<CommentEditor | null>;
    canCreateMention$!: Observable<boolean>;

    constructor(
        private store: Store,
        private communitiesStateService: CommunitiesStateService,
    ) {}

    ngOnInit(): void {
        this.post$ = concat(
            of(null),
            this.store.select(UISelectors.postIdOfFullscreenComment).pipe(
                filter((postId) => isDefined(postId)),
                distinctUntilChanged(),
                /* UISelectors.postOfFullscreenComment depends on PostSelectors.selectAllPosts, and 'communities' feature is 
                not yet loaded in store when CommentFullscreenEditorComponent is initialized */
                switchMap(() =>
                    this.store.select(UISelectors.postOfFullscreenComment),
                ),
            ),
        ).pipe(shareReplay(1));

        this.canCreateMention$ = this.post$.pipe(
            mergeMap((post) => {
                if (post) {
                    return this.communitiesStateService.getCommunity(
                        post.communityId,
                    );
                } else {
                    return of(null);
                }
            }),
            map((community) => community?.capabilities?.createMention ?? false),
        );

        this.commentFullScreenEditor$ = this.store.select(
            UISelectors.commentEditor,
        );
    }
}
