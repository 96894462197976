import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList } from '@interacta-shared/util';
import {
    IUserAction,
    UserExtendedDeserialize,
} from '@modules/core/models/user-group.model';
import {
    EventParticipateType,
    EventPostDeserialize,
    EventPostSerialize,
    IEventPost,
    IEventPostCreate,
    IEventPostEdit,
} from '@modules/post/models/event-post.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IPostMetadata } from '../models/base-post.model';
import {
    CommentCreateResponse,
    CommentDeserialize,
    CommentSerialize,
    StreamingCommentCreate,
    StreamingCommentsList,
} from '../models/comment.model';
import { PostService } from './post.service';

@Injectable({ providedIn: 'root' })
export class EventPostService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;
    private manageBaserUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/manage`;

    constructor(
        private authService: AuthService,
        private postService: PostService,
        private configurationService: ConfigurationService,
        private communitiesStateService: CommunitiesStateService,
        private http: HttpClient,
    ) {}

    getPostForCreate(communityId: number, q?: any): Observable<IEventPost> {
        const requests = [
            this.communitiesStateService.getPostMetadata(communityId),
            this.http.get<any>(
                `${this.baseUrl}/manage/event-post-data-for-create/${communityId}`,
                q ? { params: { q } } : undefined,
            ),
        ];
        return forkJoin(requests).pipe(
            map((results) =>
                EventPostDeserialize.eventPostDetailsForCreate(
                    this.configurationService,
                    results[1],
                    results[0] as IPostMetadata,
                    this.authService.getCurrentUserData()!,
                ),
            ),
        );
    }

    createPost(
        communityId: number,
        post: IEventPostCreate,
    ): Observable<IEventPost> {
        return this.http
            .post<any>(
                `${this.baseUrl}/manage/create-event-post/${communityId}`,
                EventPostSerialize.newEventPost(post),
            )
            .pipe(
                map(
                    (res: {
                        postId: number;
                        occToken: string;
                        postData: any;
                    }) =>
                        EventPostDeserialize.eventPostDetails(
                            this.configurationService,
                            res.postData,
                        ),
                ),
            );
    }

    updatePost(
        post: IEventPostEdit,
        metadata: IPostMetadata,
    ): Observable<IEventPost> {
        return this.http
            .put<{
                nextOccToken: number;
                postData: any;
            }>(
                `${this.baseUrl}/manage/edit-event-post/${post.id}/${post.occToken}`,
                EventPostSerialize.editEventPost(post),
            )
            .pipe(
                mergeMap((result) =>
                    this.postService.getMentions(post.id).pipe(
                        map((memberMentions) => ({
                            nextOccToken: result.nextOccToken,
                            postData: { ...result.postData, memberMentions },
                        })),
                    ),
                ),
                map((res) => {
                    const newPost = EventPostDeserialize.eventPostDetails(
                        this.configurationService,
                        res.postData,
                        metadata,
                    );
                    newPost.occToken = res.nextOccToken;
                    newPost.capabilities = post.capabilities;
                    return newPost;
                }),
            );
    }

    getParticipantsList(
        postId: number,
        typeId: EventParticipateType | null,
        pageToken?: string,
        name?: string,
    ): Observable<PaginatedList<IUserAction>> {
        return this.http
            .post(
                `${this.baseUrl}/data/post-event/${postId}/participants-response-list`,
                {
                    pageToken,
                    calculateTotalItemsCount: !name && !pageToken,
                    name,
                    typeId,
                },
            )
            .pipe(map((res) => UserExtendedDeserialize.participantsList(res)));
    }

    copyPost(
        idCopy: number,
        post: IEventPostEdit,
        metadata: IPostMetadata,
    ): Observable<IEventPost> {
        return this.http
            .put<any>(
                `${this.baseUrl}/manage/copy-event-post/${idCopy}/${post.occToken}`,
                EventPostSerialize.copyEventPost(post),
            )
            .pipe(
                map(
                    (res: {
                        postId: number;
                        occToken: number;
                        postData: any;
                    }) => {
                        const post = EventPostDeserialize.eventPostDetails(
                            this.configurationService,
                            res.postData,
                            metadata,
                        );
                        post.occToken = res.occToken;
                        return post;
                    },
                ),
            );
    }

    participate(
        postId: number,
        typeId: EventParticipateType,
        notes?: string,
    ): Observable<unknown> {
        return this.http.put(
            `${this.baseUrl}/manage/post-event/${postId}/partecipate`,
            { typeId, notes },
        );
    }

    getStreamingVideo(postId: number): Observable<string> {
        return this.http
            .get<{
                streamingLink: string;
            }>(`${this.baseUrl}/data/streaming-video/detail/${postId}`)
            .pipe(map((value) => value.streamingLink));
    }

    getPostStreamingComments(
        postId: number,
        pageSize = 10,
        pageToken?: string,
        syncToken?: string,
    ): Observable<StreamingCommentsList> {
        const data = {
            syncToken,
            pageToken,
            calculateTotalItemsCount: true,
            pageSize,
        };
        return this.http
            .post<any>(
                `${this.baseUrl}/data/comments-list-for-streaming/${postId}`,
                data,
            )
            .pipe(map((res) => CommentDeserialize.streamingCommentsList(res)));
    }

    createStreamingComment(
        postId: number,
        comment: StreamingCommentCreate,
    ): Observable<CommentCreateResponse> {
        return this.http
            .post<any>(
                `${this.baseUrl}/manage/create-comment-for-streaming/${postId}`,
                CommentSerialize.fromStreamingComment(comment),
            )
            .pipe(
                map((record) => ({
                    createdComment: CommentDeserialize.comment(record.comment),
                    postFolloweByMeEnabled: record.followedByMe ?? false,
                })),
            );
    }
}
