<div
    *ngIf="{
        selectionRecord: selectionService.getSelectionRecord$() | async,
        selection: selection$ | async
    } as $"
>
    @if (canEditAttachments || attachmentsList?.attachments.length) {
        <label class="typo-sm-bold block pb-4">{{
            (canEditAttachments
                ? 'POST_CREATE.ADD_ATTACHMENTS'
                : 'DETAIL.ATTACHMENTS'
            ) | translate
        }}</label>
    }

    @if (canEditAttachments) {
        <interacta-attachment-selection-actions-bar
            *ngIf="$.selection && $.selection.attachments.length > 0"
            class="mt-12 block"
            @selection-bar
            [bgColor]="'surface-B'"
            [canDelete]="$.selection.canDelete"
            [canShare]="$.selection.canShare"
            [canTag]="$.selection.canTag"
            [itemsSelected]="$.selection.attachments"
            [withSelectAll]="true"
            (delete)="removeAttachments($.selection.attachments, true)"
            (deselectAll)="selectionService.reset()"
            (downloadSelected)="downloadAll($.selection.attachments)"
            (editHashtags)="openEditHashtagsDialog($.selection.attachments)"
            (selectAll)="selectionService.selectAll()"
        ></interacta-attachment-selection-actions-bar>
    }

    <!-- lista allegati -->
    <div
        *ngIf="
            $.selectionRecord &&
            attachmentsList &&
            attachmentsList.attachments.length
        "
        class="my-16 flex w-full flex-col space-y-16"
    >
        <div
            *ngFor="
                let attachment of attachmentsList.attachments;
                let index = index;
                let last = last;
                trackBy: trackAttachment
            "
            class="flex items-baseline"
        >
            @if (canEditAttachments) {
                <interacta-checkbox
                    variant="circle"
                    [checked]="$.selectionRecord[attachment.id]?.selected"
                    [value]="attachment.id"
                    (changeCheck)="selectionService.toggleSelect(attachment.id)"
                    (mouseenter)="selectionService.hover(attachment.id)"
                    (mouseleave)="selectionService.unhover()"
                ></interacta-checkbox>
            }

            <interacta-attachment-form-chip
                class="min-w-0"
                [allowAddVersion]="canAddVersion"
                [attachment]="attachment"
                [bgColor]="'surface-B'"
                [driveEnabled]="
                    allowDrive && googleAPIService.driveIntegrationEnabled
                "
                [hashtagsAvailable]="availableHashtags.length > 0"
                [selected]="$.selectionRecord[attachment.id]?.selected || false"
                (cancelUpload)="cancelUploads([attachment])"
                (editAttachment)="openRenameDialog(attachment)"
                (editHashtags)="openEditHashtagsDialog([attachment])"
                (manageVersion)="manageVersion(attachment)"
                (removeAttachment)="removeAttachments([attachment])"
                (restoreAttachment)="restoreAttachment(index)"
                (retryUpload)="retryUpload(index, attachment)"
            >
            </interacta-attachment-form-chip>
        </div>
        <interacta-load-more
            *ngIf="attachmentsList.pageTokenInfo.tag === 'regularLoading'"
            class="self-center"
            size="large"
            [isLoading]="attachmentsList.isLoading"
            [label]="'SHOW_MORE' | translate"
            (loadMore)="loadMore.emit()"
        >
        </interacta-load-more>
    </div>

    <div
        *ngIf="(allowFileSystem || allowDrive) && canEditAttachments"
        class="flex flex-wrap items-center gap-12"
    >
        <!-- Allegati locali -->
        <interacta-local-file-select
            *ngIf="allowFileSystem"
            [acceptedMimeType]="acceptedMimeType"
            [appearance]="'border'"
            [label]="'POST_CREATE.CHOOSE_FILE' | translate"
            [uploader]="fileUploader"
        />
        <interacta-drive-file-select
            *ngIf="allowDrive && googleAPIService.driveIntegrationEnabled"
            size="regular"
            [acceptedMimeType]="acceptedMimeType"
            [appearance]="'border'"
            [label]="'POST_CREATE.ADD_FROM_DRIVE' | translate"
            (addDriveAttachments)="addDriveAttachments($event)"
        ></interacta-drive-file-select>
    </div>
</div>

<ng-container *ngIf="isEditingAttachment$ | async as isEditingAttachment">
    <interacta-attachment-edit-dialog
        *ngIf="isEditingAttachment.attachments"
        [attachments]="isEditingAttachment.attachments"
        [editMode]="isEditingAttachment.editMode"
        [hashtags]="availableHashtags"
        [isDialogOpen]="isEditingAttachment.isOpen"
        (closeDialog)="closeDialog()"
        (editAttachment)="editAttachment($event)"
        (editHashtags)="editHashtags($event)"
    >
    </interacta-attachment-edit-dialog>
</ng-container>
