import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { AttachmentLayout } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachments-view-switch',
    templateUrl: './attachments-view-switch.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsViewSwitchComponent {
    @Input() activeLayout!: AttachmentLayout;
    @Output() changeLayout = new EventEmitter<AttachmentLayout>();

    AttachmentLayout = AttachmentLayout;
}
