<div class="flex">
    <button
        class="rotate-90"
        interacta-icon-button
        type="button"
        [active]="activeLayout === AttachmentLayout.GRID"
        [icon]="'simple-mosaic-regular'"
        [matTooltip]="'DASHBOARD.ATTACHMENTS.LAYOUT_GRID' | translate"
        (click)="changeLayout.emit(AttachmentLayout.GRID)"
    ></button>
    <button
        interacta-icon-button
        type="button"
        [active]="activeLayout === AttachmentLayout.ROW"
        [icon]="'card-row'"
        [matTooltip]="'DASHBOARD.ATTACHMENTS.LAYOUT_ROW' | translate"
        (click)="changeLayout.emit(AttachmentLayout.ROW)"
    ></button>
</div>
