<div class="flex flex-col space-y-8">
    <div
        #images
        injShowMoreChildren
        [active]="!expanded"
        [ignoreShowMoreBtnWidth]="true"
        [ngClass]="{
            'flex space-x-8': !expanded,
            'grid auto-cols-fr grid-rows-1 gap-8': expanded
        }"
        [ngStyle]="{
            'grid-template-columns': expanded
                ? 'repeat(auto-fill, ' +
                  (size === 'regular' ? '5.25rem' : '3.5rem') +
                  ')'
                : 'unset'
        }"
        (showMore)="onHiddenElements($event)"
    >
        <ng-container
            *ngFor="
                let media of mediaList.attachments;
                trackBy: trackAttachments;
                let idx = index
            "
        >
            <button
                *ngIf="{
                    isShowMoreButton:
                        showMoreCount > 0 &&
                        idx + 1 + showMoreCount === mediaList.totalCount
                } as $"
                class="relative shrink-0 rounded-md"
                injShowMoreItem
                type="button"
                [attr.aria-label]="
                    $.isShowMoreButton
                        ? (showMoreCount
                          | i18nPlural
                              : {
                                    '=1': 'UI.SHOW_MORE_UNCOUNT',
                                    other: 'SHOW_MORE_COUNT'
                                }
                          | translate: { counter: showMoreCount })
                        : ('DASHBOARD.ATTACHMENTS.OPEN_ATTACHMENT'
                          | translate: { name: media.name })
                "
                [ngClass]="{
                    'opacity-0': !initialized
                }"
                (click)="handleClick(idx, $.isShowMoreButton)"
            >
                <interacta-attachment-preview
                    [attachment]="media"
                    [size]="size"
                ></interacta-attachment-preview>
                <interacta-attachment-icon
                    class="absolute left-4 top-4"
                    [attachment]="media"
                ></interacta-attachment-icon>

                <interacta-hashtag-mini-chip
                    *ngIf="(media.hashtags || []).length > 0"
                    class="absolute bottom-4 left-4"
                ></interacta-hashtag-mini-chip>

                <div
                    *ngIf="$.isShowMoreButton"
                    class="bg-transparent-black-not-black-40 typo-medium-bold text-text-white absolute left-0 top-0 flex h-full w-full place-content-center items-center rounded-md"
                    injShowMoreButton
                >
                    + {{ showMoreCount }}
                </div>
            </button>
        </ng-container>
    </div>
</div>
