import { NgModule } from '@angular/core';
import {
    ApplyPipe,
    ArrayPipe,
    ButtonLightBoxComponent,
    ButtonScrollArrowComponent,
    BypassSecurityTrustPipe,
    HashtagChipComponent,
    ImageComponent,
    InputSearchComponent,
    LoaderComponent,
    ScrollTrackerDirective,
    SeparatorComponent,
} from '@interacta-shared/ui';
import {
    InteractaDatePipe,
    NoResultComponent,
} from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { RoundPipe } from '@modules/shared-v2/pipes/round.pipe';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LightBoxAttachmentComponent } from './components/light-box-attachment/light-box-attachment.component';
import { LightBoxFooterComponent } from './components/light-box-footer/light-box-footer.component';
import { LightBoxHeaderButtonsComponent } from './components/light-box-header-buttons/light-box-header-buttons.component';
import { LightBoxHeaderComponent } from './components/light-box-header/light-box-header.component';
import { LightBoxInfoSectionComponent } from './components/light-box-info-section/light-box-info-section.component';
import { LightBoxInfoComponent } from './components/light-box-info/light-box-info.component';
import { LightBoxLayoutComponent } from './components/light-box-layout/light-box-layout.component';
import { LightBoxMiniSliderComponent } from './components/light-box-mini-slider/light-box-mini-slider.component';
import { LightBoxPreviewsComponent } from './components/light-box-previews/light-box-previews.component';
import { LightBoxSearchComponent } from './components/light-box-search/light-box-search.component';
import { LightBoxSectionPlaceholderComponent } from './components/light-box-section-placeholder/light-box-section-placeholder.component';
import { LightBoxSectionComponent } from './components/light-box-section/light-box-section.component';
import { LightBoxZoomComponent } from './components/light-box-zoom/light-box-zoom.component';
import { LightBoxComponent } from './components/light-box/light-box.component';
import { SectionsEffects } from './store/sections/sections.effects';
import { sectionsReducer } from './store/sections/sections.reducer';

const standaloneComponents = [
    IconComponent,
    HashtagChipComponent,
    ImageComponent,
    InputSearchComponent,
    LoaderComponent,
    SeparatorComponent,
    ButtonLightBoxComponent,
    ButtonScrollArrowComponent,
    NoResultComponent,
];

const standalonePipes = [
    ApplyPipe,
    ArrayPipe,
    BypassSecurityTrustPipe,
    InteractaDatePipe,
    RoundPipe,
];

const standaloneDirectives = [ScrollTrackerDirective];

@NgModule({
    declarations: [
        LightBoxComponent,
        LightBoxHeaderButtonsComponent,
        LightBoxAttachmentComponent,
        LightBoxZoomComponent,
        LightBoxMiniSliderComponent,
        LightBoxInfoComponent,
        LightBoxInfoSectionComponent,
        LightBoxPreviewsComponent,
        LightBoxHeaderComponent,
        LightBoxFooterComponent,
        LightBoxSearchComponent,
        LightBoxSectionComponent,
        LightBoxSectionPlaceholderComponent,
        LightBoxLayoutComponent,
    ],
    imports: [
        SharedV2Module,
        ...standaloneComponents,
        ...standalonePipes,
        ...standaloneDirectives,
        AttachmentsModule,
        StoreModule.forFeature('sections', sectionsReducer),
        EffectsModule.forFeature([SectionsEffects]),
    ],
    exports: [
        LightBoxComponent,
        LightBoxLayoutComponent,
        LightBoxFooterComponent,
    ],
})
export class LightBoxModule {}
