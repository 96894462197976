import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { getAttachmentsMimetype } from '@modules/post/models/attachment/attachment.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-attachment-version',
    templateUrl: './attachment-version.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentVersionComponent implements OnChanges {
    @Input({ required: true }) version!: Partial<IAttachment>;
    @Input() control!: FormControl<string | null>;
    @Input() useVersionNameForAttachment = false;

    @Output() removeVersion = new EventEmitter<void>();
    @Output() cancelUpload = new EventEmitter<void>();
    @Output() changeCheck = new EventEmitter<boolean>();

    mimeType = '';
    now = new Date();
    hoverRow$ = new BehaviorSubject<boolean>(false);
    hoverError$ = new BehaviorSubject<boolean>(false);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.version?.currentValue) {
            this.mimeType = getAttachmentsMimetype(
                this.version.contentMimeType,
            );
        }
    }
}
