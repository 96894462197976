import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { getNextPageToken } from '@interacta-shared/util';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-media-list[mediaList]',
    templateUrl: './media-list.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaListComponent implements OnChanges {
    @Input() mediaList!: IListAttachments;
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() expandable = false;

    @Output() openMedia = new EventEmitter<{ index: number }>();
    @Output() showMore = new EventEmitter<{
        pageSize: number;
        nextPageToken?: string;
    }>();

    @ViewChild('images') imagesRef!: ElementRef;

    showMoreCount = 0;
    initialized = false;
    expanded = false;

    private hiddenCount = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.mediaList && !changes.mediaList.firstChange) {
            this.updateShowCount();
        }
    }

    onHiddenElements(hiddenCount: number): void {
        this.hiddenCount = hiddenCount;
        this.initialized = true;

        this.updateShowCount();
    }

    private requestFetchMore(): void {
        const columns = this.getColumns();
        const imagesExceedingRow = this.mediaList.attachments.length % columns;
        const imagesToCompleteRow =
            imagesExceedingRow > 0 ? columns - imagesExceedingRow : 0;
        const pageSize = this.expanded
            ? columns * 2 + imagesToCompleteRow
            : imagesToCompleteRow;
        if (pageSize > 0) {
            this.showMore.emit({
                pageSize,
                nextPageToken:
                    getNextPageToken(this.mediaList.pageTokenInfo) ?? undefined,
            });
        }
        if (!this.expanded) {
            this.expanded = true;
            this.hiddenCount = 0;
            this.updateShowCount();
        }
    }

    trackAttachments(_idx: number, item: IAttachment): IAttachment['id'] {
        return item.id;
    }

    handleClick(idx: number, isShowMoreBtn: boolean): void {
        if (isShowMoreBtn && this.expandable) {
            this.requestFetchMore();
        } else {
            this.openMedia.emit({ index: idx });
        }
    }

    private updateShowCount(): void {
        const shownCount = this.mediaList.attachments.length - this.hiddenCount;
        this.showMoreCount = this.mediaList.totalCount - shownCount;
    }

    private getColumns(): number {
        if (this.expanded) {
            const columnsCSS = getComputedStyle(
                this.imagesRef.nativeElement,
            ).gridTemplateColumns;

            return columnsCSS.split(' ').length;
        } else {
            return this.mediaList.attachments.length - this.hiddenCount;
        }
    }
}
