import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CheckboxState } from '@interacta-shared/ui';
import { Index } from '@interacta-shared/util';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { flatMap } from '@modules/core/helpers/generic.utils';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

type SelectableHashtagType = Pick<IHashTag, 'id' | 'name'> & {
    label: string;
};

export interface AttachmentsHashtagsEdit {
    attachmentIds: Index[];
    addHashtags: IHashTag[];
    removeHashtags: IHashTag[];
}

@Component({
    selector: 'interacta-attachment-edit-dialog[attachments][hashtags]',
    templateUrl: './attachment-edit-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentEditDialogComponent implements OnChanges {
    @Input() isDialogOpen = false;
    @Input() attachments!: IAttachment[];
    @Input() hashtags: IHashTag[] | null = null;
    @Input() editMode: 'rename' | 'hashtags' = 'hashtags';
    @Output() closeDialog = new EventEmitter<void>();
    @Output() editAttachment = new EventEmitter<IAttachment>();
    @Output()
    editHashtags = new EventEmitter<AttachmentsHashtagsEdit>();

    nameControl = new UntypedFormControl('', [Validators.required]);
    hashtagControl = new UntypedFormControl([]);
    occurrences: Record<number, { occ: number; state: CheckboxState }> = {};

    items: SelectableHashtagType[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hashtags) {
            this.items = (this.hashtags || []).map((h) => ({
                id: h.id,
                name: h.name,
                label: h.name,
            }));
        }
        if (
            changes.attachments &&
            changes.attachments.currentValue &&
            changes.attachments.currentValue.length === 1
        ) {
            this.nameControl.setValue(changes.attachments.currentValue[0].name);
        }

        if (changes.attachments && changes.attachments.currentValue) {
            const selectedHashtag = flatMap(this.hashtags || [], (h) => {
                let countOccurrences = 0;

                this.attachments.forEach((a) => {
                    if (
                        a.hashtags &&
                        a.hashtags.find((hashtag) => hashtag.id === h.id)
                    ) {
                        countOccurrences++;
                    }
                });
                if (countOccurrences) {
                    this.occurrences[h.id] = {
                        occ: countOccurrences,
                        state:
                            countOccurrences === this.attachments.length
                                ? true
                                : 'indeterminate',
                    };
                }
                return countOccurrences
                    ? [
                          {
                              item: h,
                              state:
                                  countOccurrences === this.attachments.length
                                      ? true
                                      : 'indeterminate',
                          },
                      ]
                    : [];
            });
            this.hashtagControl.setValue(selectedHashtag);
        }
    }

    saveName(): void {
        this.editAttachment.emit({
            ...this.attachments[0],
            inUpdating: true,
            name: this.nameControl.value,
        });
        this.closeDialog.emit();
    }

    saveHashtags(): void {
        const hashtagIds = new Map<number, CheckboxState>();
        this.hashtagControl?.value.forEach((h: any) =>
            hashtagIds.set(h.item, h.state),
        );

        const removed: Array<IHashTag> = [];
        const added = (this.hashtags || []).filter(
            (h) => hashtagIds.get(h.id) === true,
        );

        Object.keys(this.occurrences).forEach((key) => {
            if (!hashtagIds.has(parseInt(key)) && this.hashtags) {
                const hashtagFound = this.hashtags.find(
                    (h) => h.id === parseInt(key),
                );
                hashtagFound && removed.push(hashtagFound);
            }
        });
        this.editHashtags.emit({
            attachmentIds: this.attachments.map((a) => a.id),
            addHashtags: added,
            removeHashtags: removed,
        });

        this.closeDialog.emit();
    }
}
