import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Palette } from '@interacta-shared/ui';
import {
    AttachmentCategoryType,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { IComment } from '@modules/post/models/comment.model';

@Component({
    selector: 'interacta-post-comment-quote',
    templateUrl: './post-comment-quote.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentQuoteComponent {
    @Input({ required: true }) comment!: IComment;
    @Input() bgColor: Extract<Palette, 'gray-100' | 'gray-300'> = 'gray-300';
    @Input() collapsed = false;

    @Output() openAttachment = new EventEmitter<{
        list: IListAttachments;
        index: number;
    }>();
    @Output() quoteClick = new EventEmitter<IComment>();

    canShowMore = false;
    showingMore = false;

    AttachmentCategoryType = AttachmentCategoryType;
}
