import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    canEditAttachment,
    canEditHashtagsAttachment,
    canManageVersionAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { filter, map, Observable } from 'rxjs';
import { GoogleAPIService } from './../../../core/services/google-api.service';

@Component({
    selector: 'interacta-attachment-description',
    templateUrl: './attachment-description.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentDescriptionComponent implements OnChanges {
    @Input({ required: true }) attachment!: IAttachment;
    @Input() aiEnabled = false;
    @Input() showPostTitle = true;
    @Input() enableMenu = true;
    @Input() canShare = false;
    @Input() customButtons: ICustomButton[] = [];
    @Input() preview = false;
    @Input() hashtagsAvailable = false;
    @Input() showOriginField = false;

    @Output() download = new EventEmitter<IAttachment>();
    @Output() openTitle = new EventEmitter<void>();
    @Output() edit = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() manageVersion = new EventEmitter<void>();
    @Output() share = new EventEmitter<void>();
    @Output() sendToAI = new EventEmitter<void>();

    isMenuOpen = false;

    canEditAttachment = canEditAttachment;
    canEditHashtags = canEditHashtagsAttachment;
    canManageVersionAttachment = canManageVersionAttachment;

    originFieldLabel$?: Observable<string | null>;

    constructor(
        public googleAPIService: GoogleAPIService,
        private communitiesStateService: CommunitiesStateService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes?.attachment &&
            this.attachment.communityId &&
            this.showOriginField
        ) {
            this.originFieldLabel$ = this.communitiesStateService
                .getPostMetadata(this.attachment.communityId)
                .pipe(
                    filter(isDefined),
                    map((value) => {
                        if (this.attachment.postFilePickerFieldId) {
                            return value.fieldMetadatas.find(
                                (m) =>
                                    m.id ===
                                    this.attachment.postFilePickerFieldId,
                            )?.label;
                        } else if (
                            this.attachment.screenFilePickerFieldId &&
                            value.workflowDefinition?.screenFieldMetadatas
                        ) {
                            return value.workflowDefinition.screenFieldMetadatas.find(
                                (m) =>
                                    m.id ===
                                    this.attachment.screenFilePickerFieldId,
                            )?.label;
                        } else {
                            return null;
                        }
                    }),
                );
        }
    }

    openDrive(): void {
        if (this.attachment.drive) {
            window.open(this.attachment.drive.webViewLink, '_blank');
        }
    }
}
