import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Palette, Size } from '@interacta-shared/ui';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    canAddVersionAttachment,
    canDeleteAttachment,
    canEditAttachment,
    canEditHashtagsAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-attachment-form-chip',
    templateUrl: './attachment-form-chip.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFormChipComponent {
    @Input({ required: true }) attachment!: Partial<IAttachment>;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-B';
    @Input() allowDelete = true;
    @Input() allowEdit = true;
    @Input() allowAddVersion = true;
    @Input() size: Extract<Size, 'regular' | 'small'> = 'small';
    @Input() selected = false;
    @Input() driveEnabled = false;
    @Input() hashtagsAvailable = false;

    @Output() retryUpload = new EventEmitter<void>();
    @Output() cancelUpload = new EventEmitter<void>();
    @Output() editAttachment = new EventEmitter<void>();
    @Output() editHashtags = new EventEmitter<void>();
    @Output() removeAttachment = new EventEmitter<void>();
    @Output() restoreAttachment = new EventEmitter<void>();
    @Output() manageVersion = new EventEmitter<void>();

    hoverRow$ = new BehaviorSubject<boolean>(false);
    hoverError$ = new BehaviorSubject<boolean>(false);

    canDeleteAttachment = canDeleteAttachment;
    canAddVersionAttachment = canAddVersionAttachment;
    canEditAttachment = canEditAttachment;
    canEditHashtags = canEditHashtagsAttachment;
}
