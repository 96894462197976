import {
    PageTokenInfo,
    areEqualObjects,
    emptyPaginatedList,
    mapArrayById,
    uuid,
} from '@interacta-shared/util';
import { QuillText } from '@modules/core';
import { EventType } from '@modules/core/models/notification-user.model';
import {
    FeedbackTaskSummary,
    TaskProcessingStatus,
} from '@modules/post/models/base-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { SurveyState } from '@modules/post/models/survey-post/survey-post.model';
import { isFeedBackPostType } from '@modules/post/models/survey-post/survey-post.utils';
import {
    FeedbackTask,
    FeedbackTaskCommentSummary,
    FeedbackTaskState,
    GenericTask,
    ITaskType,
} from '@modules/tasks/models/task.model';
import {
    FeedbackAnsweredTaskState,
    FeedbackClosedTaskState,
    FeedbackHistoryItem,
    FeedbackInsightsAnsweredTasksChart,
    FeedbackInsightsAnsweredTasksData,
    FeedbackInsightsChartsLegend,
    FeedbackInsightsCompliantTasksChart,
    FeedbackInsightsCompliantTasksData,
    FeedbackInsightsDetailDialogOptions,
    FeedbackInsightsDetailsDialogFilter,
    FeedbackInsightsTaskStateStyle,
    FeedbackInsightsTimeManagementChart,
    FeedbackInsightsTimeManagementData,
    FeedbackInsightsTotalTasksChart,
    FeedbackInsightsTotalTasksData,
    FeedbackMonitorTabs,
    FeedbackTaskAPIFilters,
    FeedbackTaskFilters,
    FeedbackTaskHistory,
    FeedbackTaskOperation,
    FeedbackTaskRole,
    FeedbackTaskStateStyle,
    FeedbackTaskWorkflow,
} from './feedback.model';

const feedbackTaskWorkflow: FeedbackTaskWorkflow = {
    [FeedbackTaskState.OPEN]: [
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SEND',
            fromState: FeedbackTaskState.OPEN,
            toState: FeedbackTaskState.IN_REVIEW,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SEND',
            withData: true,
            withOptionalNote: true,
            permittedTo: ['recipient'],
            surveyState: ['open'],
        },
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SAVE',
            fromState: FeedbackTaskState.OPEN,
            toState: FeedbackTaskState.IN_PROGRESS,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SAVE',
            withData: true,
            withOptionalNote: false,
            permittedTo: ['recipient'],
            surveyState: ['open'],
        },
    ],
    [FeedbackTaskState.IN_PROGRESS]: [
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SEND',
            fromState: FeedbackTaskState.IN_PROGRESS,
            toState: FeedbackTaskState.IN_REVIEW,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SEND',
            withData: true,
            withOptionalNote: true,
            permittedTo: ['recipient'],
            surveyState: ['open'],
        },
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SAVE',
            fromState: FeedbackTaskState.IN_PROGRESS,
            toState: FeedbackTaskState.IN_PROGRESS,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SAVE',
            withData: true,
            withOptionalNote: false,
            permittedTo: ['recipient'],
            surveyState: ['open'],
        },
    ],
    [FeedbackTaskState.REWORK]: [
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SEND',
            fromState: FeedbackTaskState.REWORK,
            toState: FeedbackTaskState.IN_REVIEW,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SEND',
            withData: true,
            withOptionalNote: true,
            permittedTo: ['recipient'],
            surveyState: ['open', 'closed'],
        },
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.SAVE',
            fromState: FeedbackTaskState.REWORK,
            toState: FeedbackTaskState.REWORK,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.SAVE',
            withData: true,
            withOptionalNote: false,
            permittedTo: ['recipient'],
            surveyState: ['open', 'closed'],
        },
    ],
    [FeedbackTaskState.IN_REVIEW]: [
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.VALIDATE_OK',
            fromState: FeedbackTaskState.IN_REVIEW,
            toState: FeedbackTaskState.CLOSED_OK,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.VALIDATE_OK',
            withData: false,
            withOptionalNote: true,
            permittedTo: ['manager'],
            surveyState: ['open', 'closed'],
        },
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.VALIDATE_KO',
            fromState: FeedbackTaskState.IN_REVIEW,
            toState: FeedbackTaskState.CLOSED_KO,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.VALIDATE_KO',
            withData: false,
            withOptionalNote: true,
            permittedTo: ['manager'],
            surveyState: ['open', 'closed'],
        },
        {
            id: uuid(),
            label: 'FEEDBACK.OPERATION.VALIDATE_REWORK',
            fromState: FeedbackTaskState.IN_REVIEW,
            toState: FeedbackTaskState.REWORK,
            description: 'FEEDBACK.OPERATION_DESCRIPTION.VALIDATE_REWORK',
            withData: false,
            withOptionalNote: true,
            permittedTo: ['manager'],
            surveyState: ['open', 'closed'],
        },
    ],
    [FeedbackTaskState.CLOSED_OK]: [],
    [FeedbackTaskState.CLOSED_KO]: [],
};

export const feedbackTaskStateArray: FeedbackTaskState[] = Object.values(
    FeedbackTaskState,
)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => <FeedbackTaskState>v);

export const permittedOperations = (
    taskState: FeedbackTaskState,
    role: FeedbackTaskRole,
    surveyState: SurveyState,
): FeedbackTaskOperation[] =>
    feedbackTaskWorkflow[taskState].filter(
        (operation) =>
            operation.permittedTo.includes(role) &&
            operation.surveyState.includes(surveyState),
    );

export const areOperationsEquals = (
    a: FeedbackTaskOperation[],
    b: FeedbackTaskOperation[],
): boolean => areEqualObjects(mapArrayById(a), mapArrayById(b));

export const atLeastOneTaskAssignedToMe = (
    summary: FeedbackTaskSummary | undefined,
): number => {
    return (
        (getAssignedToMeOpenCount(summary) ?? 0) +
        (getAssignedToMeInProgressCount(summary) ?? 0) +
        (getAssignedToMeReworkCount(summary) ?? 0)
    );
};

export const areFiltersEquals = (
    a: FeedbackTaskFilters,
    b: FeedbackTaskFilters,
): boolean =>
    a === b || (a.assingedToMe === b.assingedToMe && a.state === b.state);

const feedbackTaskStateStyleMap: Record<
    FeedbackTaskState,
    FeedbackTaskStateStyle
> = {
    [FeedbackTaskState.OPEN]: {
        label: 'FEEDBACK.TASK_STATE.OPEN',
        background: 'bg-status-N',
        text: 'text-on-status',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.OPEN',
        color: {
            light: '--in-color-pizzo',
            dark: '--in-color-pizzo-D',
        },
    },
    [FeedbackTaskState.IN_PROGRESS]: {
        label: 'FEEDBACK.TASK_STATE.IN_PROGRESS',
        background: 'bg-status-G',
        text: 'text-on-status',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.IN_PROGRESS',
        color: {
            light: '--in-color-lilla',
            dark: '--in-color-lilla-D',
        },
    },
    [FeedbackTaskState.IN_REVIEW]: {
        label: 'FEEDBACK.TASK_STATE.IN_REVIEW',
        background: 'bg-status-F',
        text: 'text-on-status',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.IN_REVIEW',
        color: {
            light: '--in-color-azzurro-2',
            dark: '--in-color-azzurro-2-D',
        },
    },
    [FeedbackTaskState.REWORK]: {
        label: 'FEEDBACK.TASK_STATE.REWORK',
        background: 'bg-status-C',
        text: 'text-on-status',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.REWORK',
        color: {
            light: '--in-color-sole',
            dark: '--in-color-sole-D',
        },
    },
    [FeedbackTaskState.CLOSED_OK]: {
        label: 'FEEDBACK.TASK_STATE.CLOSED_OK',
        background: 'bg-status-E',
        text: 'text-on-status',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.CLOSED_OK',
        color: {
            light: '--in-color-lime',
            dark: '--in-color-lime-D',
        },
    },
    [FeedbackTaskState.CLOSED_KO]: {
        label: 'FEEDBACK.TASK_STATE.CLOSED_KO',
        background: 'bg-avatar-A',
        text: 'pure-white',
        emptyLabel: 'FEEDBACK.EMPTY_LIST.CLOSED_KO',
        color: {
            light: '--in-color-rosso',
            dark: '--in-color-rosso-D-20-sat',
        },
    },
};

export function getFeedbackTaskStateStyle(
    state: FeedbackTaskState,
): FeedbackTaskStateStyle {
    return feedbackTaskStateStyleMap[state];
}

export const feedbackTaskStateOrderedArray: FeedbackTaskState[] = [
    FeedbackTaskState.OPEN,
    FeedbackTaskState.IN_PROGRESS,
    FeedbackTaskState.IN_REVIEW,
    FeedbackTaskState.REWORK,
    FeedbackTaskState.CLOSED_OK,
    FeedbackTaskState.CLOSED_KO,
];

export const feedbackAnsweredTaskState: FeedbackAnsweredTaskState[] = [
    FeedbackAnsweredTaskState.ANSWERED,
    FeedbackAnsweredTaskState.ANSWERED_BEFORE_EXPIRATION,
    FeedbackAnsweredTaskState.NOT_ANSWERED,
];

export const feedbackClosedTaskState: FeedbackClosedTaskState[] = [
    FeedbackClosedTaskState.CLOSED_OK,
    FeedbackClosedTaskState.CLOSED_OK_FIRST_SUBMIT,
    FeedbackClosedTaskState.OTHER_CASES,
];

const feedbackAnsweredTaskStyleMap: Record<
    FeedbackAnsweredTaskState,
    FeedbackInsightsTaskStateStyle
> = {
    [FeedbackAnsweredTaskState.ANSWERED]: {
        label: 'FEEDBACK.ANSWERED_TASK_STATE.ANSWERED',
        background: 'bg-palette-secondary',
        color: {
            light: '--in-color-blu',
            dark: '--in-color-blu-D',
        },
    },
    [FeedbackAnsweredTaskState.ANSWERED_BEFORE_EXPIRATION]: {
        label: 'FEEDBACK.ANSWERED_TASK_STATE.ANSWERED_BEFORE_EXPIRATION',
        background: 'bg-palette-quaternary',
        color: {
            light: '--in-color-azzurro',
            dark: '--in-color-azzurro-D',
        },
    },
    [FeedbackAnsweredTaskState.NOT_ANSWERED]: {
        label: 'FEEDBACK.ANSWERED_TASK_STATE.NOT_ANSWERED',
        background: 'bg-surface-300',
        color: {
            light: '--in-color-grigio-300',
            dark: '--in-color-grigio-300-D',
        },
    },
};

export const getFeedbackAnsweredTaskStateStyle = (
    state: FeedbackAnsweredTaskState,
): FeedbackInsightsTaskStateStyle => feedbackAnsweredTaskStyleMap[state];

const feedbackClosedTaskStyleMap: Record<
    FeedbackClosedTaskState,
    FeedbackInsightsTaskStateStyle
> = {
    [FeedbackClosedTaskState.CLOSED_OK]: {
        label: 'FEEDBACK.COMPLIANT_TASK_STATE.CLOSED_OK',
        background: 'bg-status-E',
        color: {
            light: '--in-color-lime',
            dark: '--in-color-lime-D',
        },
    },
    [FeedbackClosedTaskState.CLOSED_OK_FIRST_SUBMIT]: {
        label: 'FEEDBACK.COMPLIANT_TASK_STATE.CLOSED_OK_FIRST_SUBMIT',
        background: 'bg-status-L',
        color: {
            light: '--in-color-acido',
            dark: '--in-color-acido-D',
        },
    },
    [FeedbackClosedTaskState.OTHER_CASES]: {
        label: 'FEEDBACK.COMPLIANT_TASK_STATE.OTHER_CASES',
        background: 'bg-surface-300',
        color: {
            light: '--in-color-grigio-300',
            dark: '--in-color-grigio-300-D',
        },
    },
};

export const getFeedbackClosedTaskStateStyle = (
    state: FeedbackClosedTaskState,
): FeedbackInsightsTaskStateStyle => feedbackClosedTaskStyleMap[state];

export const stateLabel = (taskState: FeedbackTaskState): string =>
    getFeedbackTaskStateStyle(taskState).label;

/**
 * Feedback tasks count
 * Assinged to me
 */

export const getAssignedToMeOpenCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return summary?.assignedToMeTasksCount[FeedbackTaskState.OPEN];
};

export const getAssignedToMeClosedOkCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return summary?.assignedToMeTasksCount[FeedbackTaskState.CLOSED_OK];
};

export const getAssignedToMeInProgressCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return summary?.assignedToMeTasksCount[FeedbackTaskState.IN_PROGRESS];
};

export const getAssignedToMeInReviewCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return getInReviewCount(summary, true);
};

export const getAssignedToMeReworkCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return summary?.assignedToMeTasksCount[FeedbackTaskState.REWORK];
};

export const getAssignedToMeClosedKoCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return summary?.assignedToMeTasksCount[FeedbackTaskState.CLOSED_KO];
};

export const getAssignedToMeCount = (
    summary: FeedbackTaskSummary | undefined,
): number | undefined => {
    return getTotalTasksCount(summary, true);
};

export const getAssignedToMeSendedCount = (
    summary: FeedbackTaskSummary | undefined,
): number => {
    return (
        (getAssignedToMeInReviewCount(summary) ?? 0) +
        (getAssignedToMeClosedOkCount(summary) ?? 0) +
        (getAssignedToMeClosedKoCount(summary) ?? 0)
    );
};

export const getAssignedToMeNeverSendedCount = (
    summary: FeedbackTaskSummary | undefined,
): number => {
    return (
        (getAssignedToMeOpenCount(summary) ?? 0) +
        (getAssignedToMeInProgressCount(summary) ?? 0)
    );
};

export const getAssignedToMeSendableCount = (
    summary: FeedbackTaskSummary | undefined,
    state: SurveyState,
): number => {
    return (
        (state !== 'closed' ? getAssignedToMeOpenCount(summary) ?? 0 : 0) +
        (state !== 'closed'
            ? getAssignedToMeInProgressCount(summary) ?? 0
            : 0) +
        (getAssignedToMeReworkCount(summary) ?? 0)
    );
};

/**
 * Feedback tasks count
 * All
 */

export const getInReviewCount = (
    summary: FeedbackTaskSummary | undefined,
    assignedToMe?: boolean,
): number | undefined => {
    const stateCounters = assignedToMe
        ? summary?.assignedToMeTasksCount
        : summary?.tasksCount;
    return stateCounters?.[FeedbackTaskState.IN_REVIEW];
};

export const getTotalTasksCount = (
    summary: FeedbackTaskSummary | undefined,
    assignedToMe?: boolean,
): number | undefined => {
    const stateCounters = assignedToMe
        ? summary?.assignedToMeTasksCount
        : summary?.tasksCount;

    return summary
        ? feedbackTaskStateArray
              .map((s) => stateCounters?.[s] ?? 0)
              .reduce((tot, x) => tot + x, 0)
        : undefined;
};

export const getOpenCount = (
    summary: FeedbackTaskSummary | undefined,
    assignedToMe?: boolean,
): number | undefined => {
    return assignedToMe
        ? getAssignedToMeOpenCount(summary)
        : summary?.tasksCount?.[FeedbackTaskState.OPEN];
};

export const getInProgressCount = (
    summary: FeedbackTaskSummary | undefined,
    assignedToMe?: boolean,
): number | undefined => {
    return assignedToMe
        ? getAssignedToMeInProgressCount(summary)
        : summary?.tasksCount?.[FeedbackTaskState.IN_PROGRESS];
};

export const getReceivedTasksCount = (
    summary: FeedbackTaskSummary | undefined,
    assignedToMe?: boolean,
): number | undefined => {
    const stateCounters = assignedToMe
        ? summary?.assignedToMeTasksCount
        : summary?.tasksCount;

    return summary
        ? feedbackTaskStateArray
              .filter((state) =>
                  [
                      FeedbackTaskState.IN_REVIEW,
                      FeedbackTaskState.CLOSED_OK,
                      FeedbackTaskState.CLOSED_KO,
                  ].includes(state),
              )
              .map((s) => stateCounters?.[s] ?? 0)
              .reduce((tot, x) => tot + x, 0)
        : undefined;
};

export function reorderWorkflowHistoryItem(
    prevList: FeedbackTaskHistory['list'],
    newList: FeedbackTaskHistory['list'],
    pageToken: PageTokenInfo,
): FeedbackTaskHistory['list'] {
    //TODO capire se questo va bene, o bisogna fare delle cose in stile
    //toWorkflowHistoryItem di workflow history deserialize

    return [...newList, ...prevList];
}

export const buildHistoryItemFromChangeState = (
    task: FeedbackTask,
    fromState: FeedbackTaskState,
    message: QuillText,
): FeedbackHistoryItem => {
    return {
        tag: 'state-change',
        id: uuid(),
        timestamp: task.lastModifyTimestamp ?? new Date(),
        authorUser:
            task.lastModifyUser ?? task.lastReviewUser ?? task.creatorUser!,
        typeId: EventType.FEEDBACK_TASK_CHANGE_STATUS,
        typeDescription: 'Feedback Task Change Status',
        community: null,
        targetUser: null,
        task,
        fromState,
        toState: task.state,
        message,
    };
};

export const buildHistoryItemFromEditState = (
    task: FeedbackTask,
): FeedbackHistoryItem => {
    return {
        tag: 'edit-state',
        id: uuid(),
        timestamp: task.lastModifyTimestamp ?? new Date(),
        authorUser:
            task.lastModifyUser ?? task.lastReviewUser ?? task.creatorUser!,
        typeId: EventType.EDIT_TASK,
        typeDescription: 'Feedback Task Edit',
        community: null,
        targetUser: null,
        task,
    };
};

export const isFeedbackTask = (
    task: GenericTask | null,
): task is FeedbackTask => {
    return task != null && task.type === ITaskType.FEEDBACK;
};

export const toFeedbackTaskStateCountRecord = (
    tasks: FeedbackTask[],
): Record<FeedbackTaskState, number> => {
    const result = {} as Record<FeedbackTaskState, number>;
    feedbackTaskStateArray.forEach(
        (s) => (result[s] = tasks.filter((i) => i.state === s).length),
    );
    return result;
};

export const areCountersEquals = (
    a: Record<FeedbackTaskState, number>,
    b: Record<FeedbackTaskState, number>,
): boolean =>
    feedbackTaskStateArray.reduce((result, s) => result && a[s] === b[s], true);

export const isFeedbackPostInProcessingStatus = (
    post: GenericPost,
): boolean => {
    return (
        isFeedBackPostType(post) &&
        post.feedbackTaskSummary?.processingStatus ===
            TaskProcessingStatus.IN_PROGRESS
    );
};

export const getFeedbackTaskCommentSummary = (
    task: FeedbackTask,
    fieldId: number,
): FeedbackTaskCommentSummary | undefined => task.dataCommentsSummary[fieldId];

export const toFeedbackTotalTaskData = (
    data: FeedbackInsightsTotalTasksData | null,
): FeedbackInsightsTotalTasksChart | null => {
    if (!data) return null;

    const legends: FeedbackInsightsChartsLegend<FeedbackTaskState>[] =
        feedbackTaskStateOrderedArray.map((key) => {
            const stateStyle = getFeedbackTaskStateStyle(key);
            const count = data.perStateTasksCounterMap[key];
            return {
                label: stateStyle.label,
                color: stateStyle.background,
                count,
                state: key,
            };
        });

    return {
        totalCount: data.totalTasksCount,
        legends,
        perStateTasksCounterMap: data.perStateTasksCounterMap,
    };
};

export const toFeedbackAnsweredTaskData = (
    data: FeedbackInsightsAnsweredTasksData | null,
): FeedbackInsightsAnsweredTasksChart | null => {
    if (!data) return null;
    const perStateTasksCounterMap: Record<FeedbackAnsweredTaskState, number> = {
        [FeedbackAnsweredTaskState.ANSWERED]: data.answeredTasksCount,
        [FeedbackAnsweredTaskState.ANSWERED_BEFORE_EXPIRATION]:
            data.answeredBeforeExpirationTasksCount,
        [FeedbackAnsweredTaskState.NOT_ANSWERED]:
            data.totalTasksCount - data.answeredTasksCount,
    };

    const legends: FeedbackInsightsChartsLegend<FeedbackAnsweredTaskState>[] =
        feedbackAnsweredTaskState.map((state) => {
            const { label, background } =
                getFeedbackAnsweredTaskStateStyle(state);
            const count = perStateTasksCounterMap[state];
            return { label, color: background, count, state };
        });

    const answeredPercentage = !data.totalTasksCount
        ? 0
        : Math.round((data.answeredTasksCount / data.totalTasksCount) * 100);

    return {
        totalCount: data.totalTasksCount,
        answeredCount: data.answeredTasksCount,
        answeredBeforeExpirationCount: data.answeredBeforeExpirationTasksCount,
        answeredPercentage,
        legends,
        perStateTasksCounterMap,
    };
};

export const toFeedbackClosedOkTaskData = (
    data: FeedbackInsightsCompliantTasksData | null,
): FeedbackInsightsCompliantTasksChart | null => {
    if (!data) return null;
    const perStateTasksCounterMap: Record<FeedbackClosedTaskState, number> = {
        [FeedbackClosedTaskState.CLOSED_OK]: data.closedOkTasksCount,
        [FeedbackClosedTaskState.CLOSED_OK_FIRST_SUBMIT]:
            data.closedOkAtFirstAnswerTasksCount,
        [FeedbackClosedTaskState.OTHER_CASES]: data.otherCasesTasksCount,
    };

    const legends: FeedbackInsightsChartsLegend<FeedbackClosedTaskState>[] =
        feedbackClosedTaskState.map((state) => {
            const { label, background } =
                getFeedbackClosedTaskStateStyle(state);
            const count = perStateTasksCounterMap[state];
            return { label, color: background, count, state };
        });

    const closedOkPercentage = !data.totalAnsweredTasksCount
        ? 0
        : Math.round(
              (data.closedOkTasksCount / data.totalAnsweredTasksCount) * 100,
          );

    return {
        totalCount: data.totalAnsweredTasksCount,
        closedOkCount: data.closedOkTasksCount,
        closedOkAtFirstAnswerCount: data.closedOkAtFirstAnswerTasksCount,
        closedOkPercentage,
        otherCasesCount: data.otherCasesTasksCount,
        legends,
        perStateTasksCounterMap,
    };
};

export const toFeedbackTimeManagementData = (
    data: FeedbackInsightsTimeManagementData | null,
): FeedbackInsightsTimeManagementChart | null => {
    if (!data) return null;

    const parsedData: FeedbackInsightsTimeManagementChart = {
        toReview: null,
        toClosed: null,
    };

    if (data.avgTimeToReview) {
        const toReviewList = data.avgTimeToReviewList?.list || [];
        const avgTimeToReviewMaxValue =
            toReviewList[toReviewList.length - 1]?.timeToReview || 0;

        parsedData.toReview = {
            avgTimeValue: data.avgTimeToReview,
            maxTimeValueFromList: avgTimeToReviewMaxValue,
            avgTimeList:
                data.avgTimeToReviewList || emptyPaginatedList<FeedbackTask>(),
            avgTimeFromState: FeedbackTaskState.OPEN,
            avgTimeToState: FeedbackTaskState.IN_REVIEW,
        };
    }

    if (data.avgTimeToClosed) {
        const toClosedList = data.avgTimeToClosedList?.list || [];
        const avgTimeToClosedMaxValue =
            toClosedList[toClosedList.length - 1]?.timeToClosed || 0;

        parsedData.toClosed = {
            avgTimeValue: data.avgTimeToClosed,
            maxTimeValueFromList: avgTimeToClosedMaxValue,
            avgTimeList:
                data.avgTimeToClosedList || emptyPaginatedList<FeedbackTask>(),
            avgTimeFromState: FeedbackTaskState.OPEN,
            avgTimeToState: FeedbackTaskState.CLOSED_OK,
        };
    }

    return parsedData;
};

export const feedbackInsightsFirstAnswerFilter: FeedbackTaskAPIFilters = {
    assigneeName: null,
    assingedToMe: false,
    order: {
        orderBy: 'timeToReview',
        orderDesc: false,
    },
    states: [
        FeedbackTaskState.IN_REVIEW,
        FeedbackTaskState.REWORK,
        FeedbackTaskState.CLOSED_OK,
        FeedbackTaskState.CLOSED_KO,
    ],
    calculateTotalItemsCount: true,
};

export const feedbackInsightsToClosedFilter: FeedbackTaskAPIFilters = {
    assigneeName: null,
    assingedToMe: false,
    order: {
        orderBy: 'timeToClosed',
        orderDesc: false,
    },
    states: [FeedbackTaskState.CLOSED_OK],
    calculateTotalItemsCount: true,
};

export const toFeedbackTaskAPIFilters = (
    filters: FeedbackTaskFilters,
): FeedbackTaskAPIFilters => {
    return {
        ...filters,
        states: filters.state ? [filters.state] : null,
    };
};

export const getTaskFiltersFromTaskState = (
    taskState: FeedbackTaskState,
): FeedbackTaskAPIFilters => {
    return {
        states: [taskState],
        assingedToMe: false,
        assigneeName: null,
        order: { orderBy: '', orderDesc: false },
    };
};

export const getTaskFiltersFromAnsweredTaskState = (
    answeredTaskState: FeedbackAnsweredTaskState,
): FeedbackTaskAPIFilters => {
    return {
        states:
            answeredTaskState === FeedbackAnsweredTaskState.NOT_ANSWERED
                ? [FeedbackTaskState.OPEN, FeedbackTaskState.IN_PROGRESS]
                : [
                      FeedbackTaskState.CLOSED_KO,
                      FeedbackTaskState.CLOSED_OK,
                      FeedbackTaskState.IN_REVIEW,
                      FeedbackTaskState.REWORK,
                  ],
        assingedToMe: false,
        assigneeName: null,
        order: { orderBy: '', orderDesc: false },
        firstReviewBeforeExpiring:
            answeredTaskState ===
            FeedbackAnsweredTaskState.ANSWERED_BEFORE_EXPIRATION
                ? true
                : false,
    };
};

export const getTaskFiltersFromClosedTaskState = (
    closedTaskState: FeedbackClosedTaskState,
): FeedbackTaskAPIFilters => {
    return {
        states:
            closedTaskState === FeedbackClosedTaskState.CLOSED_OK ||
            closedTaskState === FeedbackClosedTaskState.CLOSED_OK_FIRST_SUBMIT
                ? [FeedbackTaskState.CLOSED_OK]
                : [
                      FeedbackTaskState.CLOSED_KO,
                      FeedbackTaskState.IN_REVIEW,
                      FeedbackTaskState.REWORK,
                  ],
        assingedToMe: false,
        assigneeName: null,
        order: { orderBy: '', orderDesc: false },
        onlyOneReview:
            closedTaskState === FeedbackClosedTaskState.CLOSED_OK_FIRST_SUBMIT
                ? true
                : undefined,
    };
};
export const timeManagementToFeedbackInsightsDetailDialogOptions = (
    itemType: 'timeToReview' | 'timeToClosed',
): FeedbackInsightsDetailDialogOptions | null => {
    const baseOptions: FeedbackInsightsDetailDialogOptions = {
        detailDialogTitle: 'FEEDBACK.INSIGHTS.DIALOG.AVG_TIME_TITLE',
        detailDialogTitleParams: null,
        size: 'regular',
        feedbackTaskDetailDialogFilter: null,
        isDetailDialogOpen: true,
        showRedirectButton: false,
        showFeedbackTaskDateInfo: false,
        showFeedbackTaskStateInfo: 'none',
        totalCount: null,
    };
    if (itemType === 'timeToReview') {
        return {
            ...baseOptions,
            detailDialogTitleParams: {
                fromState: 'FEEDBACK.TASK_STATE.OPEN',
                toState: 'FEEDBACK.TASK_STATE.IN_REVIEW',
            },
            feedbackTaskDetailDialogFilter: feedbackInsightsFirstAnswerFilter,
            showFeedbackTaskStateInfo: 'timeToReview',
        };
    } else if (itemType === 'timeToClosed') {
        return {
            ...baseOptions,
            detailDialogTitleParams: {
                fromState: 'FEEDBACK.TASK_STATE.OPEN',
                toState: 'FEEDBACK.TASK_STATE.CLOSED_OK',
            },
            feedbackTaskDetailDialogFilter: feedbackInsightsToClosedFilter,
            showFeedbackTaskStateInfo: 'timeToClosed',
        };
    }
    return null;
};

export const filterToFeedbackInsightsDetailDialogOptions = (
    event: FeedbackInsightsDetailsDialogFilter,
    totalCount: number,
): FeedbackInsightsDetailDialogOptions | null => {
    const baseOptions: FeedbackInsightsDetailDialogOptions = {
        feedbackTaskDetailDialogFilter: null,
        detailDialogTitle: '',
        detailDialogTitleParams: null,
        size: 'small',
        isDetailDialogOpen: true,
        showRedirectButton: false,
        showFeedbackTaskDateInfo: true,
        showFeedbackTaskStateInfo: 'none',
        totalCount,
    };
    if (event.taskState) {
        return {
            ...baseOptions,
            feedbackTaskDetailDialogFilter: getTaskFiltersFromTaskState(
                event.taskState,
            ),
            detailDialogTitle: getFeedbackTaskStateStyle(event.taskState).label,
            showRedirectButton: true,
        };
    } else if (event.answeredTaskState) {
        return {
            ...baseOptions,
            feedbackTaskDetailDialogFilter: getTaskFiltersFromAnsweredTaskState(
                event.answeredTaskState,
            ),
            detailDialogTitle: getFeedbackAnsweredTaskStateStyle(
                event.answeredTaskState,
            ).label,
        };
    } else if (event.closedTaskState) {
        return {
            ...baseOptions,
            feedbackTaskDetailDialogFilter: getTaskFiltersFromClosedTaskState(
                event.closedTaskState,
            ),
            detailDialogTitle: getFeedbackClosedTaskStateStyle(
                event.closedTaskState,
            ).label,
        };
    }

    return null;
};

export const stateToTab = (
    state: FeedbackTaskState | null,
): FeedbackMonitorTabs => {
    if (state === null) return 'ALL';
    return FeedbackTaskState[state] as FeedbackMonitorTabs;
};
