<div
    #resizableContainer
    class="relative overflow-hidden"
    [ngClass]="{
        'bg-surface-A': bgColor === 'surface-A',
        'bg-surface-B': bgColor === 'surface-B',
        'bg-surface-C': bgColor === 'surface-C',
        'bg-surface-primary-low-contrast':
            bgColor === 'surface-primary-low-contrast'
    }"
>
    <span #fullText class="whitespace-nowrap" [ngClass]="textNgClass">{{
        text
    }}</span>
    <span
        *ngIf="textOverflow$ | async"
        class="absolute right-0 top-0 block w-1/2 overflow-hidden text-ellipsis whitespace-nowrap"
        dir="rtl"
        [ngClass]="
            [
                {
                    'bg-surface-A': bgColor === 'surface-A',
                    'bg-surface-B': bgColor === 'surface-B',
                    'bg-surface-C': bgColor === 'surface-C',
                    'bg-surface-primary-low-contrast':
                        bgColor === 'surface-primary-low-contrast'
                },
                textNgClass
            ] | apply: merge
        "
        >{{ text }}</span
    >
</div>
