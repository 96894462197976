import {
    PaginatedList,
    lastLoading,
    regularLoading,
} from '@interacta-shared/util';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import {
    BasePostDeserialize,
    IPostMetadata,
    VisibilityType,
    defaultBasePostCapabilities,
} from '@modules/post/models/base-post.model';
import {
    toPostCustomFields,
    toScreenCustomFields,
} from '../custom-metadata/custom-metadata.deserialize';
import { toWorkflowStatus } from '../workflow/workflow-base.deserialize';
import { IPostLinkAndRelation } from './post-link-and-relation.model';

export const toPostLinkAndRelation = (
    record: any,
    metadata: IPostMetadata,
): IPostLinkAndRelation => ({
    communityId: record.communityId,
    code: record.customId,
    title: record.title,
    description: record.descriptionPlainText ?? '',
    descriptionDelta: record.descriptionDelta
        ? new Server2Delta().process(record.descriptionDelta as string)
        : null,
    deleted: record.deleted,
    currentWorkflowState: record.currentWorkflowState
        ? toWorkflowStatus(record.currentWorkflowState)
        : undefined,
    type: record.type,
    tag: record.type,
    id: record.id,
    customFields: toPostCustomFields(record.customData, metadata),
    customFieldsScreen: toScreenCustomFields(undefined, metadata),
    occToken: record.occToken,
    capabilities: {
        ...(record.capabilities
            ? BasePostDeserialize.postCapabilities(record.capabilities)
            : defaultBasePostCapabilities()),
        canSeparate:
            record.capabilities?.canModify &&
            (!record.screenLinkFieldIds.length ||
                (record.screenLinkFieldIds.length &&
                    record.postLinkFieldIds.length)),
    },
    postLinkFieldIds: record.postLinkFieldIds ?? [],
    screenLinkFieldIds: record.screenLinkFieldIds ?? [],
    visibility: record.visibility ?? VisibilityType.PUBLIC,
    announcement: record.announcement ?? false,
    metadata,
});

export const toLinkAndRelationPosts = (
    record: any,
    metadata: IPostMetadata,
): PaginatedList<IPostLinkAndRelation> => ({
    list: (<Array<any>>record.items || []).map((i) =>
        toPostLinkAndRelation(i, metadata),
    ),
    nextPageToken: record.nextPageToken
        ? regularLoading(record.nextPageToken)
        : lastLoading(),
    totalCount: record.totalItemsCount,
    isFetching: record.isFetching,
});
