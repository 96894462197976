<ng-container
    *ngIf="{
        post: post$ | async,
        commentFullScreenEditor: commentFullScreenEditor$ | async,
        canCreateMention: (canCreateMention$ | async) ?? false
    } as $"
>
    <div
        *ngIf="$.post && $.commentFullScreenEditor"
        class="fixed inset-0 z-20 flex justify-center"
        @container
        [ngClass]="{
            'bg-transparent-black-not-black-80':
                $.commentFullScreenEditor.overlayPalette ===
                'black-not-black-80',
            'items-center':
                $.commentFullScreenEditor.verticalAlign === 'center',
            'mb-40 items-end': $.commentFullScreenEditor.verticalAlign === 'end'
        }"
    >
        <interacta-post-comment-input
            [createMention]="$.canCreateMention"
            [disableFullScreen]="
                $.commentFullScreenEditor.verticalAlign === 'end'
            "
            [draft]="$.post.commentDraft"
            [initialViewMode]="'extended'"
            [isFullScreen]="true"
            [locationType]="$.commentFullScreenEditor.location"
            [ngClass]="{
                'w-676': $.commentFullScreenEditor.size === 'regular',
                'w-492': $.commentFullScreenEditor.size === 'small'
            }"
            [post]="$.post"
            [showCloseButton]="
                $.commentFullScreenEditor.verticalAlign === 'end'
            "
        ></interacta-post-comment-input>
    </div>
</ng-container>
