@if (quoted) {
    <section class="flex items-center gap-x-4">
        <interacta-icon class="size-16" [icon]="'microphone'"></interacta-icon>
        <p
            [ngClass]="{
                'typo-xs': size === 'extra-small',
                'typo-sm': size === 'small'
            }"
        >
            {{
                'DASHBOARD.COMMENTS.VOICE_MESSAGE.QUOTED'
                    | translate
                    | lowercase
            }}
            ({{
                comment.voiceComment?.duration ?? 0
                    | apply: formatQuotedDuration
            }})
        </p>
    </section>
} @else {
    <section class="flex h-36" (click)="$event.stopPropagation()">
        <button
            class="text-surface-700 shrink-0"
            interacta-icon-button
            type="button"
            [border]="true"
            [icon]="isPlaying() ? 'pause' : 'play'"
            [matTooltip]="
                (isPlaying()
                    ? 'DASHBOARD.COMMENTS.VOICE_MESSAGE.PAUSE'
                    : 'DASHBOARD.COMMENTS.VOICE_MESSAGE.PLAY'
                ) | translate
            "
            [ngClass]="{
                'animate-pulse': loading()
            }"
            (click)="playPause()"
        ></button>
        <div
            class="grow"
            [id]="{ comment, uuid } | apply: getWaveformId"
            [ngClass]="{
                'mx-6': active()
            }"
        ></div>
        <div
            class="bg-surface-300 h-2 self-center"
            [ngClass]="{
                'mx-6 w-full': !active(),
                'w-0': active(),
                'animate-pulse': loading()
            }"
        ></div>
        <button
            class="typo-xs w-52 shrink-0"
            interacta-button
            type="button"
            [appearance]="'border'"
            [label]="'X ' + (currentPlaybackSpeed() | number: '1.1-1')"
            [matTooltip]="
                'DASHBOARD.COMMENTS.VOICE_MESSAGE.CHANGE_PLAYBACK_SPEED'
                    | translate
            "
            [shape]="'round'"
            [textCenter]="true"
            [width]="'full'"
            (click)="nextPlaybackSpeed()"
        ></button>
    </section>
    <time class="typo-xs mt-8 block">{{ displayedFormattedTime() }}</time>
}
