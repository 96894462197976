import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import {
    PaginatedList,
    isDefined,
    toPaginatedList,
} from '@interacta-shared/util';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { toLinkAndRelationPosts } from '@modules/communities/models/post-link-and-relation/post-link-and-relation.deserialize';
import {
    IPostLinkAndRelation,
    PostLinkFilters,
} from '@modules/communities/models/post-link-and-relation/post-link-and-relation.model';
import { fromPostLinkAndRelationFilters } from '@modules/communities/models/post-link-and-relation/post-link-and-relation.serialize';
import { zipWith } from '@modules/core/helpers/generic.utils';
import { ManageProcessesFilters } from '@modules/manage/models/manage.model';
import { fromManageProcessesFilters } from '@modules/manage/models/manage.serialize';
import {
    toAttachmentsCounter,
    toAttachmentsList,
} from '@modules/post/models/attachment/attachment.deserialize';
import {
    AttachmentCategoryType,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    IBasePost,
    IBasePostDifferential,
    IPostMetadata,
    PostType,
} from '@modules/post/models/base-post.model';
import {
    CustomPostDeserialize,
    ICustomPost,
} from '@modules/post/models/custom-post.model';
import {
    DraftType,
    IPostFilters,
    IPostLinkFilters,
} from '@modules/post/models/filter-post/filter-post.model';
import {
    IPostItemList,
    PostList,
    PostListDeserialize,
} from '@modules/post/models/post-list.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Observable, forkJoin, of } from 'rxjs';
import { concatMap, defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import {
    fromCommunityListFilter,
    fromGlobalListFilter,
    fromPostLinkFilters,
} from '../models/filter-post/filter-post.serialize';
import { toPostLinkSearch } from '../models/post-link/post-link.deserialize';
import { IPostLink } from '../models/post-link/post-link.model';
import { SurveyType } from '../models/survey-post/survey-post.model';

@Injectable({ providedIn: 'root' })
export class PostListService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/data`;
    private attachmentsUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/attachments/data`;

    constructor(
        private http: HttpClient,
        private communitiesStateService: CommunitiesStateService,
        private configurationService: ConfigurationService,
        private authService: AuthService,
    ) {}

    mockedList(
        size: number,
        communityIds: number[],
        currentWorkflowStatusIds: number[],
        postTypes: PostType[],
        postSurveyTypes?: SurveyType[],
    ): Observable<IBasePost[]> {
        return this.http
            .post<{ list: any[] }>(`${this.baseUrl}/mocks`, {
                communityIds,
                currentWorkflowStatusIds,
                postTypes,
                postSurveyTypes,
                size,
            })
            .pipe(
                mergeMap(({ list }) =>
                    forkJoin(
                        list.map((item) =>
                            this.communitiesStateService
                                .getPostMetadata(item.communityId)
                                .pipe(
                                    map((metadata: IPostMetadata) => ({
                                        ...PostListDeserialize.postDetails(
                                            this.configurationService,
                                            item,
                                            metadata,
                                        ),
                                        capabilities: isDefined(
                                            item.capabilities,
                                        )
                                            ? CustomPostDeserialize.postCapabilities(
                                                  item.capabilities,
                                              )
                                            : undefined,
                                        draft: false,
                                        isScheduled: false,
                                    })),
                                ),
                        ),
                    ),
                ),
            );
    }

    globalList(
        filter: IPostFilters,
        communityIds?: number[],
        loadMainAttachment = false,
        filterByVisibilityPreferences = false,
        loadCapabilities = false,
        loadMainAttachmentViewLink = false,
    ): Observable<PostList> {
        const _loadMainAttachment = String(loadMainAttachment);
        return this.http
            .post<any>(
                `${this.baseUrl}/list/global`,
                fromGlobalListFilter(
                    filter,
                    communityIds,
                    this.authService.getCurrentUserData() ?? undefined,
                ),
                {
                    params: {
                        filterByVisibilityPreferences: String(
                            filterByVisibilityPreferences,
                        ),
                        loadMainAttachment: _loadMainAttachment,
                        loadMainAttachmentPreviewImageHiResLink:
                            _loadMainAttachment,
                        loadMainAttachmentPreviewImageHiResAnimatedLink:
                            _loadMainAttachment,
                        loadCapabilities: String(loadCapabilities),
                        loadMainAttachmentViewLink,
                    },
                },
            )
            .pipe(
                mergeMap((record: any) => this.postItemConcat(record, filter)),
            );
    }

    postPickerList(
        fieldId: number,
        communityId: number,
        postId: number | null,
        type: MetadataFieldModeType,
        search: IPostLinkFilters,
    ): Observable<PaginatedList<IPostLink>> {
        const typePath = this.getPostPickerPathByType(type);
        return this.http
            .post<any>(
                type === MetadataFieldModeType.SURVEY && postId
                    ? `${this.baseUrl}/${postId}/${typePath}/${fieldId}`
                    : `${this.baseUrl}/${typePath}/${fieldId}/communities/${communityId}`,
                fromPostLinkFilters(search),
            )
            .pipe(map((res: any) => toPostLinkSearch(res)));
    }

    getLinkedPosts(
        postId: number,
        communityId: number,
        metadata: IPostMetadata,
        pagination: PostLinkFilters,
    ): Observable<PaginatedList<IPostLinkAndRelation>> {
        return this.http
            .post(
                `${this.baseUrl}/linked-by-list/${postId}/communities/${communityId}`,
                fromPostLinkAndRelationFilters(pagination),
                {
                    params: {
                        loadCapabilities: 'true',
                    },
                },
            )
            .pipe(map((posts) => toLinkAndRelationPosts(posts, metadata)));
    }

    communityList(
        filter: IPostFilters,
        loadCapabilities = false,
        loadPostDetails = true,
        loadMainAttachmentViewLink = false,
    ): Observable<PostList> {
        return this.http
            .post<{
                items: { id: number; communityId: number }[];
            }>(
                `${this.baseUrl}/list/community/${filter.communityId}`,
                fromCommunityListFilter(
                    filter,
                    this.authService.getCurrentUserData() || undefined,
                ),
                {
                    params: {
                        loadPostDetails: String(loadPostDetails),
                        loadCapabilities: String(loadCapabilities),
                        loadMainAttachmentViewLink: String(
                            loadMainAttachmentViewLink,
                        ),
                        loadMainAttachment: 'true',
                        loadMainAttachmentPreviewImageHiResLink: 'true',
                        loadMainAttachmentPreviewImageHiResAnimatedLink: 'true',
                    },
                },
            )
            .pipe(mergeMap((record) => this.postItemConcat(record, filter)));
    }

    communityAttachmentList(filter: IPostFilters): Observable<{
        attachmentsList: IListAttachments;
        mediasCount: number;
        documentsCount: number;
    }> {
        return this.http
            .post<{
                items: any[];
                imageAttachmentsCount: number;
                documentsAttachmentsCount: number;
                videoAttachmentsCount: number;
                audioAttachmentsCount: number;
                nextPageToken: string | undefined;
            }>(
                `${this.attachmentsUrl}/list/community/${filter.communityId}`,
                fromCommunityListFilter(
                    filter,
                    this.authService.getCurrentUserData() || undefined,
                ),
                {
                    params: {
                        loadViewLink: 'true',
                        loadDownloadLink: 'false',
                        loadPreviewImageLink: 'true',
                    },
                },
            )
            .pipe(
                map((res) => {
                    const { mediasCount, documentsCount } =
                        toAttachmentsCounter({
                            imageAttachmentsCount: res.imageAttachmentsCount,
                            videoAttachmentsCount: res.videoAttachmentsCount,
                            audioAttachmentsCount: res.audioAttachmentsCount,
                            documentsCount: res.documentsAttachmentsCount,
                        });
                    const totalCount =
                        filter.mimeTypeCategory ===
                        AttachmentCategoryType.MULTIMEDIA
                            ? mediasCount
                            : filter.mimeTypeCategory ===
                                AttachmentCategoryType.DOCUMENT
                              ? documentsCount
                              : mediasCount + documentsCount;
                    return { ...res, totalCount, mediasCount, documentsCount };
                }),
                map((result) => ({
                    attachmentsList: toAttachmentsList(
                        result.items,
                        result.totalCount,
                        filter.mimeTypeCategory,
                        result.nextPageToken,
                    ),
                    mediasCount: result.mediasCount,
                    documentsCount: result.documentsCount,
                })),
            );
    }

    toManage(
        filters: ManageProcessesFilters,
        pageToken: string | null = null,
        pageSize = 10,
    ): Observable<PaginatedList<ICustomPost>> {
        const body = {
            ...fromManageProcessesFilters(filters),
            pageToken,
            pageSize,
            calculateTotalItemsCount: pageToken == null,
        };
        const params = {
            loadViewLink: 'false',
            loadDownloadLink: 'false',
            loadPreviewImageLink: 'false',
        };
        return this.http
            .post<{
                nextPageToken: string | undefined;
                items: any[];
                totalItemsCount: number;
            }>(`${this.baseUrl}/to-manage-posts`, body, { params })
            .pipe(
                mergeMap((result) =>
                    this.fetchMetadata(result.items).pipe(
                        map((postList) =>
                            toPaginatedList<ICustomPost>({
                                ...result,
                                items: postList,
                            }),
                        ),
                    ),
                ),
            );
    }

    globalStream(
        syncToken?: string,
        pageToken?: string,
    ): Observable<{ posts: IBasePostDifferential[]; nextSyncToken: string }> {
        const params: {
            syncToken?: string;
            pageToken?: string;
            filterByVisibilityPreferences: string;
        } = {
            filterByVisibilityPreferences: 'false',
        };
        if (syncToken) {
            params.syncToken = syncToken;
        }
        if (pageToken) {
            params.pageToken = pageToken;
        }
        return this.http
            .post<{
                items: any[];
                nextSyncToken: any;
            }>(`${this.baseUrl}/global-stream`, {}, { params })
            .pipe(
                concatMap((res) =>
                    this.fetchMetadata(res.items).pipe(
                        map((posts) =>
                            zipWith(res.items, posts, (r: any, p) => ({
                                ...p,
                                status: r.status,
                            })),
                        ),
                        map((posts) => ({
                            posts,
                            nextSyncToken: res.nextSyncToken,
                        })),
                    ),
                ),
            );
    }

    fetchDraftsAndScheduled(
        draftType: DraftType | null,
        pageSize: number | null,
        nextPageToken: string | null,
        communityIds: number[] | null,
    ): Observable<PaginatedList<IBasePost>> {
        return this.http
            .post<{
                items: any[];
                nextPageToken: string | null;
                totalItemsCount: number;
            }>(`${this.baseUrl}/draft-list`, {
                draftType,
                pageSize,
                pageToken: nextPageToken,
                communityIds,
                calculateTotalItemsCount: nextPageToken == null,
            })
            .pipe(
                map((postList) =>
                    toPaginatedList<IBasePost>({
                        ...postList,
                        items: postList.items.map((post) =>
                            PostListDeserialize.postDetails(
                                this.configurationService,
                                { ...post, draft: true },
                            ),
                        ),
                    }),
                ),
            );
    }

    private fetchMetadata(posts: any[]): Observable<IPostItemList[]> {
        return forkJoin(
            posts.map((post: any) =>
                this.communitiesStateService
                    .getPostMetadata(post.communityId)
                    .pipe(
                        map((metadata) =>
                            PostListDeserialize.postDetails(
                                this.configurationService,
                                post,
                                metadata,
                            ),
                        ),
                    ),
            ),
        ).pipe(defaultIfEmpty([]));
    }

    private postItemConcat(
        record: any,
        filter: IPostFilters,
    ): Observable<PostList> {
        const postList = PostListDeserialize.postList(record, filter);

        if (!record || record.items.length === 0) return of(postList);
        return forkJoin(
            (<Array<any>>record.items).map((item) =>
                this.communitiesStateService
                    .getPostMetadata(item.communityId)
                    .pipe(
                        map((metadata: IPostMetadata) => ({
                            ...PostListDeserialize.postDetails(
                                this.configurationService,
                                item,
                                metadata,
                            ),
                            capabilities: isDefined(item.capabilities)
                                ? CustomPostDeserialize.postCapabilities(
                                      item.capabilities,
                                  )
                                : undefined,
                            draft:
                                filter.draftType != null &&
                                [DraftType.DRAFT, DraftType.SCHEDULED].includes(
                                    filter.draftType,
                                ),
                            isScheduled:
                                filter.draftType === DraftType.SCHEDULED,
                        })),
                    ),
            ),
        ).pipe(map((list: IPostItemList[]) => ({ ...postList, list })));
    }

    private getPostPickerPathByType(type: MetadataFieldModeType): string {
        let typePath: string;
        switch (type) {
            case MetadataFieldModeType.POST:
                typePath = 'post-picker';
                break;
            case MetadataFieldModeType.SCREEN:
                typePath = `screen-post-picker`;
                break;
            case MetadataFieldModeType.ACK_TASK:
                typePath = `ack-task-screen-post-picker`;
                break;
            case MetadataFieldModeType.SURVEY:
                typePath = `survey-post-picker`;
                break;
            default:
                typePath = 'post-picker';
                break;
        }

        return typePath;
    }
}
