import { createReducer, on } from '@ngrx/store';
import { HomeSelectorItem } from '../models/home-selector.model';
import { setHomeSelectorItems } from './header.actions';

export const headerFeatureKey = 'header';

export interface HeaderState {
    homeSelectorItems: HomeSelectorItem[];
}

const initialState: HeaderState = {
    homeSelectorItems: [],
};

export const headerReducer = createReducer<HeaderState>(
    initialState,
    on(setHomeSelectorItems, (state, { items }) => ({
        ...state,
        homeSelectorItems: items,
    })),
);
