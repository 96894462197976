<div
    *ngIf="!collapsed"
    class="border-border-primary flex flex-col space-y-4 rounded-r-lg border-l-2 py-4 pl-16 pr-4"
    [ngClass]="{
        'bg-surface-100': bgColor === 'gray-100',
        'bg-surface-300': bgColor === 'gray-300'
    }"
>
    <button class="text-left" type="button" (click)="quoteClick.emit(comment)">
        <p class="typo-xs-bold">
            {{ comment.creatorUser?.fullName || ('UNKNOWN_USER' | translate) }}
        </p>

        <p *ngIf="comment.deleted" class="typo-xs italic">
            {{ 'DASHBOARD.COMMENTS.DELETED' | translate }}
        </p>

        @if (!comment.deleted) {
            @if (comment.isVoiceComment) {
                <interacta-post-comment-voice-message
                    [comment]="comment"
                    [quoted]="true"
                ></interacta-post-comment-voice-message>
            } @else {
                <interacta-delta-view
                    *ngIf="comment.plainText.length > 0"
                    class="typo-xs"
                    injMentionEventHandler
                    [clickable]="true"
                    [delta]="comment.comment"
                    [maxLines]="showingMore ? 0 : 3"
                    (canShowMore)="!showingMore && (canShowMore = $event)"
                ></interacta-delta-view>

                @if (comment.attachments && comment.attachments.length) {
                    <interacta-attachments-counter
                        [attachments]="comment.attachments"
                    ></interacta-attachments-counter>
                }
            }
        }
    </button>
    <interacta-show-more-toggle
        *ngIf="!comment.deleted && canShowMore"
        [arrow]="false"
        [isShowingMore]="showingMore"
        (toggle)="showingMore = !showingMore"
    ></interacta-show-more-toggle>
</div>

<div *ngIf="collapsed" class="flex items-center">
    <interacta-icon class="size-24" [icon]="'replied'"></interacta-icon>

    <span class="typo-xs-bold ml-8">{{
        'DASHBOARD.COMMENTS.COLLAPSED_QUOTE' | translate
    }}</span>
</div>
