<article class="flex flex-col">
    <section
        class="auto-fill grid content-start justify-center"
        [ngClass]="{
            'gap-24': size === 'large',
            'gap-x-56 gap-y-48': size === 'extra-large'
        }"
        [style.--attachment-size]="
            size === 'extra-large' ? '12.5rem' : '9.25rem'
        "
    >
        @for (
            attachment of attachments.attachments;
            let index = $index;
            track attachment.id
        ) {
            <interacta-attachment-card
                class="place-self-center"
                [aiEnabled]="aiEnabled"
                [attachment]="attachment"
                [attachmentType]="attachments.attachmentCategoryType!"
                [canShare]="canShare"
                [customButtons]="customButtons"
                [enableMenu]="enableMenu"
                [enableSelection]="enableSelection"
                [hashtagsAvailable]="hashtagsAvailable"
                [isSelectionActive]="isSelectionActive"
                [preview]="preview"
                [selected]="
                    enableSelection &&
                    (selectionRecord[attachment.id]?.selected || false)
                "
                [selectedPreview]="
                    enableSelection &&
                    (selectionRecord[attachment.id]?.preview || false)
                "
                [showOriginField]="showOriginField"
                [size]="size"
                (delete)="delete.emit(attachment)"
                (download)="download.emit($event)"
                (edit)="edit.emit(attachment)"
                (editHashtags)="editHashtags.emit(attachment)"
                (itemMouseEnter)="itemMouseEnter.emit(attachment)"
                (itemMouseLeave)="itemMouseLeave.emit(attachment)"
                (manageVersion)="manageVersion.emit(attachment)"
                (openAttachment)="
                    openAttachment.emit({
                        index,
                        attachmentId: attachment.id,
                        showInfo: $event
                    })
                "
                (sendToAI)="sendToAI.emit(attachment)"
                (share)="share.emit(attachment)"
                (toggleSelectAttachment)="
                    toggleSelectAttachment.emit({ attachment })
                "
            />
        }
        @if (
            attachments.isLoading &&
            attachments.pageTokenInfo.tag === 'firstLoading'
        ) {
            @for (_ of placeholdersCount | array; track _) {
                <div
                    class="bg-surface-300 animate-pulse place-self-center rounded-lg"
                    [ngClass]="{
                        'size-148': size === 'large',
                        'size-200': size === 'extra-large'
                    }"
                ></div>
            }
        }
    </section>

    @if (attachments.pageTokenInfo.tag === 'regularLoading') {
        <interacta-load-more
            class="self-center"
            [isLoading]="attachments.isLoading"
            [label]="'SHOW_MORE' | translate"
            [ngClass]="{ 'mt-8': preview }"
            [size]="'large'"
            (loadMore)="loadMore.emit()"
        />
    }
</article>
