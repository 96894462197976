import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { Index } from '@interacta-shared/util';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { SelectionRecord } from '@modules/core/services/selection.service';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachments-grid',
    templateUrl: './attachments-grid.component.html',
    styles: [
        `
            .auto-fill {
                grid-template-columns: repeat(
                    auto-fill,
                    var(--attachment-size)
                );
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsGridComponent implements OnChanges {
    @Input({ required: true }) attachments!: IListAttachments;
    @Input() size: Extract<Size, 'extra-large' | 'large'> = 'large';
    @Input() selectionRecord: SelectionRecord = {};
    @Input() enableSelection = true;
    @Input() enableMenu = true;
    @Input() placeholdersCount = 4;
    @Input() customButtons: ICustomButton[] = [];
    @Input() canShare = false;
    @Input() preview = false;
    @Input() hashtagsAvailable = false;
    @Input() aiEnabled = false;
    @Input() showOriginField = false;

    @Output() itemMouseEnter = new EventEmitter<IAttachment>();
    @Output() itemMouseLeave = new EventEmitter<IAttachment>();
    @Output() toggleSelectAttachment = new EventEmitter<{
        attachment: IAttachment;
    }>();
    @Output() download = new EventEmitter<IAttachment>();
    @Output() openAttachment = new EventEmitter<{
        index: number;
        attachmentId: Index;
        showInfo: boolean;
    }>();
    @Output() loadMore = new EventEmitter<void>();
    @Output() edit = new EventEmitter<IAttachment>();
    @Output() editHashtags = new EventEmitter<IAttachment>();
    @Output() delete = new EventEmitter<IAttachment>();
    @Output() manageVersion = new EventEmitter<IAttachment>();
    @Output() share = new EventEmitter<IAttachment>();
    @Output() sendToAI = new EventEmitter<IAttachment>();

    isSelectionActive = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectionRecord) {
            this.isSelectionActive = Object.values(this.selectionRecord).some(
                ({ selected }) => selected,
            );
        }
    }
}
