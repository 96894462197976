<div
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    class="bg-transparent-black-not-black-95 text-text dark fixed inset-0 h-screen w-screen"
    @light-box
>
    <ng-content></ng-content>

    <img
        alt="Interacta Logo"
        class="fixed bottom-20 left-10 w-44"
        [src]="logo"
    />
</div>
