import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'interacta-post-comment-input-footer[uploader]',
    templateUrl: './post-comment-input-footer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentInputFooterComponent {
    @Input() uploader!: FileUploader;
    @Input() postId!: number;
    @Input() isCreatingComment = false;
    @Input() canSaveComment = true;
    @Input() canAddAttachments = true;
    @Input() mode: { tag: 'creation' } | { tag: 'edit'; commentId: number } = {
        tag: 'creation',
    };
    @Output() saveComment = new EventEmitter<number | undefined>();
    @Output() cancel = new EventEmitter<void>();

    save(): void {
        if (this.mode.tag === 'edit') {
            this.saveComment.emit(this.mode.commentId);
        } else {
            this.saveComment.emit();
        }
    }
}
