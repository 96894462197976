import {
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import {
    ManageMentionsAndRepliesFilters,
    ManageProcessesFilters,
    ManageTasksFilters,
    ManageToMonitorTasksFilters,
    MentionedAsType,
} from './manage.model';

export const fromManageProcessesFilters = (
    filters: ManageProcessesFilters,
): any => ({
    containsText: filters.containsText,
    //Commented in IISP-6031 because problems with IISP-5912
    //assignedOnlyToMe: filters.assignedOnlyToMe,
    communityIds: filters.community ? [filters.community.id] : null,
    currentWorkflowStatusIds: filters.states?.length
        ? filters.states.map((state) => state.id)
        : null,
    orderBy: filters.orderBy,
    orderDesc: filters.orderDesc,
});

export const fromManageTasksFilters = (filters: ManageTasksFilters): any => ({
    containsText: filters.containsText,
    assignedOnlyToMe: filters.assignedOnlyToMe,
    communityId: filters.community?.id,
    orderBy: filters.orderBy,
    orderDesc: filters.orderDesc,
});

export const fromManageToMonitorTasksFilters = (
    filters: ManageToMonitorTasksFilters,
): any => {
    const assignedToUserIds =
        filters.members?.filter(isMemberUser).map((member) => member.innerId) ??
        [];
    const assignedToGroupIds =
        filters.members
            ?.filter(isMemberGroup)
            .map((member) => member.innerId) ?? [];

    return {
        containsText: filters.containsText,
        communityIds: filters.community?.id ? [filters.community.id] : null,
        assignedToUserIds,
        assignedToGroupIds,
        orderBy: filters.orderBy,
        orderDesc: filters.orderDesc,
    };
};

export const fromManageMentionsAndRepliesFilters = (
    filters?: ManageMentionsAndRepliesFilters,
): any => {
    if (!filters)
        return {
            types: [],
            communityIds: [],
            authorMentionIds: [],
            mentionedAsTypes: [],
            groupIds: [],
            containsText: null,
        };

    let userId: number | null = null;
    const groupIds: number[] = [];

    if (filters.recipients) {
        filters.recipients.forEach((t) => {
            if (isMemberUser(t)) userId = t.innerId;
            else groupIds.push(t.innerId);
        });
    }

    const mentionedAsTypes: MentionedAsType[] = [];

    if (userId) mentionedAsTypes.push(MentionedAsType.USER);
    if (groupIds.length) mentionedAsTypes.push(MentionedAsType.GROUP);

    return {
        types: filters.mentionType ? [filters.mentionType] : [],
        communityIds: filters.community ? [filters.community.id] : [],
        authorMentionIds: filters.authors
            ? filters.authors.map((a) => a.innerId)
            : [],
        mentionedAsTypes,
        groupIds,
        containsText: filters.containsText,
    };
};
