import { bytesConverter } from '@interacta-shared/util-common';
import {
    AttachmentStorageType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import {
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';
import {
    AIContentGenerationType,
    AIToneOfVoiceType,
    supportedAttachmentMimeTypes,
} from '../models/ai.model';

export function isAttachmentSupported(attachment: IAttachment): boolean {
    const MAX_MEDIA_SIZE_MB = 200;
    const MAX_FILE_SIZE_MB = 30;
    return (
        supportedAttachmentMimeTypes.includes(attachment.contentMimeType) &&
        attachment.type !== AttachmentStorageType.DRIVE &&
        ((isMediaAttachment(attachment) &&
            bytesConverter(attachment.size, undefined, -2) <
                MAX_MEDIA_SIZE_MB) ||
            (isFileAttachment(attachment) &&
                bytesConverter(attachment.size, undefined, -2) <
                    MAX_FILE_SIZE_MB))
    );
}

export function getContentGenerationTypeLabel(
    action: AIContentGenerationType,
): string {
    const baseLabel = 'AI.CONTENT_GENERATION.ACTIONS.';
    return `${baseLabel}${AIContentGenerationType[action]}`;
}

export function getToneOfVoiceTypeLabel(tone: AIToneOfVoiceType): string {
    const baseLabel = 'AI.CONTENT_GENERATION.TONES_OF_VOICE.';
    return `${baseLabel}${tone}`;
}
