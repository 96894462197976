import { BidiModule } from '@angular/cdk/bidi';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';
import { Palette } from '../../model';
import { ApplyPipe } from '../../pipes/apply.pipe';
import { ResizeElementService } from '../../services';

/**
 * bgClass is used to partially hide the text with its tail part, when owerflow happens.
 */
@Component({
    selector: 'interacta-inner-ellipses-text',
    templateUrl: './inner-ellipses-text.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, BidiModule, AsyncPipe, ApplyPipe],
})
export class InnerEllipsesTextComponent
    implements OnDestroy, AfterViewInit, OnChanges
{
    @Input({ required: true }) text!: string;
    @Input() bgColor: Extract<
        Palette,
        'surface-A' | 'surface-B' | 'surface-C' | 'surface-primary-low-contrast'
    > = 'surface-B';
    @Input() textNgClass?: { [klass: string]: any } | undefined;
    @ViewChild('resizableContainer', { static: true })
    resizableContainer!: ElementRef<HTMLElement>;
    @ViewChild('fullText', { static: true })
    fullText!: ElementRef<HTMLElement>;

    textOverflow$!: Observable<boolean>;

    private _textOverflow = new BehaviorSubject(false);

    private destroy$ = new Subject<void>();

    constructor(private resizeElementService: ResizeElementService) {
        this.textOverflow$ = this._textOverflow.pipe(distinctUntilChanged());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['text'] && !changes['text'].firstChange) {
            this._textOverflow.next(this.getTextOverflow());
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() =>
            this.resizeElementService
                .observeElement(this.resizableContainer.nativeElement)
                .pipe(
                    takeUntil(this.destroy$),
                    debounceTime(100),
                    map(() => this.getTextOverflow()),
                )
                .subscribe((textOverflow) =>
                    this._textOverflow.next(textOverflow),
                ),
        );
    }

    merge(objects: Array<{ [klass: string]: any } | undefined>): {
        [klass: string]: any;
    } {
        return objects
            .filter(isDefined)
            .reduce((result, object) => ({ ...result, ...object }), {});
    }

    private getTextOverflow(): boolean {
        const fullLabelWidth = this.fullText.nativeElement.offsetWidth;
        const availableWidth =
            this.resizableContainer.nativeElement.clientWidth;
        return availableWidth - fullLabelWidth < 0;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
