<div
    *ngIf="{
        hoverError: hoverError$ | async,
        hoverRow: hoverRow$ | async
    } as $"
    class="flex flex-col space-y-4"
    (mouseenter)="hoverRow$.next(true)"
    (mouseleave)="hoverRow$.next(false)"
>
    <div class="flex items-center justify-between">
        <div
            *ngIf="version.inPending && !version.drive"
            class="flex min-w-0 items-center space-x-8"
        >
            <interacta-image
                classes="w-24 h-24 shrink-0"
                alt="{{ version.name }}'s icon"
                [src]="version.iconPath || ''"
            ></interacta-image>

            <interacta-input-text-v2 [control]="control">
            </interacta-input-text-v2>
        </div>

        <a
            *ngIf="!version.inPending || version.drive"
            class="flex min-w-0 items-center space-x-8"
            target="_blank"
            [attr.href]="
                version.drive?.webViewLink ??
                version.temporaryContentViewLink ??
                version.temporaryContentDownloadLink
            "
            [ngClass]="{
                'opacity-50': version.inDeleting
            }"
        >
            <interacta-image
                classes="w-24 h-24"
                alt="{{ version.name }}'s icon"
                [src]="version.iconPath || ''"
            ></interacta-image>
            <span
                class="typo-medium-bold min-w-0 break-words"
                [ngClass]="{ 'line-through': version.inDeleting }"
            >
                {{ version.name || '' }}
            </span>
            <span
                *ngIf="version.isCurrentVersion"
                class="typo-medium-bold shrink-0"
                [ngClass]="{
                    'line-through': version.inDeleting
                }"
            >
                {{ 'DASHBOARD.ATTACHMENTS.CURRENT_VERSION' | translate }}
            </span>
        </a>

        <div class="ml-12 flex shrink-0 items-center space-x-12">
            <ng-container
                *ngIf="
                    version.uploadProgress && version.uploadProgress < 100;
                    else notInProgress
                "
            >
                <interacta-progress-loader
                    *ngIf="!$.hoverRow"
                    [matTooltip]="version.uploadProgress + '%'"
                    [progress]="version.uploadProgress"
                    [size]="'small'"
                ></interacta-progress-loader>
                <button
                    *ngIf="$.hoverRow"
                    class="bg-surface-100 rounded-full"
                    interacta-icon-button
                    type="button"
                    [icon]="'close'"
                    [matTooltip]="'UPLOAD.CANCEL_UPLOAD' | translate"
                    [size]="'small'"
                    (click)="cancelUpload.emit()"
                ></button>
            </ng-container>

            <ng-template #notInProgress>
                <interacta-creation-date
                    class="text-text-mid-contrast typo-xs"
                    [date]="version.creationTimestamp ?? now"
                >
                </interacta-creation-date>

                <button
                    *ngIf="version.inPending"
                    interacta-icon-button
                    type="button"
                    [icon]="'trash-bin'"
                    (click)="removeVersion.emit()"
                ></button>
            </ng-template>
        </div>
    </div>

    <interacta-checkbox
        *ngIf="version.inPending && !version.drive"
        [checked]="useVersionNameForAttachment"
        [size]="'small'"
        (changeCheck)="changeCheck.emit($event === true)"
    >
        <span class="typo-sm">
            {{ 'DASHBOARD.ATTACHMENTS.VERSIONS.USE_VERSION_NAME' | translate }}
        </span>
    </interacta-checkbox>

    <div class="text-text-mid-contrast flex items-center space-x-8">
        <span
            *ngIf="!version.isPartial"
            class="typo-sm"
            [ngClass]="{
                'text-text-primary': version.inPending
            }"
        >
            {{
                version.inPending
                    ? ('DASHBOARD.ATTACHMENTS.CURRENT_VERSION_WILL_BE_MAIN'
                      | translate)
                    : version.creatorUser?.fullName
            }}
        </span>
        <span *ngIf="!version.isPartial" class="typo-xs">
            ({{ version.size ?? 0 | formatBytes }}, {{ mimeType }})
        </span>
    </div>
</div>
