<div class="flex flex-col space-y-4">
    <div
        *ngIf="{
            hoverError: hoverError$ | async,
            hoverRow: hoverRow$ | async
        } as $"
        class="h-30 flex max-w-max items-center space-x-8 rounded-3xl pl-16 pr-8"
        [ngClass]="{
            'bg-surface-A': bgColor === 'surface-A',
            'bg-surface-B': bgColor === 'surface-B',
            'ring-border-primary ring-1': selected
        }"
        (mouseenter)="hoverRow$.next(true)"
        (mouseleave)="hoverRow$.next(false)"
    >
        <a
            class="flex min-w-0 items-center space-x-8"
            target="_blank"
            [attr.href]="
                attachment.drive?.webViewLink ??
                attachment.temporaryContentViewLink ??
                attachment.temporaryContentDownloadLink
            "
            [ngClass]="{
                'opacity-50': attachment.inDeleting
            }"
        >
            <interacta-image
                classes="w-24 h-24"
                alt="{{ attachment.name }}'s icon"
                [src]="attachment.iconPath || ''"
            ></interacta-image>
            <interacta-inner-ellipses-text
                class="typo-sm min-w-0"
                [bgColor]="bgColor"
                [matTooltip]="attachment.name"
                [text]="attachment.name ?? ''"
                [textNgClass]="{
                    'line-through': attachment.inDeleting
                }"
            ></interacta-inner-ellipses-text>
        </a>

        <div class="flex shrink-0 items-center">
            <ng-container
                *ngIf="
                    attachment.uploadProgress &&
                        attachment.uploadProgress < 100;
                    else notInProgress
                "
            >
                <interacta-progress-loader
                    *ngIf="!$.hoverRow"
                    [matTooltip]="attachment.uploadProgress + '%'"
                    [progress]="attachment.uploadProgress"
                    [size]="size"
                ></interacta-progress-loader>
                <button
                    *ngIf="$.hoverRow"
                    class="bg-surface-100 rounded-full"
                    interacta-icon-button
                    type="button"
                    [icon]="'close'"
                    [matTooltip]="'UPLOAD.CANCEL_UPLOAD' | translate"
                    [size]="size"
                    (click)="cancelUpload.emit()"
                ></button>
            </ng-container>

            <ng-template #notInProgress>
                <div
                    *ngIf="attachment.isLoadingError"
                    (mouseenter)="hoverError$.next(true)"
                    (mouseleave)="hoverError$.next(false)"
                >
                    <button
                        class="text-text-error"
                        interacta-icon-button
                        type="button"
                        [icon]="$.hoverError ? 'retry' : 'warning'"
                        [matTooltip]="'UPLOAD.UPLOAD_ERROR_RETRY' | translate"
                        [size]="size"
                        (click)="retryUpload.emit()"
                    ></button>
                </div>

                <interacta-icon
                    *ngIf="attachment.drive"
                    class="mr-4 h-20 w-20"
                    icon="google-drive"
                    [attr.href]="attachment.drive.webViewLink"
                    matTooltip="{{
                        'DASHBOARD.ATTACHMENTS.SOURCE'
                            | translate
                                : {
                                      source:
                                          'DASHBOARD.ATTACHMENTS.SOURCES.GOOGLE_DRIVE'
                                          | translate
                                  }
                    }}"
                ></interacta-icon>

                <!-- Sulla legacy si controlla anche che non sia una copia di un post -->
                <button
                    *ngIf="
                        allowAddVersion && attachment
                            | apply: canAddVersionAttachment
                    "
                    class="text-text"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        ((attachment.drive && driveEnabled) || !attachment.drive
                            ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                            : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
                        ) | translate
                    "
                    [disabled]="attachment.isPartial"
                    [icon]="'history-clock'"
                    [matTooltip]="
                        ((attachment.drive && driveEnabled) || !attachment.drive
                            ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                            : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
                        ) | translate
                    "
                    [size]="size"
                    (click)="manageVersion.emit()"
                ></button>
                <button
                    *ngIf="attachment.inDeleting"
                    class="text-text"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.COMMENTS.RESTORE_ATTACHMENT' | translate
                    "
                    [icon]="'retry'"
                    [matTooltip]="
                        'DASHBOARD.COMMENTS.RESTORE_ATTACHMENT' | translate
                    "
                    [size]="size"
                    (click)="restoreAttachment.emit()"
                ></button>
                <div *ngIf="attachment.isCanceled" class="typo-xs shrink-0">
                    {{ 'UPLOAD.UPLOAD_CANCELED' | translate }}
                </div>

                <button
                    *ngIf="
                        allowEdit &&
                        ({ attachment, hashtagsAvailable }
                            | apply: canEditHashtags)
                    "
                    class="text-text"
                    icon="tag"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate
                    "
                    [disabled]="attachment.isPartial"
                    [matTooltip]="
                        'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate
                    "
                    [size]="size"
                    (click)="editHashtags.emit()"
                ></button>
                <button
                    *ngIf="allowEdit && (attachment | apply: canEditAttachment)"
                    class="text-text"
                    icon="pen-pad"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.ATTACHMENTS.RENAME' | translate
                    "
                    [disabled]="attachment.isPartial"
                    [matTooltip]="'DASHBOARD.ATTACHMENTS.RENAME' | translate"
                    [size]="size"
                    (click)="editAttachment.emit()"
                ></button>
                <button
                    *ngIf="
                        allowDelete && attachment | apply: canDeleteAttachment
                    "
                    class="text-text"
                    icon="close"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.COMMENTS.REMOVE_ATTACHMENT' | translate
                    "
                    [disabled]="attachment.isPartial"
                    [matTooltip]="
                        'DASHBOARD.COMMENTS.REMOVE_ATTACHMENT' | translate
                    "
                    [size]="size"
                    (click)="removeAttachment.emit()"
                ></button>
            </ng-template>
        </div>
    </div>

    <div
        *ngIf="
            (attachment &&
                attachment.versionNumber &&
                attachment.versionNumber > 1) ||
            (attachment.hashtags && attachment.hashtags.length)
        "
        class="flex flex-wrap gap-8"
    >
        <span
            *ngIf="attachment.versionNumber && attachment.versionNumber > 1"
            class="typo-xs"
        >
            {{
                'DASHBOARD.ATTACHMENTS.VERSIONS.NUMBER'
                    | translate: { number: attachment.versionNumber }
            }}
        </span>

        <interacta-hashtag-chip
            *ngFor="let hashtag of attachment.hashtags"
            [label]="'#' + hashtag.name"
        ></interacta-hashtag-chip>
    </div>
</div>
