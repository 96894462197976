import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PostViewService {
    triggerShadow$ = new Subject<{ postId: number }>();

    triggerShadow(postId: number): void {
        this.triggerShadow$.next({ postId });
    }

    getTriggerShadow$(id: number): Observable<void> {
        return this.triggerShadow$.asObservable().pipe(
            filter(({ postId }) => id === postId),
            mapTo(void 0),
        );
    }
}
