import { PageTokenInfo } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import {
    IWorkflowDefinitionForFilter,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import { Member } from '@modules/core/models/member/member.model';
import { DashboardPost } from '@modules/dashboard/models/dashboard-post.model';
import { StandardTask } from '@modules/tasks/models/task.model';

export enum ManageSectionType {
    DRAFTS_AND_SCHEDULED = 'draft_and_scheduled',
    MENTIONS_AND_REPLIES = 'mentions_and_reply',
    PROCESSES = 'processes',
    TASK = 'task',
}

export enum TaskTypes {
    ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
    TO_MONITOR = 'TO_MONITOR',
}

export enum TaskTabs {
    ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
    TO_MONITOR = 'TO_MONITOR',
    CLOSED_TO_MONITOR = 'CLOSED_TO_MONITOR',
}

export interface ManageSection {
    id: ManageSectionType;
    imgPath: string;
    redirectUrl: string;
    label: string;
    info: string;
    background: string;
    counter: number | undefined;
}

export interface ScheduledPostList {
    lists: {
        error: DashboardPost[];
        today: DashboardPost[];
        week: DashboardPost[];
        future: DashboardPost[];
    };
    totalCount: number;
    nextPageToken: PageTokenInfo;
    isFetching: boolean;
}

export type CommunityFilterItem = Pick<ICommunity, 'id' | 'name'>;
export type WorkflowStateFilterItem = Pick<IWorkflowStatus, 'id' | 'name'> & {
    communityId: number;
};
export type IWorkflowDefinitionForManageFilter =
    IWorkflowDefinitionForFilter & {
        communityId: number;
    };

export const manageProcessesOrderByValues = [
    'lastTransitionTimestamp',
    'creationTimestamp',
    'title',
    'customId',
] as const;

export type ManageProcessesOrderBy =
    (typeof manageProcessesOrderByValues)[number];

export const manageTasksOrderByValues = [
    'expiration',
    'publicationTimestamp',
    'title',
    'priority',
    'postTitle',
] as const;

export type ManageTasksOrderBy = (typeof manageTasksOrderByValues)[number];

export enum MentionFilterType {
    MENTION = 1,
    REPLY = 2,
}

export enum MentionedAsType {
    USER = 1,
    GROUP = 2,
}

export interface ManageMentionsAndRepliesFilters {
    containsText: string | null;
    mentionType: MentionFilterType | null;
    community: CommunityFilterItem | null;
    authors: Member[];
    recipients: Member[];
}

export interface ManageProcessesFilters {
    containsText: string | null;
    assignedOnlyToMe: boolean;
    community: CommunityFilterItem | null;
    states: WorkflowStateFilterItem[] | null;
    orderBy: ManageProcessesOrderBy;
    orderDesc: boolean;
}

export interface ManageTasksFilters {
    containsText: string | null;
    assignedOnlyToMe: boolean;
    community: CommunityFilterItem | null;
    orderBy: ManageTasksOrderBy;
    orderDesc: boolean;
}

export interface ManageToMonitorTasksFilters {
    containsText: string | null;
    community: CommunityFilterItem | null;
    members: Member[];
    orderBy: ManageTasksOrderBy;
    orderDesc: boolean;
}

export interface ManageDraftsFilters {
    community: CommunityFilterItem | null;
}

export interface ManageFiltersBanner {
    community: CommunityFilterItem | null;
    assignedOnlyToMe?: boolean;
    containsText?: string | null;
    states?: WorkflowStateFilterItem[] | null;
    mentionType?: MentionFilterType | null;
    authors?: Member[];
    recipients?: Member[];
}

export interface ManageCommunitySummary extends CommunityFilterItem {
    count: number;
}

export const WIDGETS_LISTS_MAX_LENGTH = 6;

export interface ManageTask extends StandardTask {
    id: number;
    postId: number;
    postTitle: string | null;
    postCustomId: string | null;
    communityId: number;
}

export type DashboardManageTask = ManageTask & { community: ICommunity };
export type ManageTaskSrcListType = 'expired' | 'open' | 'closed';

export interface ManageMentionsAndRepliesFilters {
    containsText: string | null;
    mentionType: MentionFilterType | null;
    community: CommunityFilterItem | null;
    authors: Member[];
    recipients: Member[];
}
