import { VoiceCommentPlaybackRateType } from '@modules/post/models/comment.model';
import { createAction, props } from '@ngrx/store';

export const setVoiceCommentsPlaybackRate = createAction(
    '[Comments] Set Voice Comments Playback Rate',
    props<{
        rate: VoiceCommentPlaybackRateType;
    }>(),
);

export const setVoiceCommentsCurrentlyPlaying = createAction(
    '[Comments] Set Voice Comments Currently Playing',
    props<{
        id: string | null;
    }>(),
);
