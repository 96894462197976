import {
    IScreen,
    IScreenContext,
} from '@modules/communities/models/screen.model';
import { QuillText } from '@modules/core';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { ICustomPostForContext } from '../custom-post.model';

export interface ListenChangesChange {
    fieldId: number;
    change: any;
}

export interface ListenChangesCustomPostData {
    context: ICustomPostForContext;
    changedFieldId: number;
}

export interface ListenChangesScreenData {
    screen: IScreen;
    context: IScreenContext;
    changedScreenFieldId: number;
}

export interface OnChangeMessage {
    message: string | QuillText;
    level: OnChangeMessageLevel;
    messageFormat: MessageInfoFormat;
}

export enum MessageInfoFormat {
    DELTA = 1,
    PLAINTEXT,
}

export enum OnChangeMessageLevel {
    ERROR = 0,
    WARNING,
    INFO,
    SUCCESS,
}

export class ListenChangesDeserialize {
    public static formatMessage = (
        messageInfo: OnChangeMessage | null,
    ): OnChangeMessage | null => {
        return messageInfo
            ? {
                  ...messageInfo,
                  message:
                      messageInfo.messageFormat === MessageInfoFormat.DELTA
                          ? new Server2Delta().process(
                                messageInfo.message as string,
                            )
                          : messageInfo.message,
              }
            : null;
    };
}
