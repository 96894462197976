<div
    *ngIf="{
        showMoreCount: attachmentsList.totalCount - showingCount
    } as $"
    class="flex flex-col space-y-8"
>
    <div
        class="flex"
        injShowMoreChildren
        [active]="orientation === 'row'"
        [ngClass]="{
            'flex-col gap-8': orientation === 'column',
            'flex-row space-x-8': orientation === 'row'
        }"
        (showMore)="onHiddenElements($event)"
    >
        <ng-container *ngIf="canOpenDocument; else notClickableChip">
            <button
                *ngFor="
                    let attachment of attachmentsList.attachments
                        | slice: 0 : showingCount;
                    let idx = index
                "
                class="rounded-3xl"
                injShowMoreItem
                type="button"
                [ngClass]="{
                    'max-w-max': orientation === 'column',
                    'min-w-136': orientation === 'row'
                }"
                (click)="openDocument.emit({ index: idx })"
            >
                <interacta-attachment-chip
                    [attachment]="attachment"
                    [bgColor]="bgColor"
                    [showVersionIcon]="canShowVersion"
                    (hashtagIconClick)="hashtagIconClick.emit()"
                    (versionIconClick)="versionIconClick.emit(attachment)"
                ></interacta-attachment-chip>
            </button>
        </ng-container>

        <ng-template #notClickableChip>
            <div
                *ngFor="
                    let attachment of attachmentsList.attachments
                        | slice: 0 : showingCount;
                    let idx = index
                "
                class="rounded-3xl"
                injShowMoreItem
                [ngClass]="{
                    'max-w-max': orientation === 'column',
                    'min-w-136': orientation === 'row'
                }"
            >
                <interacta-attachment-chip
                    [attachment]="attachment"
                    [bgColor]="bgColor"
                ></interacta-attachment-chip>
            </div>
        </ng-template>

        <button
            *ngIf="orientation === 'row'"
            bgColor="surface-B"
            class="shrink-0"
            injShowMoreButton
            interacta-chip-show-more
            type="button"
            [counter]="showMoreCount"
            (click)="
                openDocument.emit({
                    index: attachmentsList.attachments.length - showMoreCount
                })
            "
        ></button>
        <button
            *ngIf="
                orientation !== 'row' &&
                canShowMore &&
                (expandable ||
                    attachmentsList.pageTokenInfo.tag !== 'firstLoading')
            "
            bgColor="surface-B"
            interacta-chip-show-more
            type="button"
            [counter]="attachmentsList.totalCount - showingCount"
            (click)="requestShowMore()"
        ></button>
    </div>
</div>
