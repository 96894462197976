import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-attachments-no-result',
    templateUrl: './attachments-no-result.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsNoResultComponent {
    @Input() title = 'DASHBOARD.ATTACHMENTS.NO_ATTACHMENTS_RESULT_1';
    @Input() subtitle = 'UI.NO_RESULT_HINT';
    @Input() imageName: 'no-results' | 'empty-page' = 'no-results';
}
