import { EMPTY_SEARCH_VALUE } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import {
    ICustomFieldDefinition,
    ICustomFieldMetadata,
    IFieldMetadataScreen,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { PostType, VisibilityType } from '../base-post.model';
import { ICustomPost } from '../custom-post.model';

export type IPostLink = Pick<
    ICustomPost,
    | 'id'
    | 'communityId'
    | 'type'
    | 'tag'
    | 'code'
    | 'title'
    | 'currentWorkflowState'
    | 'visibility'
> & { deleted: boolean; announcement: boolean };

export type IPostLinkNoCommunity = Omit<IPostLink, 'communityId'> &
    Partial<Pick<IPostLink, 'communityId'>>;

export interface ILinkedBy {
    community: ICommunity;
    postLinkFieldDefinitions?: ICustomFieldDefinition[];
    screenLinkFieldDefinitions?: IFieldMetadataScreen[];
    defaultMetadata?: ICustomFieldMetadata;
}

export type IEditPostLinkSourcePost = Pick<
    ICustomPost,
    'id' | 'occToken' | 'title'
>;

export interface IEditPostLinksResponse {
    linksSuccess: IEditPostLinkSourcePost[];
    linksErrorValidation: IEditPostLinkSourcePost[];
    linksErrorConcurrency: IEditPostLinkSourcePost[];
}

export const getEmptyPostLinkPlaceholder = (): IPostLink => {
    return {
        id: EMPTY_SEARCH_VALUE,
        deleted: false,
        type: PostType.CUSTOM,
        tag: PostType.CUSTOM,
        title: 'DASHBOARD.FILTERS.NOT_ASSIGNED',
        visibility: VisibilityType.PUBLIC,
        announcement: false,
    } as IPostLink;
};
