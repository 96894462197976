import { fetchWidgetPostsSuccess } from '@modules/digital-workplace-shared/store/digital-workplace.api-actions';
import { DigitalWorkplaceDefinition } from '@modules/digital-workplace/models/digital-workplace.model';
import { isPostListWidget } from '@modules/digital-workplace/models/digital-workplace.utils';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import {
    clear,
    fetchHomeDefinitions,
    fetchHomeDefinitionsSuccess,
    invalidate,
    markHomeAsViewedSuccess,
} from './home-pages.actions';

export const homePagesFeatureKey = 'homePages';

export interface HomePagesFeatureState {
    homeDefinitions: Record<number, DigitalWorkplaceDefinition> | undefined;
    homeDefinitionsOrder: number[] | undefined;
    stale: boolean;
}

const initialState: HomePagesFeatureState = {
    homeDefinitions: undefined,
    homeDefinitionsOrder: undefined,
    stale: false,
};

export const homePagesReducer = createReducer<HomePagesFeatureState>(
    initialState,

    on(fetchHomeDefinitions, (state) => {
        if (state.stale) {
            return initialState;
        } else {
            return state;
        }
    }),

    on(fetchHomeDefinitionsSuccess, (state, { homeDefinitions }) => ({
        ...state,
        homeDefinitions: homeDefinitions.reduce(
            (registry, homeDefinition) => ({
                ...registry,
                [homeDefinition.id]: homeDefinition,
            }),
            {} as Record<number, DigitalWorkplaceDefinition>,
        ),
        homeDefinitionsOrder: homeDefinitions.map(
            (homeDefinition) => homeDefinition.id,
        ),
        stale: false,
    })),

    on(markHomeAsViewedSuccess, (state, { id, viewedTimestamp }) => {
        if (state.homeDefinitions && state.homeDefinitions[id]) {
            return {
                ...state,
                homeDefinitions: {
                    ...state.homeDefinitions,
                    [id]: { ...state.homeDefinitions[id], viewedTimestamp },
                },
            };
        }
        return state;
    }),

    on(clear, () => initialState),

    on(invalidate, (state) => ({ ...state, stale: true })),
    on(fetchWidgetPostsSuccess, (state, { widgetId, posts, homeId }) =>
        produce(state, (draft) => {
            if (homeId) {
                const homeDefinition = draft.homeDefinitions?.[homeId];

                if (homeDefinition) {
                    const widget = homeDefinition.widgets.find(
                        (w) => w.id === widgetId,
                    );

                    if (widget && isPostListWidget(widget)) {
                        widget.empty = posts.list.length === 0;
                    }
                }
            }
        }),
    ),
);
