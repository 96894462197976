<interacta-dialog
    *ngIf="{
        isRename: editMode === 'rename' && attachments.length === 1,
        isHashtag: editMode === 'hashtags' && hashtags?.length
    } as $"
    size="large"
    [closeOnBackdropClick]="false"
    [isOpen]="isDialogOpen"
    (closing)="closeDialog.emit()"
>
    <div class="typo-t6 flex" title>
        {{
            ($.isRename
                ? 'DASHBOARD.ATTACHMENTS.RENAME_ATTACHMENT'
                : 'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS'
            ) | translate
        }}
    </div>
    <interacta-dialog-description>
        {{
            attachments.length
                | i18nPlural
                    : {
                          '=1': $.isRename
                              ? 'DASHBOARD.ATTACHMENTS.EDIT_ATTACHMENT_SUBTITLE'
                              : 'DASHBOARD.ATTACHMENTS.EDIT_ATTACHMENT_SUBTITLE_HASHTAG',
                          other: 'DASHBOARD.ATTACHMENTS.MASSIVE_HASHTAGS_APPLICATION_SUBTITLE'
                      }
                | translate: { counter: attachments.length }
        }}
    </interacta-dialog-description>
    <div class="flex flex-col space-y-16 overflow-y-auto" content>
        <interacta-form-field-v2
            *ngIf="$.isRename"
            [control]="nameControl"
            [name]="'DASHBOARD.ATTACHMENTS.NAME' | translate"
        >
            <interacta-input-text-v2
                [control]="nameControl"
            ></interacta-input-text-v2>
        </interacta-form-field-v2>

        <interacta-form-field-v2
            *ngIf="$.isHashtag"
            [control]="hashtagControl"
            [name]="'DASHBOARD.ATTACHMENTS.HASHTAG' | translate"
        >
            <interacta-hashtag-select
                [control]="hashtagControl"
                [hashtags]="hashtags"
                [occurrences]="occurrences"
            ></interacta-hashtag-select>
        </interacta-form-field-v2>
    </div>

    <interacta-dialog-buttons>
        <button
            appearance="ghost"
            interacta-dialog-button
            type="button"
            [bgColor]="'surface-A'"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="closeDialog.emit()"
        ></button>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [disabled]="nameControl.invalid && $.isRename"
            [label]="'BUTTON.LABEL_BUTTON_SAVE' | translate"
            (click)="$.isRename ? saveName() : saveHashtags()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
