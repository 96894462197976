<div
    *ngIf="{
        preview: attachment | apply: getPreview,
        hoverError: hoverError$ | async,
        hoverRow: hoverRow$ | async
    } as $"
    class="relative"
    (mouseenter)="itemMouseEnter.emit(); hoverRow$.next(true)"
    (mouseleave)="itemMouseLeave.emit(); hoverRow$.next(false)"
>
    <a
        target="_blank"
        [attr.href]="
            attachment.drive?.webViewLink ?? attachment.temporaryContentViewLink
        "
        [ngClass]="{
            'opacity-50': attachment.inDeleting
        }"
    >
        <interacta-image
            *ngIf="$.preview"
            alt="{{ attachment.name }}'s preview image"
            [classes]="'break-inside-avoid h-200 w-auto max-w-588 rounded-2xl'"
            [fallback]="attachment | apply: srcFallback"
            [src]="$.preview"
            (imgLoad)="previewLoaded = true"
        ></interacta-image>
        <interacta-attachment-preview
            *ngIf="!$.preview"
            size="extra-large"
            [attachment]="attachment"
        ></interacta-attachment-preview>
        <div
            *ngIf="selected || selectedPreview || $.hoverRow || hoverCheck"
            class="absolute inset-0 rounded-2xl"
            @selectionOverlay
            [ngClass]="{
                'bg-transparent-primary ring-border-primary ring-4': selected,
                'bg-transparent-black-not-black-40':
                    !selected && (selectedPreview || $.hoverRow || hoverCheck)
            }"
        ></div>
        <interacta-attachment-feedback-highlight
            *ngIf="previewLoaded || !$.preview"
            class="absolute inset-0"
            [attachment]="attachment"
        ></interacta-attachment-feedback-highlight>
    </a>
    <ng-container *ngIf="previewLoaded || !$.preview">
        <button
            *ngIf="isSelectionActive"
            class="absolute inset-0 w-full"
            type="button"
            [attr.aria-label]="
                (hoverCheck && selected
                    ? 'DASHBOARD.ATTACHMENTS.UNSELECT'
                    : 'DASHBOARD.ATTACHMENTS.SELECT'
                ) | translate
            "
            (click)="toggleSelectAttachment.emit(); $event.stopPropagation()"
        ></button>
        <div
            *ngIf="!isSelectionActive"
            class="text-text absolute right-8 top-8 flex gap-4"
        >
            <ng-container
                *ngIf="
                    attachment.uploadProgress &&
                        attachment.uploadProgress < 100;
                    else notInProgress
                "
            >
                <interacta-progress-loader
                    *ngIf="!$.hoverRow"
                    [matTooltip]="attachment.uploadProgress + '%'"
                    [progress]="attachment.uploadProgress"
                ></interacta-progress-loader>
                <button
                    *ngIf="$.hoverRow"
                    class="bg-surface-100 rounded-full"
                    interacta-icon-button
                    size="small"
                    type="button"
                    [icon]="'close'"
                    [matTooltip]="'UPLOAD.CANCEL_UPLOAD' | translate"
                    (click)="cancelUpload.emit()"
                ></button>
            </ng-container>
            <ng-template #notInProgress>
                <div
                    *ngIf="attachment.isLoadingError"
                    (mouseenter)="hoverError$.next(true)"
                    (mouseleave)="hoverError$.next(false)"
                >
                    <button
                        class="text-text-error"
                        interacta-icon-button
                        size="small"
                        type="button"
                        [icon]="$.hoverError ? 'retry' : 'warning'"
                        [matTooltip]="'UPLOAD.UPLOAD_ERROR_RETRY' | translate"
                        (click)="retryUpload.emit()"
                    ></button>
                </div>
                <a
                    *ngIf="attachment.drive"
                    icon="google-drive"
                    interacta-icon-button
                    size="small"
                    target="_blank"
                    [attr.href]="attachment.drive.webViewLink"
                    [bgColor]="'surface-A'"
                ></a>
                <button
                    *ngIf="
                        allowAddVersion &&
                        (attachment | apply: canAddVersionAttachment)
                    "
                    class="text-text"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        ((attachment.drive && driveEnabled) || !attachment.drive
                            ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                            : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
                        ) | translate
                    "
                    [disabled]="attachment.isPartial"
                    [icon]="'history-clock'"
                    [matTooltip]="
                        ((attachment.drive && driveEnabled) || !attachment.drive
                            ? 'DASHBOARD.ATTACHMENTS.VERSIONS.MANAGE'
                            : 'DASHBOARD.ATTACHMENTS.VERSIONS.CONSULT'
                        ) | translate
                    "
                    [size]="'small'"
                    (click)="manageVersion.emit()"
                ></button>
                <button
                    *ngIf="attachment.inDeleting"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.COMMENTS.RESTORE_ATTACHMENT' | translate
                    "
                    [bgColor]="'surface-A'"
                    [icon]="'retry'"
                    [matTooltip]="
                        'DASHBOARD.COMMENTS.RESTORE_ATTACHMENT' | translate
                    "
                    [size]="'small'"
                    (click)="restoreAttachment.emit()"
                ></button>
                <div *ngIf="attachment.isCanceled" class="typo-xs shrink-0">
                    {{ 'UPLOAD.UPLOAD_CANCELED' | translate }}
                </div>

                <button
                    *ngIf="
                        allowEdit &&
                        ({ attachment, hashtagsAvailable }
                            | apply: canEditHashtags)
                    "
                    class="text-text"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate
                    "
                    [disabled]="attachment.isPartial"
                    [icon]="'tag'"
                    [matTooltip]="
                        'DASHBOARD.ATTACHMENTS.MANAGE_HASHTAGS' | translate
                    "
                    (click)="editHashtags.emit()"
                ></button>
                <button
                    *ngIf="allowEdit && (attachment | apply: canEditAttachment)"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.ATTACHMENTS.RENAME' | translate
                    "
                    [bgColor]="'surface-A'"
                    [disabled]="attachment.isPartial"
                    [icon]="'pen-pad'"
                    [matTooltip]="'DASHBOARD.ATTACHMENTS.RENAME' | translate"
                    [size]="'small'"
                    (click)="editAttachment.emit()"
                ></button>
                <button
                    *ngIf="
                        allowDelete && (attachment | apply: canDeleteAttachment)
                    "
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.COMMENTS.REMOVE_ATTACHMENT' | translate
                    "
                    [bgColor]="'surface-A'"
                    [disabled]="attachment.isPartial"
                    [icon]="'close'"
                    [matTooltip]="
                        'DASHBOARD.COMMENTS.REMOVE_ATTACHMENT' | translate
                    "
                    [size]="'small'"
                    (click)="removeAttachment.emit()"
                ></button>
            </ng-template>
        </div>

        <button
            class="absolute left-8 top-8 rounded-full"
            type="button"
            [matTooltip]="
                (hoverCheck && selected
                    ? 'DASHBOARD.ATTACHMENTS.UNSELECT'
                    : 'DASHBOARD.ATTACHMENTS.SELECT'
                ) | translate
            "
            [matTooltipPosition]="'below'"
            [ngClass]="{
                'bg-surface-A': selected,
                'bg-black-10': hoverCheck
            }"
            (click)="toggleSelectAttachment.emit()"
            (mouseenter)="itemMouseEnter.emit(); hoverCheck = true"
            (mouseleave)="hoverCheck = false"
        >
            <interacta-icon
                class="text-text-primary h-24 w-24"
                [icon]="
                    selected || selectedPreview || hoverCheck
                        ? 'check-circle-true'
                        : 'radio-button-false'
                "
                [ngClass]="{
                    'text-text-primary ': selected && !hoverCheck,
                    'text-text-reverse': !selected || hoverCheck
                }"
            ></interacta-icon>
        </button>
    </ng-container>
</div>
