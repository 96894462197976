import {
    ChangeDetectionStrategy,
    Component,
    computed,
    Input,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { getColorFromCss, uuid } from '@interacta-shared/util';
import { AppSelectors, AppState } from '@modules/core/store';
import { theme } from '@modules/core/store/UI/ui.selector';
import {
    IComment,
    VoiceCommentPlaybackRateType,
} from '@modules/post/models/comment.model';
import {
    setVoiceCommentsCurrentlyPlaying,
    setVoiceCommentsPlaybackRate,
} from '@modules/post/store/comments/comments.actions';
import {
    selectCurrentlyPlaying,
    selectPlaybackRate,
} from '@modules/post/store/comments/comments.selectors';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { filter, shareReplay, Subject, takeUntil } from 'rxjs';
import WaveSurfer from 'wavesurfer.js';

@Component({
    selector: 'interacta-post-comment-voice-message',
    templateUrl: './post-comment-voice-message.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCommentVoiceMessageComponent implements OnInit, OnDestroy {
    @Input({ required: true }) comment!: IComment;
    @Input() quoted = false;
    @Input() size: Extract<Size, 'small' | 'extra-small'> = 'extra-small';

    destroy$ = new Subject<void>();
    theme$ = this.store.select(theme).pipe(shareReplay(1));

    wavesurfer: WaveSurfer | null = null;

    audioTime = signal<number>(0);
    duration = signal<number>(0);
    isPlaying = signal<boolean>(false);
    currentPlaybackSpeed = signal<VoiceCommentPlaybackRateType>(1);

    active = signal<boolean>(false);
    loading = signal<boolean>(false);

    uuid = uuid();

    displayedFormattedTime = computed<string>(() => {
        return format(
            (this.isPlaying()
                ? this.audioTime()
                : this.comment.voiceComment?.duration ?? 0) * 1000,
            'mm:ss',
        );
    });

    constructor(
        private store: Store,
        private appStore: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.appStore
            .select(AppSelectors.selectRouteState)
            .pipe(
                takeUntil(this.destroy$),
                filter(() => this.isPlaying()),
            )
            .subscribe(() =>
                this.store.dispatch(
                    setVoiceCommentsCurrentlyPlaying({
                        id: null,
                    }),
                ),
            );

        this.store
            .select(selectPlaybackRate)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                this.currentPlaybackSpeed.set(value);
                this.wavesurfer?.setPlaybackRate(this.currentPlaybackSpeed());
            });

        this.store
            .select(selectCurrentlyPlaying)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                if (value === this.uuid) {
                    this.play();
                } else this.pause();
            });
    }

    init(): void {
        this.loading.set(true);

        this.wavesurfer = WaveSurfer.create({
            container:
                '#' +
                this.getWaveformId({ comment: this.comment, uuid: this.uuid }),
            url: this.comment.voiceComment?.temporaryContentLink,
            barWidth: 2,
            barGap: 1,
            barRadius: 8,
            cursorWidth: 0,
            height: 'auto',
            normalize: true,
        });

        this.wavesurfer.on('timeupdate', (currentTime) => {
            this.audioTime.set(currentTime);
        });

        this.wavesurfer.on('ready', () => {
            this.loading.set(false);
            this.active.set(true);
            this.wavesurfer?.setPlaybackRate(this.currentPlaybackSpeed());
            this.playPause();
        });

        this.wavesurfer.on('finish', () => {
            this.isPlaying.set(false);
            this.store.dispatch(
                setVoiceCommentsCurrentlyPlaying({
                    id: null,
                }),
            );
        });

        this.theme$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value?.mode === 'dark') {
                this.wavesurfer?.setOptions({
                    waveColor: getColorFromCss('--in-color-grigio-300-D'),
                    progressColor: getColorFromCss('--in-color-bianchetto'),
                });
            } else {
                this.wavesurfer?.setOptions({
                    waveColor: getColorFromCss('--in-color-grigio-300'),
                    progressColor: getColorFromCss('--in-color-nero-non-nero'),
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    playPause(): void {
        if (!this.wavesurfer) this.init();
        else {
            this.store.dispatch(
                setVoiceCommentsCurrentlyPlaying({
                    id: !this.isPlaying() ? this.uuid : null,
                }),
            );
        }
    }

    private pause(): void {
        this.wavesurfer?.pause();
        this.isPlaying.set(false);
    }

    private play(): void {
        this.wavesurfer?.play();
        this.isPlaying.set(true);
    }

    nextPlaybackSpeed(): void {
        switch (this.currentPlaybackSpeed()) {
            case 1:
                this.currentPlaybackSpeed.set(1.5);
                break;
            case 1.5:
                this.currentPlaybackSpeed.set(2);
                break;
            case 2:
                this.currentPlaybackSpeed.set(1);
                break;
        }

        this.store.dispatch(
            setVoiceCommentsPlaybackRate({ rate: this.currentPlaybackSpeed() }),
        );
    }

    getWaveformId(data: { comment: IComment; uuid: string }): string {
        return `voice-message-waveform-${data.comment.id}-${data.uuid}`;
    }

    formatQuotedDuration(duration: number): string {
        return format(duration * 1000, 'm:ss');
    }
}
