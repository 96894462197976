import { NgModule } from '@angular/core';
import {
    ApplyPipe,
    ArrayPipe,
    ButtonComponent,
    ButtonMenuComponent,
    CheckboxComponent,
    ChipComponent,
    ChipShowMoreComponent,
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
    FormFieldV2Component,
    HashtagChipComponent,
    IconButtonComponent,
    ImageComponent,
    InnerEllipsesTextComponent,
    InputTextV2Component,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    ProgressLoaderComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import {
    InteractaDatePipe,
    LocalFileSelectComponent,
    NoResultComponent,
} from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { AttachmentActionMenuComponent } from './components/attachment-action-menu/attachment-action-menu.component';
import { AttachmentCardComponent } from './components/attachment-card/attachment-card.component';
import { AttachmentChipComponent } from './components/attachment-chip/attachment-chip.component';
import { AttachmentDescriptionComponent } from './components/attachment-description/attachment-description.component';
import { AttachmentEditDialogComponent } from './components/attachment-edit-dialog/attachment-edit-dialog.component';
import { AttachmentFeedbackHighlightComponent } from './components/attachment-feedback-highlight/attachment-feedback-highlight.component';
import { AttachmentFormChipComponent } from './components/attachment-form-chip/attachment-form-chip.component';
import { AttachmentFormThumbComponent } from './components/attachment-form-thumb/attachment-form-thumb.component';
import { AttachmentHighlightComponent } from './components/attachment-highlight/attachment-highlight.component';
import { AttachmentIconComponent } from './components/attachment-icon/attachment-icon.component';
import { AttachmentInputListComponent } from './components/attachment-input-list/attachment-input-list.component';
import { AttachmentInputComponent } from './components/attachment-input/attachment-input.component';
import { AttachmentPreviewComponent } from './components/attachment-preview/attachment-preview.component';
import { AttachmentRowComponent } from './components/attachment-row/attachment-row.component';
import { AttachmentSelectionActionsBarComponent } from './components/attachment-selection-actions-bar/attachment-selection-actions-bar.component';
import { AttachmentVersionComponent } from './components/attachment-version/attachment-version.component';
import { AttachmentsChipListComponent } from './components/attachments-chip-list/attachments-chip-list.component';
import { AttachmentsGridComponent } from './components/attachments-grid/attachments-grid.component';
import { AttachmentsListComponent } from './components/attachments-list/attachments-list.component';
import { AttachmentsNoResultComponent } from './components/attachments-no-result/attachments-no-result.component';
import { AttachmentsRowsComponent } from './components/attachments-rows/attachments-rows.component';
import { AttachmentsVersionsDialogComponent } from './components/attachments-versions-dialog/attachments-versions-dialog.component';
import { AttachmentsViewSwitchComponent } from './components/attachments-view-switch/attachments-view-switch.component';
import { MediaListDifferentSizeComponent } from './components/media-list-different-size/media-list-different-size.component';
import { MediaListComponent } from './components/media-list/media-list.component';

const components = [
    AttachmentsGridComponent,
    AttachmentCardComponent,
    AttachmentDescriptionComponent,
    AttachmentSelectionActionsBarComponent,
    AttachmentPreviewComponent,
    AttachmentChipComponent,
    AttachmentFormChipComponent,
    AttachmentsChipListComponent,
    MediaListComponent,
    AttachmentsNoResultComponent,
    AttachmentActionMenuComponent,
    AttachmentEditDialogComponent,
    AttachmentInputComponent,
    AttachmentsListComponent,
    AttachmentsVersionsDialogComponent,
    AttachmentVersionComponent,
    AttachmentIconComponent,
    AttachmentRowComponent,
    AttachmentsRowsComponent,
    AttachmentsViewSwitchComponent,
    MediaListDifferentSizeComponent,
    AttachmentInputListComponent,
    AttachmentFormThumbComponent,
    AttachmentHighlightComponent,
    AttachmentFeedbackHighlightComponent,
];

const standaloneComponents = [
    IconComponent,
    ButtonComponent,
    ButtonMenuComponent,
    CheckboxComponent,
    ChipComponent,
    ChipShowMoreComponent,
    DialogButtonsComponent,
    DialogButtonComponent,
    DialogComponent,
    DialogDescriptionComponent,
    HashtagChipComponent,
    IconButtonComponent,
    ImageComponent,
    InnerEllipsesTextComponent,
    InputTextV2Component,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    NoResultComponent,
    ProgressLoaderComponent,
    SeparatorComponent,
    FormFieldV2Component,
    LocalFileSelectComponent,
];

const standalonePipes = [ApplyPipe, ArrayPipe, InteractaDatePipe];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [SharedV2Module, ...standaloneComponents, ...standalonePipes],
})
export class AttachmentsModule {}
