<div
    class="flex flex-col space-y-8"
    [ngClass]="{
        'w-200 h-308': size === 'extra-large',
        'w-148': size === 'large',
        'h-232': size === 'large' && !preview
    }"
>
    <div class="relative flex w-full flex-col">
        <button
            type="button"
            [attr.aria-label]="
                (isSelectionActive
                    ? hoverCheck && selected
                        ? 'DASHBOARD.ATTACHMENTS.UNSELECT'
                        : 'DASHBOARD.ATTACHMENTS.SELECT'
                    : 'DASHBOARD.ATTACHMENTS.OPEN_ATTACHMENT'
                ) | translate: { name: attachment.name }
            "
            (click)="
                isSelectionActive
                    ? toggleSelectAttachment.emit()
                    : openAttachment.emit()
            "
            (mouseenter)="itemMouseEnter.emit(); hoverCard = true"
            (mouseleave)="itemMouseLeave.emit(); hoverCard = false"
        >
            <interacta-attachment-preview
                class="flex"
                [attachment]="attachment"
                [size]="size"
            ></interacta-attachment-preview>
            <div
                *ngIf="selected || selectedPreview || hoverCard || hoverCheck"
                class="absolute inset-0 rounded-lg"
                @selectionOverlay
                [ngClass]="{
                    'bg-transparent-primary ring-border-primary ring-4':
                        selected,
                    'bg-transparent-black-not-black-40':
                        !selected &&
                        (selectedPreview || hoverCard || hoverCheck)
                }"
            ></div>
        </button>
        <interacta-attachment-icon
            class="absolute left-8 top-8"
            [attachment]="attachment"
        ></interacta-attachment-icon>

        <button
            *ngIf="enableSelection"
            class="absolute right-8 top-8 rounded-full"
            matTooltipPosition="below"
            type="button"
            [attr.aria-label]="
                (hoverCheck && selected
                    ? 'DASHBOARD.ATTACHMENTS.UNSELECT'
                    : 'DASHBOARD.ATTACHMENTS.SELECT'
                ) | translate
            "
            [matTooltip]="
                (hoverCheck && selected
                    ? 'DASHBOARD.ATTACHMENTS.UNSELECT'
                    : 'DASHBOARD.ATTACHMENTS.SELECT'
                ) | translate
            "
            [ngClass]="{
                'bg-surface-A': selected,
                'bg-black-10': hoverCheck
            }"
            (click)="toggleSelectAttachment.emit()"
            (mouseenter)="itemMouseEnter.emit(); hoverCheck = true"
            (mouseleave)="hoverCheck = false"
        >
            <interacta-icon
                class="text-text-primary h-24 w-24"
                [icon]="
                    selected || selectedPreview || hoverCheck
                        ? 'check-circle-true'
                        : 'radio-button-false'
                "
                [ngClass]="{
                    'text-text-primary ': selected && !hoverCheck,
                    'text-text-reverse': !selected || hoverCheck
                }"
            ></interacta-icon>
        </button>

        <div
            *ngIf="
                (attachment.hashtags && attachment.hashtags.length) ||
                (attachment.versionNumber ?? 0) > 1
            "
            class="absolute bottom-8 mx-8 flex max-w-full gap-4"
        >
            <button
                *ngIf="attachment.hashtags && attachment.hashtags.length"
                type="button"
                matTooltip="{{
                    'DASHBOARD.FILTERS.HASHTAG_BANNER_COUNTER'
                        | translate: { counter: attachment.hashtags.length }
                }}"
                (click)="openAttachment.emit(true); $event.stopPropagation()"
            >
                <interacta-chip
                    bgColor="surface-A"
                    iconLeft="tag"
                    size="small"
                    [label]="'' + attachment.hashtags.length"
                ></interacta-chip>
            </button>
            <button
                *ngIf="(attachment.versionNumber ?? 0) > 1"
                type="button"
                matTooltip="{{
                    'LIGHTBOX.VERSION'
                        | translate: { number: attachment.versionNumber }
                }}"
                (click)="openAttachment.emit(true); $event.stopPropagation()"
            >
                <interacta-chip
                    bgColor="surface-A"
                    iconLeft="history-clock"
                    size="small"
                    [label]="'' + attachment.versionNumber"
                ></interacta-chip>
            </button>
        </div>
    </div>
    <interacta-attachment-description
        class="flex grow"
        [aiEnabled]="aiEnabled"
        [attachment]="attachment"
        [canShare]="canShare"
        [customButtons]="customButtons"
        [enableMenu]="enableMenu"
        [hashtagsAvailable]="hashtagsAvailable"
        [preview]="preview"
        [showOriginField]="showOriginField"
        [showPostTitle]="size === 'extra-large'"
        (delete)="delete.emit()"
        (download)="download.emit($event)"
        (edit)="edit.emit()"
        (editHashtags)="editHashtags.emit()"
        (manageVersion)="manageVersion.emit()"
        (openTitle)="openAttachment.emit()"
        (sendToAI)="sendToAI.emit()"
        (share)="share.emit()"
    ></interacta-attachment-description>
</div>
