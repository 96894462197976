<ng-container
    *ngIf="{
        canEdit: (attachment | apply: canEditAttachment),
        canEditHashtags:
            ({ attachment, hashtagsAvailable } | apply: canEditHashtags),
        canDelete: attachment.capabilities?.canDelete || false,
        canManageVersion:
            ({
                attachment,
                driveIntegrationEnabled:
                    googleAPIService.driveIntegrationEnabled
            } | apply: canManageVersionAttachment),
        versionNumber: attachment.versionNumber ?? 1,
        originFieldLabel: originFieldLabel$ | async
    } as val"
>
    <div class="flex h-full w-full flex-col">
        <div class="flex items-center justify-between">
            <button class="min-w-0" type="button" (click)="openTitle.emit()">
                <interacta-inner-ellipses-text
                    class="typo-xs-bold"
                    [bgColor]="preview ? 'surface-C' : 'surface-B'"
                    [matTooltip]="attachment.name"
                    [text]="attachment.name"
                ></interacta-inner-ellipses-text>
            </button>
            <div #openMenuBtn="cdkOverlayOrigin" cdkOverlayOrigin class="h-24">
                <button
                    *ngIf="
                        enableMenu &&
                        (val.canEdit ||
                            val.canDelete ||
                            val.canManageVersion ||
                            val.versionNumber > 1 ||
                            attachment.canDownload ||
                            attachment.drive)
                    "
                    icon="ellipsis-vertical"
                    interacta-icon-button
                    size="extra-small"
                    type="button"
                    [attr.aria-label]="
                        'DASHBOARD.ATTACHMENTS.OPEN_MENU' | translate
                    "
                    (click)="isMenuOpen = true"
                ></button>
            </div>
        </div>

        <div class="typo-xs flex">
            <div class="min-w-0 grow">
                <div
                    *ngIf="
                        showPostTitle &&
                        attachment.post &&
                        (attachment.post.title || attachment.post.code)
                    "
                    class="line-clamp-2 break-words"
                >
                    <span>{{ 'DASHBOARD.ATTACHMENTS.IN' | translate }}</span>
                    <a
                        class="text-text-primary text-left"
                        [routerLink]="['/post', attachment.post.id]"
                    >
                        {{
                            attachment.post.code
                                | postCodeTitle: attachment.post.title
                        }}
                    </a>
                </div>

                <span class="line-clamp-1">
                    {{ attachment.creatorUser?.fullName }}
                </span>
            </div>

            <div class="h-full w-24 shrink-0"></div>
        </div>

        <div
            *ngIf="!preview"
            class="typo-xs text-text-mid-contrast flex h-24 items-center justify-between"
        >
            <div [matTooltip]="attachment.lastModifyTimestamp | dateTooltip">
                {{ attachment.lastModifyTimestamp | interactaDate: 'date' }}
            </div>
            <interacta-icon
                *ngIf="attachment.drive"
                class="h-24 w-24"
                icon="google-drive"
                matTooltip="{{
                    'DASHBOARD.ATTACHMENTS.SOURCE'
                        | translate
                            : {
                                  source:
                                      'DASHBOARD.ATTACHMENTS.SOURCES.GOOGLE_DRIVE'
                                      | translate
                              }
                }}"
            ></interacta-icon>
        </div>
        @if (showOriginField && val.originFieldLabel) {
            <p
                class="typo-xs text-text-mid-contrast w-fit max-w-full overflow-hidden text-ellipsis text-nowrap"
                [matTooltip]="val.originFieldLabel"
            >
                {{ 'DASHBOARD.ATTACHMENTS.IN' | translate }}
                <span class="typo-xs-bold">{{ val.originFieldLabel }}</span>
            </p>
        }
    </div>

    <interacta-menu
        positionX="before"
        positionY="below"
        [open]="isMenuOpen"
        [origin]="openMenuBtn"
        (closing)="isMenuOpen = false"
    >
        <interacta-attachment-action-menu
            [aiEnabled]="aiEnabled"
            [attachment]="attachment"
            [canDelete]="val.canDelete"
            [canDownload]="attachment.canDownload ?? false"
            [canEdit]="val.canEdit"
            [canEditHashtags]="val.canEditHashtags"
            [canManageVersion]="val.canManageVersion"
            [canShare]="canShare"
            [customButtons]="customButtons"
            [isDrive]="!!attachment.drive"
            [versionNumber]="val.versionNumber"
            (click)="isMenuOpen = false"
            (delete)="delete.emit()"
            (download)="download.emit(attachment)"
            (edit)="edit.emit()"
            (editHashtags)="editHashtags.emit()"
            (manageVersion)="manageVersion.emit()"
            (openDrive)="openDrive()"
            (sendToAI)="sendToAI.emit()"
            (share)="share.emit()"
        >
        </interacta-attachment-action-menu>
    </interacta-menu>
</ng-container>
