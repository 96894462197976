<div class="flex items-center justify-between">
    <div class="flex space-x-12" (click)="$event.stopPropagation()">
        <interacta-local-file-select
            *ngIf="canAddAttachments"
            [appearance]="'ghost'"
            [shape]="'round'"
            [tooltip]="'POST.ADD_ATTACHMENTS' | translate"
            [uploader]="uploader"
        />
    </div>
    <div class="relative flex space-x-12">
        <button
            class="text-text-low-contrast"
            interacta-button
            type="button"
            [appearance]="'ghost'"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            [ngClass]="{ 'opacity-0': isCreatingComment }"
            [size]="'regular'"
            (click)="cancel.emit(); $event.stopPropagation()"
        ></button>
        <button
            interacta-button
            type="button"
            [disabled]="!canSaveComment || !!isCreatingComment"
            [label]="
                (mode.tag === 'edit'
                    ? 'BUTTON.LABEL_BUTTON_EDIT'
                    : 'BUTTON.LABEL_BUTTON_PUBLISH'
                ) | translate
            "
            [ngClass]="{ 'opacity-0': isCreatingComment }"
            [size]="'regular'"
            (click)="save()"
        ></button>
        <interacta-loader
            *ngIf="isCreatingComment"
            class="absolute right-20 top-6"
        />
    </div>
</div>
