<div class="flex flex-wrap gap-16">
    <ng-container *ngFor="let attachment of mediaList; let idx = index">
        <button
            class="relative"
            type="button"
            (click)="openMedia.emit({ index: idx })"
        >
            <ng-container
                *ngIf="{
                    preview: attachment | apply: getPreview
                } as $"
            >
                <interacta-image
                    *ngIf="$.preview"
                    alt="{{ attachment.name }}'s preview image"
                    [classes]="
                        'break-inside-avoid h-200 w-auto max-w-588 rounded-2xl'
                    "
                    [src]="$.preview"
                    (imgLoad)="previewLoaded = true"
                ></interacta-image>
                <interacta-attachment-preview
                    *ngIf="!$.preview"
                    size="extra-large"
                    [attachment]="attachment"
                ></interacta-attachment-preview>
                <interacta-attachment-feedback-highlight
                    *ngIf="previewLoaded || !$.preview"
                    class="absolute inset-0"
                    [attachment]="attachment"
                ></interacta-attachment-feedback-highlight>
            </ng-container>
        </button>
    </ng-container>
</div>
