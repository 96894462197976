import { Palette, Size } from '@interacta-shared/ui';
import { PaginatedList } from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IUser, QuillText } from '@modules/core';
import { EventType } from '@modules/core/models/notification-user.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { IOrder } from '@modules/post/models/filter-post/filter-post.model';
import { SurveyState } from '@modules/post/models/survey-post/survey-post.model';
import {
    BaseTask,
    FeedbackTaskList,
    FeedbackTaskState,
} from '@modules/tasks/models/task.model';
import { FeedbackTaskStateInfo } from '../components/feedback-detail-header/feedback-detail-header.component';

export enum FeedbackAnsweredTaskState {
    ANSWERED = 1,
    ANSWERED_BEFORE_EXPIRATION = 2,
    NOT_ANSWERED = 3,
}

export enum FeedbackClosedTaskState {
    CLOSED_OK = 1,
    CLOSED_OK_FIRST_SUBMIT = 2,
    OTHER_CASES = 3,
}

export type FeedbackTaskRole = 'recipient' | 'manager' | 'viewer';

export interface FeedbackTaskOperation {
    readonly id: string;
    label: string;
    fromState: FeedbackTaskState;
    toState: FeedbackTaskState;
    withData: boolean;
    withOptionalNote: boolean;
    description: string;
    permittedTo: FeedbackTaskRole[];
    surveyState: SurveyState[];
}

export type FeedbackTaskWorkflow = Record<
    FeedbackTaskState,
    FeedbackTaskOperation[]
>;

export interface FeedbackTaskFilters {
    state: FeedbackTaskState | null;
    assingedToMe: boolean;
    assigneeName: string | null;
    order: IOrder;
    calculateTotalItemsCount?: boolean;
    firstReviewBeforeExpiring?: boolean;
    onlyOneReview?: boolean;
}

export type FeedbackTaskAPIFilters = Omit<FeedbackTaskFilters, 'state'> & {
    states: FeedbackTaskState[] | null;
};

export interface FeedbackTaskHistoryBase {
    id: string;
    timestamp: Date;
    authorUser: IUser;
    targetUser: IUser | null;
    typeId: EventType;
    typeDescription: string;
    community: ICommunity | null;
    task: Partial<BaseTask>;
}

export interface FeedbackTaskHistoryItemInitial
    extends FeedbackTaskHistoryBase {
    tag: 'initial';
    state: FeedbackTaskState;
}

export interface FeedbackTaskHistoryItemStateChange
    extends FeedbackTaskHistoryBase {
    tag: 'state-change';
    fromState: FeedbackTaskState;
    toState: FeedbackTaskState;
    message: QuillText;
}

export interface FeedbackTaskHistoryItemEditState
    extends FeedbackTaskHistoryBase {
    tag: 'edit-state';
}

export type FeedbackHistoryItem =
    | FeedbackTaskHistoryItemInitial
    | FeedbackTaskHistoryItemStateChange
    | FeedbackTaskHistoryItemEditState;

export type FeedbackTaskHistory = PaginatedList<FeedbackHistoryItem>;

export interface FeedbackTaskComment {
    id: number;
    clientUid: string;
    comment: QuillText;
    plainText: string;
    attachments: IAttachment[];
    lastModifyTimestamp: Date;
    creatorUser: IUser;
    creationTimestamp: Date;
    compliant: boolean | null;
    fieldId: number;
    taskState: FeedbackTaskState;
    deleted: boolean;
    capabilities: {
        canEdit: boolean;
        canDelete: boolean;
    };
    isEdited: boolean;
}

export type FeedbackTaskCommentCreate = Pick<
    FeedbackTaskComment,
    'clientUid' | 'comment' | 'attachments' | 'compliant' | 'fieldId'
>;

export type FeedbackTaskCommentEdit = Pick<
    FeedbackTaskComment,
    'id' | 'clientUid' | 'comment' | 'compliant' | 'fieldId'
> & {
    addAttachments: IAttachment[];
    removeAttachmentIds: IAttachment['id'][];
};

export type FeedbackTaskCommentDelete = Pick<
    FeedbackTaskComment,
    'id' | 'fieldId'
>;

export type FeedbackTaskCommentList = PaginatedList<FeedbackTaskComment>;

export type FeedbackMonitorTabs =
    | keyof typeof FeedbackTaskState
    | 'ALL'
    | 'INSIGHTS';

export interface FeedbackInsightsCard {
    title: string;
    description: string;
    appearance: 'card' | 'banner';
}

export interface FeedbackTaskStateStyle {
    label: string;
    background: string;
    text: Extract<Palette, 'text-on-status' | 'pure-white'>;
    emptyLabel: string;
    color: Record<ThemeMode, string>;
}

export type FeedbackInsightsTaskStateStyle = Pick<
    FeedbackTaskStateStyle,
    'label' | 'background' | 'color'
>;

export interface FeedbackInsightsState {
    isFetching: boolean;
    charts: FeedbackInsightsChartsState;
}

export interface FeedbackInsightsChartsState {
    totalTasks: FeedbackInsightsTotalTasksData | null;
    answeredTasks: FeedbackInsightsAnsweredTasksData | null;
    compliantTasks: FeedbackInsightsCompliantTasksData | null;
    timeManagement: FeedbackInsightsTimeManagementData | null;
}

export interface FeedbackInsightsChartsLegend<T> {
    label: string;
    color: string;
    count: number;
    state: T;
}

export interface FeedbackInsightsTotalTasksData {
    totalTasksCount: number;
    perStateTasksCounterMap: Record<FeedbackTaskState, number>;
}

export interface FeedbackInsightsAnsweredTasksData {
    totalTasksCount: number;
    answeredTasksCount: number;
    answeredBeforeExpirationTasksCount: number;
}

export interface FeedbackInsightsCompliantTasksData {
    totalAnsweredTasksCount: number;
    closedOkTasksCount: number;
    closedOkAtFirstAnswerTasksCount: number;
    otherCasesTasksCount: number;
}

export interface FeedbackInsightsTimeManagementData {
    avgTimeToReview: number | null;
    avgTimeToClosed: number | null;
    avgTimeToReviewList: FeedbackTaskList;
    avgTimeToClosedList: FeedbackTaskList;
}
export interface FeedbackInsightsTotalTasksChart {
    totalCount: number;
    legends: FeedbackInsightsChartsLegend<FeedbackTaskState>[];
    perStateTasksCounterMap: Record<FeedbackTaskState, number>;
}

export interface FeedbackInsightsAnsweredTasksChart {
    totalCount: number;
    answeredCount: number;
    answeredBeforeExpirationCount: number;
    answeredPercentage: number;
    legends: FeedbackInsightsChartsLegend<FeedbackAnsweredTaskState>[];
    perStateTasksCounterMap: Record<FeedbackAnsweredTaskState, number>;
}

export interface FeedbackInsightsCompliantTasksChart {
    totalCount: number;
    closedOkCount: number;
    closedOkAtFirstAnswerCount: number;
    closedOkPercentage: number;
    otherCasesCount: number;
    legends: FeedbackInsightsChartsLegend<FeedbackClosedTaskState>[];
    perStateTasksCounterMap: Record<FeedbackClosedTaskState, number>;
}

export interface FeedbackInsightsTimeManagementChart {
    toReview: FeedbackInsightsTimeManagementChartItem | null;
    toClosed: FeedbackInsightsTimeManagementChartItem | null;
}

export interface FeedbackInsightsTimeManagementChartItem {
    avgTimeValue: number | null;
    maxTimeValueFromList: number;
    avgTimeList: FeedbackTaskList;
    avgTimeFromState: FeedbackTaskState;
    avgTimeToState: FeedbackTaskState;
}

export interface FeedbackInsightsServerData {
    answeredBeforeExpirationTasksCount: number | null;
    answeredTasksCount: number | null;
    closedOkAtFirstAnswerTasksCount: number | null;
    closedOkAverageTime: number | null;
    firstAnswerAverageTime: number | null;
    tasksCountByStateMap: Record<FeedbackTaskState, number> | null;
    totalTasksCount: number | null;
}

export interface FeedbackInsightsDetailsDialogFilter {
    answeredTaskState?: FeedbackAnsweredTaskState;
    closedTaskState?: FeedbackClosedTaskState;
    taskState?: FeedbackTaskState;
}

export interface FeedbackInsightsDetailDialogOptions {
    isDetailDialogOpen: boolean;
    feedbackTaskDetailDialogFilter: FeedbackTaskAPIFilters | null;
    detailDialogTitle: string;
    detailDialogTitleParams: { fromState?: string; toState?: string } | null;
    size: Extract<Size, 'regular' | 'small'>;
    showRedirectButton: boolean;
    showFeedbackTaskDateInfo: boolean;
    showFeedbackTaskStateInfo: FeedbackTaskStateInfo;
    totalCount: number | null;
}
